export const IvaValue = {
    IVA: 21
};

export const orderStatus = {
    PREPARING: "PREPARING",
    SEND: "SEND",
    RECEIVED: "RECEIVED"
}

export const reviewStarts = [
     {
        id: 5,
        name: '5 Estrellas'
    },
    {
        id: 4,
        name: '4 Estrellas'
    },
    {
        id: 3,
        name: '3 Estrellas'
    },
    {
        id: 2,
        name: '2 Estrellas'
    },
    {
        id: 1,
        name: '1 Estrellas'
    },
]