/**
 * Register Form
 */
import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';

import { Form } from 'reactstrap';
import FormInput from '../Form/FormInput';
import { FormattedMessage } from 'react-intl';


const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email('La dirección de correo electrónico no es correcta'),
        password: Yup.string()
            .required('Debe indicar una contraseña')
            .min(6, 'La longitud de la contraseña debe ser de, al menos, 6 caracteres'),
        passwordConfirmation: Yup.string()
            .required('Debe repetir la contraseña')
            .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    }),
    mapPropsToValues: props => ({
        email: props.registerData.email,
        password: props.registerData.password,
        passwordConfirmation: props.registerData.passwordConfirmation,
    }),
    handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
        props.checkUserEmail(values.email).then(() => {
          props.onSubmit(false, values);
          setSubmitting(false);
        }).catch(error => {
          setErrors({'email': 'Correo electrónico en uso'});
          setSubmitting(false);
        });
    },
    enableReinitialize: true
});


const RegisterForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="register.placeholder.email">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="register.label.email" />}
                                type="text"
                                name="email"
                                id="email"
                                placeholder={placeholder}
                                value={values.email}
                                messageError={errors.email}
                                invalid={errors.email && touched.email}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <FormattedMessage id="register.placeholder.password">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="register.label.password" />}
                                type='password'
                                name='password'
                                id='password'
                                placeholder={placeholder}
                                value={values.password}
                                messageError={errors.password}
                                invalid={errors.password && touched.password}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <FormattedMessage id="register.placeholder.passwordConfirmation">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="register.label.passwordConfirmation" />}
                                type='password'
                                name='passwordConfirmation'
                                id='passwordConfirmation'
                                placeholder={placeholder}
                                value={values.passwordConfirmation}
                                messageError={errors.passwordConfirmation}
                                invalid={errors.passwordConfirmation && touched.passwordConfirmation}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>
            {props.loading ?
                <div className="buttons">
                    <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                        <FormattedMessage id="button.signin.label" />
                    </button>
                </div>
                :
                <div className="buttons">
                    <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="button.signin.label" />
                    </button>
                </div>
            }
        </Form>
    );
};

export default formikEnhancer(RegisterForm);
