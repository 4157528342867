import { 
    GET_FAMILY_WITH_FAMILYGROUPS,
    GET_FAMILY_WITH_FAMILYGROUPS_SUCCESS,
    GET_FAMILY_WITH_FAMILYGROUPS_FAILURE,
 } from './familyGroupsActionTypes';


export const getFamilyWithFamilyGroups = () => ({
    type: GET_FAMILY_WITH_FAMILYGROUPS
});

export const getFamilyWithFamilyGroupsSuccess = (data) => ({
    type: GET_FAMILY_WITH_FAMILYGROUPS_SUCCESS,
    payload: data
});

export const getFamilyWithFamilyGroupsFailure = (error) => ({
    type: GET_FAMILY_WITH_FAMILYGROUPS_FAILURE,
    payload: error
});