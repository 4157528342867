// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';
//import Topbar from './Topbar';
//import { LogoSvg } from '../../svg';
import { getProductsByCriteria, setProductsCriteriaSearch } from '../../store/products';
import { signinClient, logoutClient } from '../../store/auth';

class Header extends Component {

    constructor(props) {
        super(props);

        this.updateSearch = this.updateSearch.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    /**
    * On Search products
    */
    updateSearch(criteria) {
        this.props.setProductsCriteriaSearch(criteria);
        if (criteria) {
            this.props.getProductsByCriteria();
        }
    }

    onSubmit(isLogin, data) {
        this.props.signinClient(data, this.props.history);
    }

    onLogout() {
        this.props.logoutClient();
    }

    /*componentDidUpdate(prevProps) {
        if (prevProps.totalProductsByCriteria !== this.props.totalProductsByCriteria) {
            this.props.getProductsByCriteria();
        }
    }*/

    render() {
        const { layout, loadingCriteria, productsByCriteria, user, signinData, loadingSignin, criteriaSearchBar } = this.props;
        let bannerSection;

        if (layout === 'default') {
            bannerSection = (
                <div className="site-header__middle container">
                    <div className="site-header__logo">
                        <Link to="/">
                            <img src={require('../../assets/img/logo-vercan-header.jpg')} className="mr-15" alt="site logo" />
                        </Link>

                    </div>
                    <div className="site-header__search">
                        <Search context="header" criteriaSearchBar={criteriaSearchBar} updateSearch={this.updateSearch} loadingCriteria={loadingCriteria} productsByCriteria={productsByCriteria} />
                    </div>
                    <div className="site-header__phone">
                        <div className="site-header__phone-title">
                            <FormattedMessage id="header.phoneLabel" defaultMessage="Atención al cliente" />
                        </div>
                        <div className="site-header__phone-number">
                            <FormattedMessage id="header.phone" defaultMessage="942 269 548" />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="site-header">
                {/*<Topbar />*/}
                {bannerSection}
                <div className="site-header__nav-panel">
                    <NavPanel user={user} onSubmit={this.onSubmit} signinData={signinData} loadingSignin={loadingSignin} onLogout={this.onLogout} layout={layout} />
                </div>
            </div>
        );

    };
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

// map state to props
const mapStateToProps = ({ productsApp, authApp, settings }) => {
    const { productsByCriteria, loadingCriteria, criteriaSearchBar, totalProductsByCriteria } = productsApp;
    const { user, signinData, loadingSignin } = authApp;
    return { productsByCriteria, loadingCriteria, user, signinData, loadingSignin, criteriaSearchBar, totalProductsByCriteria, settings };
}

export default withRouter(connect(mapStateToProps, {
    getProductsByCriteria,
    signinClient,
    logoutClient,
    setProductsCriteriaSearch
})(Header));
