// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';
// redux action
import {
    sendRegisterConfirmationEmail
} from '../../store/auth';

class AccountPageConfirmEmailSend extends Component {

    constructor(props) {
        super(props);

        this.onSendConfirmRegister = this.onSendConfirmRegister.bind(this);
    }

    componentDidMount() {
    }

    onSendConfirmRegister(email) {
        this.props.sendRegisterConfirmationEmail(email);
    }

    render() {
        const { userEmail, sendingEmail } = this.props;

        return (
            <div className="block about-us">
                <Helmet>
                    <title>{`Confirmar registro — ${theme.name}`}</title>
                    <meta name="description" content={"Envío de email con la confirmación del registro"}></meta>
                </Helmet>

                <div className="about-us__image" style={{ backgroundImage: `url(${require('../../assets/img/slides/slider7.jpg')})` }} />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-10">
                            <div className="about-us__body">
                                <h1 className="about-us__title"><FormattedMessage id="confirm.register.title" /></h1>
                                <div className="about-us__text typography">
                                    <p>
                                        <FormattedMessage id="confirm.register.message.part1" /> {userEmail ? "(" + userEmail + ")" : ""}  <FormattedMessage id="confirm.register.message.part2" />
                                    </p>
                                </div>
                                {userEmail &&
                                    <div className="about-us__team">
                                        <div className="about-us__team-subtitle text-muted">
                                            <FormattedMessage id="confirm.register.not.success.message" />
                                            <br />
                                            <div className="buttons mt-2">
                                                <button
                                                    onClick={() => this.onSendConfirmRegister(userEmail)}
                                                    className={sendingEmail ? "btn btn-primary btn-loading" : "btn btn-primary"}
                                                    disabled={sendingEmail ? true : false}>
                                                    <FormattedMessage id="confirm.register.resent" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ authApp, settings }) => {
    const { userEmail, sendingEmail } = authApp;
    return { userEmail, sendingEmail, settings };
}

const mapDispatchToProps = {
    sendRegisterConfirmationEmail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageConfirmEmailSend);
