//PRODUCTS
export const pageSize = (state) => state.productsApp.pageSize;
export const pageSizeInfiniteScroll = (state) => state.productsApp.pageSizeInfiniteScroll;
export const cardsPageSizeInfiniteScroll = (state) => state.productsApp.cardsPageSizeInfiniteScroll;
export const productsPageIndex = (state) => state.productsApp.pageIndex;
export const productsSelectedGroups = (state) => state.productsApp.selectedGroups;
export const productsSelectedFamilyGroups = (state) => state.productsApp.selectedFamilyGroups;
export const productsSelectedFamilies = (state) => state.productsApp.selectedFamilies;
export const productsSelectedManufacturers = (state) => state.productsApp.selectedManufacturers;
export const productsSortField = (state) => state.productsApp.sortByField;
export const productsCriteriaSearchBar = (state) => state.productsApp.criteriaSearchBar;
export const isOutletScreen = (state) => state.productsApp.isOutletScreen;

