// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import CryptoJS from 'crypto-js';

// application
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

// app config
import AppConfig from '../../util/AppConfig';

import { createOrder, setPaymentData } from '../../store/cart';
import SitePageAccessDenied from '../site/SitePageAccessDenied';

class PaymentPaid extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
       
    }

    render() {
        return (
            null
        );
    }
}


const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPaid);
