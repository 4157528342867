// eslint-disable-next-line import/prefer-default-export
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const GET_FEATURED_PRODUCTS = 'GET_FEATURED_PRODUCTS';
export const GET_FEATURED_PRODUCTS_SUCCESS = 'GET_FEATURED_PRODUCTS_SUCCESS';
export const GET_FEATURED_PRODUCTS_FAILURE = 'GET_FEATURED_PRODUCTS_FAILURE';

export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const GET_NEW_RELEASE_PRODUCTS = 'GET_NEW_RELEASE_PRODUCTS';
export const GET_NEW_RELEASE_PRODUCTS_SUCCESS = 'GET_NEW_RELEASE_PRODUCTS_SUCCESS';
export const GET_NEW_RELEASE_PRODUCTS_FAILURE = 'GET_NEW_RELEASE_PRODUCTS_FAILURE';

export const GET_BEST_SELLER_PRODUCTS = 'GET_BEST_SELLER_PRODUCTS';
export const GET_BEST_SELLER_PRODUCTS_SUCCESS = 'GET_BEST_SELLER_PRODUCTS_SUCCESS';
export const GET_BEST_SELLER_PRODUCTS_FAILURE = 'GET_BEST_SELLER_PRODUCTS_FAILURE';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';

export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS';
export const GET_RELATED_PRODUCTS_SUCCESS = 'GET_RELATED_PRODUCTS_SUCCESS';
export const GET_RELATED_PRODUCTS_FAILURE = 'GET_RELATED_PRODUCTS_FAILURE';

export const SET_FAMILY_GROUP_ID = 'SET_FAMILY_GROUP_ID';
export const SET_PRODUCTS_PAGE = 'SET_PRODUCTS_PAGE';

export const GET_PRODUCTS_AFTER_SCROLLING = 'GET_PRODUCTS_AFTER_SCROLLING';
export const GET_PRODUCTS_AFTER_SCROLLING_SUCCESS = 'GET_PRODUCTS_AFTER_SCROLLING_SUCCESS';
export const GET_PRODUCTS_AFTER_SCROLLING_FAILURE = 'GET_PRODUCTS_AFTER_SCROLLING_FAILURE';

export const GET_PRODUCTS_BY_CRITERIA = 'GET_PRODUCTS_BY_CRITERIA';
export const GET_PRODUCTS_BY_CRITERIA_SUCCESS = 'GET_PRODUCTS_BY_CRITERIA_SUCCESS';
export const GET_PRODUCTS_BY_CRITERIA_FAILURE = 'GET_PRODUCTS_BY_CRITERIA_FAILURE';

export const GET_GROUPS_FILTER = 'GET_GROUPS_FILTER';
export const GET_GROUPS_FILTER_SUCCESS = 'GET_GROUPS_FILTER_SUCCESS';
export const GET_GROUPS_FILTER_FAILURE = 'GET_GROUPS_FILTER_FAILURE';

export const ADD_SELECTED_GROUP = 'ADD_SELECTED_GROUP';
export const REMOVE_SELECTED_GROUP = 'REMOVE_SELECTED_GROUP';

export const GET_FAMILY_GROUPS_FILTER = 'GET_FAMILY_GROUPS_FILTER';
export const GET_FAMILY_GROUPS_FILTER_SUCCESS = 'GET_FAMILY_GROUPS_FILTER_SUCCESS';
export const GET_FAMILY_GROUPS_FILTER_FAILURE = 'GET_FAMILY_GROUPS_FILTER_FAILURE';
export const ADD_SELECTED_FAMILY_GROUP = 'ADD_SELECTED_FAMILY_GROUP';
export const REMOVE_SELECTED_FAMILY_GROUP = 'REMOVE_SELECTED_FAMILY_GROUP';

export const GET_FAMILIES_FILTER = 'GET_FAMILIES_FILTER';
export const GET_FAMILIES_FILTER_SUCCESS = 'GET_FAMILIES_FILTER_SUCCESS';
export const GET_FAMILIES_FILTER_FAILURE = 'GET_FAMILIES_FILTER_FAILURE';
export const ADD_SELECTED_FAMILY = 'ADD_SELECTED_FAMILY';
export const REMOVE_SELECTED_FAMILY = 'REMOVE_SELECTED_FAMILY';

export const GET_MANUFACTURERS_FILTER = 'GET_MANUFACTURERS_FILTER';
export const GET_MANUFACTURERS_FILTER_SUCCESS = 'GET_MANUFACTURERS_FILTER_SUCCESS';
export const GET_MANUFACTURERS_FILTER_FAILURE = 'GET_MANUFACTURERS_FILTER_FAILURE';
export const ADD_SELECTED_MANUFACTURER = 'ADD_SELECTED_MANUFACTURER';
export const REMOVE_SELECTED_MANUFACTURER = 'REMOVE_SELECTED_MANUFACTURER';

export const CLEAN_PRODUCTS_FILTERS = 'CLEAN_PRODUCTS_FILTERS';
export const SET_SORT_BY_FIELD = 'SET_SORT_BY_FIELD';

export const BUILD_EMPTY_PRODUCT_REVIEW = 'BUILD_EMPTY_PRODUCT_REVIEW';
export const ADD_PRODUCT_REVIEW = 'ADD_PRODUCT_REVIEW';
export const ADD_PRODUCT_REVIEW_SUCCESS = 'ADD_PRODUCT_REVIEW_SUCCESS';
export const ADD_PRODUCT_REVIEW_FAILURE = 'ADD_PRODUCT_REVIEW_FAILURE';
export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const GET_PRODUCT_REVIEWS_SUCCESS = 'GET_PRODUCT_REVIEWS_SUCCESS';
export const GET_PRODUCT_REVIEWS_FAILURE = 'GET_PRODUCT_REVIEWS_FAILURE';

export const SET_PRODUCTS_CRITERIA = 'SET_PRODUCTS_CRITERIA';
export const SET_IS_OUTLET_SCREEN = 'SET_IS_OUTLET_SCREEN';

export const GET_FAMILY_GROUPS = 'GET_FAMILY_GROUPS';
export const GET_FAMILY_GROUPS_SUCCESS = 'GET_FAMILY_GROUPS_SUCCESS';
export const GET_FAMILY_GROUPS_FAILURE = 'GET_FAMILY_GROUPS_FAILURE';
export const SET_GROUP_ID = 'SET_GROUP_ID';
