// react
import React, { Component } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';

// application
import Currency from '../shared/Currency';
import { Cross20Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import {
    getRegions
} from '../../store/user';

import {
    cleanCart
} from '../../store/cart';
import SitePageAccessDenied from '../site/SitePageAccessDenied';


class ShopPageOrderFailure extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { me } = this.props;

        if (!me) {
            return (
                <SitePageAccessDenied />
            );
        }

        return (
            <div className="block order-success">
                <Helmet>
                    <title>{`Compra fallida — ${theme.name}`}</title>
                    <meta name="description" content={"Pantalla con el detalle de la compra fallida"}></meta>
                </Helmet>

                <div className="container">
                    <div>
                        <div className="order-success__header">
                            <Cross20Svg className="order-success__icon" />
                            <h1 className="order-success__title"><FormattedMessage id="order.failure.title" /></h1>
                            <div className="order-success__subtitle"><FormattedMessage id="order.failure.subtitle" /></div>
                            <div className="order-success__actions">
                                <Link to="/tramitar-comprar/datos-envio" className="btn btn-xs btn-secondary"><FormattedMessage id="order.failure.button.home" /></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    regions: state.userApp.regions,
    me: state.authApp.me
});

const mapDispatchToProps = {
    getRegions,
    cleanCart
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderFailure);

