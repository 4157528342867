import client from './client';


export const createOrderRequest = async (data) => {
    let queryUrl = `/orders`;
    return await client.post(queryUrl, data)
        .then(response => response.data);       
};

export const getShippingCost = async (provinceZone, weight, amountToCheckDiscount) => {
    let queryUrl = `/shipping-costs/get-cost?weight=${weight}&provinceZone=${provinceZone}&amountToCheckDiscount=${amountToCheckDiscount}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const counterSecRequest = async (name, year) => {
    let queryUrl = `/counters/payment?name=${name}&year=${year}`;
    return await client.put(queryUrl)
        .then(response => response.data);       
};

export const createOrderInKarveRequest = async (data) => {
    let queryUrl = `/orders/create-order-in-karve`;
    return await client.post(queryUrl, data)
        .then(response => response.data);       
};