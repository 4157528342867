// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
import products from '../../data/shopProducts';
import { FormattedMessage } from 'react-intl';
import theme from '../../data/theme';

import {
    openEmailClient
} from "../../utils";

import {
    FacebookShareButton,
    LinkedinShareButton,
    FacebookIcon,
    LinkedinIcon,
} from "react-share";

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: props.product.minimumQuantity > 0 ? props.product.minimumQuantity : 1,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    render() {
        const {
            product,
            layout,
            wishlistAddItem,
            compareAddItem,
            cartAddItem,
            onAddCartClick,
            adding,
            onAddWishListClick
        } = this.props;
        const { quantity } = this.state;
        let prices;
        const stock = product.stock > 0 ? true : false;

        if (product.priceWithDiscount > 0) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price"><Currency value={product.priceWithDiscount} /></span>
                    {' '}
                    <span className="product__old-price"><Currency value={product.price} /></span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={product.price} />;
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <Helmet>
                    <title>{`${product.name} — ${theme.name}`}</title>
                    <meta name="description" content={`Vista rápida producto - ${product.name}`}></meta>
                </Helmet>
                <div className="product__content">
                    <ProductGallery layout={layout} images={product.pictures} />

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>
                        <h1 className="product__name">{product.name}</h1>
                        <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating value={product.rating} />
                            </div>
                            {/*<div className="product__rating-legend">
                                <Link to="/">{`${product.reviews} Reviews`}</Link>
                                <span>/</span>
                                <Link to="/">Write A Review</Link>
                            </div>*/}
                        </div>
                        <div className="product__description mt-10">
                            <FormattedMessage id="product.code" /> {product.code}
                        </div>
                        <ul className="product__meta">
                            <li className="product__meta-availability">
                                <FormattedMessage id="product.availability" />
                                {' '}
                                {stock ?
                                    <span className="text-success"><FormattedMessage id="product.with.stock" /></span>
                                    :
                                    <Link to="#" className="link-color" onClick={() => openEmailClient()}>{" Consultar disponibilidad"}</Link>
                                }
                            </li>
                            <li>
                                {/*<FormattedMessage id="product.manufacturer" />
                                {' '}
                                {product.manufacturer ? product.manufacturer.name : "-"}*/}
                            </li>

                        </ul>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Availability:
                            {' '}
                            <span className="text-success">In Stock</span>
                        </div>

                        <div className="product__prices">
                            {prices}
                        </div>

                        <form className="product__options">
                            {/*<div className="form-group product__option">
                                <div className="product__option-label">Color</div>
                                <div className="input-radio-color">
                                    <div className="input-radio-color__list">
                                        <label
                                            className="input-radio-color__item input-radio-color__item--white"
                                            style={{ color: '#fff' }}
                                            data-toggle="tooltip"
                                            title="White"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item"
                                            style={{ color: '#ffd333' }}
                                            data-toggle="tooltip"
                                            title="Yellow"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item"
                                            style={{ color: '#ff4040' }}
                                            data-toggle="tooltip"
                                            title="Red"
                                        >
                                            <input type="radio" name="color" />
                                            <span />
                                        </label>
                                        <label
                                            className="input-radio-color__item input-radio-color__item--disabled"
                                            style={{ color: '#4080ff' }}
                                            data-toggle="tooltip"
                                            title="Blue"
                                        >
                                            <input type="radio" name="color" disabled />
                                            <span />
                                        </label>
                                    </div>
                                </div>
                            </div>*/}
                            {/*<div className="form-group product__option">
                                <div className="product__option-label">Material</div>
                                <div className="input-radio-label">
                                    <div className="input-radio-label__list">
                                        <label>
                                            <input type="radio" name="material" />
                                            <span>Metal</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="material" />
                                            <span>Wood</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="material" disabled />
                                            <span>Plastic</span>
                                        </label>
                                    </div>
                                </div>
                            </div>*/}

                            {product.minimumQuantity > 0 && <label className="product__option-label">{"pedido mínimo: "} {product.minimumQuantity} {" unidades"}</label>}

                            <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label"><FormattedMessage id="product.quantity" /> </label>

                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={product.minimumQuantity > 0 ? product.minimumQuantity : 1}
                                            value={quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <button
                                            type="button"
                                            onClick={() => onAddCartClick({ product: product, options: [], quantity: quantity })}
                                            disabled={false}
                                            className={classNames('btn btn-primary btn-lg', {
                                                'btn-loading': adding,
                                            })}
                                        >
                                            {"Añadir al carrito"}
                                        </button>
                                    </div>
                                    <div className="product__actions-item product__actions-item--wishlist">

                                        <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title="Wishlist"
                                            onClick={() => onAddWishListClick(product)}
                                            className={'btn btn-secondary btn-svg-icon btn-lg'}
                                        >
                                            <Wishlist16Svg />
                                        </button>
                                    </div>
                                    {/* <div className="product__actions-item product__actions-item--compare">
                                        <AsyncAction
                                            action={() => compareAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Compare"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>*/}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="product__footer">
                        <div className="product__tags tags">
                            <div className="tags__list">
                                {product.group && product.group.name &&
                                    <Link>{product.group.name}</Link>
                                }
                                {product.family && product.family.name &&
                                    <Link>{product.family.name}</Link>
                                }
                                {product.familyGroup && product.familyGroup.name &&
                                    <Link>{product.familyGroup.name}</Link>
                                }
                            </div>
                        </div>

                        <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item">
                                    <FacebookShareButton quote={product.name + " " + window.location.href} url="https://tienda.vercanminimizados.com" hashtag="#vercan">
                                        <FacebookIcon size={20} />
                                    </FacebookShareButton></li>
                                <li className="share-links__item">
                                    <LinkedinShareButton url={"https://tienda.vercanminimizados.com/producto/" + product._id} title={product.name} >
                                        <LinkedinIcon size={20} />
                                    </LinkedinShareButton></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(Product);
