// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Indicator from '../header/Indicator';
import {
    Menu18x14Svg,
    LogoSmallSvg,
    Search20Svg,
    Heart20Svg,
    Cart20Svg,
} from '../../svg';
import { mobileMenuOpen } from '../../store/mobile-menu';
import Search from '../header/Search';
import IndicatorAccount from '../header/IndicatorAccount';

import { getGroups, getFamilyGroupsFilter } from '../../store/products';
import { getFamilyWithFamilyGroups } from '../../store/familygroups';
import { signinClient, logoutClient } from '../../store/auth';
import { getProductsByCriteria, setProductsCriteriaSearch } from '../../store/products';

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
    }

    componentDidMount() {
        this.props.getGroups();
        this.props.getFamilyWithFamilyGroups();
        this.props.getFamilyGroupsFilter();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

       /**
    * On Search products
    */
   updateSearch(criteria) {
    this.props.setProductsCriteriaSearch(criteria);
    if (criteria) {
        this.props.getProductsByCriteria();
    }
}

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    onSubmit(isLogin, data) {
        this.props.signinClient(data, this.props.history);
    }

    onLogout() {
        this.props.logoutClient();
    }

    render() {
        const { openMobileMenu, wishlistLength, cartQuantity, user, signinData, loadingSignin,loadingCriteria, productsByCriteria } = this.props;
        const { searchOpen } = this.state;
        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--open': searchOpen,
        });

        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                                <Menu18x14Svg />
                            </button>
                            <Link to="/" className="mobile-header__logo" >
                                <img src={require('../../assets/img/logo-vercan-200x76.png')} alt="site logo" width="130" />
                            </Link>
                            <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                                updateSearch={this.updateSearch}
                                loadingCriteria={loadingCriteria} productsByCriteria={productsByCriteria}
                            />
                            <div className="mobile-header__indicators">
                                <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile d-sm-flex d-none"
                                    url="/lista-de-deseos"
                                    value={wishlistLength}
                                    icon={<Heart20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile"
                                    url="/carrito"
                                    value={cartQuantity}
                                    icon={<Cart20Svg />}
                                />
                                <IndicatorAccount isMobile={true} user={user} onSubmit={this.onSubmit} signinData={signinData} loadingSignin={loadingSignin} onLogout={this.onLogout} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ cart, wishlist, authApp, productsApp, settings }) => {
    const cartQuantity = cart.quantity;
    const wishlistLength = wishlist.length;
    const { user, signinData, loadingSignin } = authApp;
    const { productsByCriteria, loadingCriteria } = productsApp;
    return { cartQuantity, wishlistLength, user, signinData, loadingSignin, productsByCriteria, loadingCriteria, settings };
}

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
    getGroups,
    getFamilyGroupsFilter,
    getFamilyWithFamilyGroups,
    signinClient,
    logoutClient,
    getProductsByCriteria,
    setProductsCriteriaSearch
};



export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileHeader);
