// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import { Helmet } from 'react-helmet-async';
import theme from '../../data/theme';
import { FormattedMessage } from 'react-intl';
import { Quickview16Svg } from '../../svg';
import AppConfig from '../../util/AppConfig';


class PaymentAutoSubmitForm extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { paymentData } = this.props;

        if (paymentData && paymentData.Ds_MerchantParameters && paymentData.Ds_Signature && paymentData.Ds_SignatureVersion) {
            document.getElementById('paymentForm').submit();
        }
    }

    render() {
        const { paymentData } = this.props;

        return (
            <div className="block order-success">
                <Helmet>
                    <title>{`Order Success — ${theme.name}`}</title>
                </Helmet>

                <div className="text-center mt-10">
                    <h1><FormattedMessage id="payment.submit.loading.message" /></h1>
                    <div className="form-group">
                        <button type="button" className={`btn btn-loading btn-xl btn-svg-icon`}>
                            <Quickview16Svg />
                        </button>
                    </div>
                </div>
                <div>
                    <form name="paymentForm" id="paymentForm" method="post" action={AppConfig.url_REAL}>
                        <input type="hidden" name="Ds_MerchantParameters" value={paymentData.Ds_MerchantParameters} />
                        <input type="hidden" name="Ds_Signature" value={paymentData.Ds_Signature} />
                        <input type="hidden" name="Ds_SignatureVersion" value={paymentData.Ds_SignatureVersion} />

                    </form>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    me: state.authApp.me,
    paymentData: state.cart.paymentData
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAutoSubmitForm);
