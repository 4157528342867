import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    BUILD_EMPTY_REGISTER_DATA,
    BUILD_EMPTY_SIGNIN_DATA,
    CONFIRM_REGISTER,
    CONFIRM_REGISTER_SUCCESS,
    CONFIRM_REGISTER_FAILURE,
    LOGIN_CLIENT,
    LOGIN_CLIENT_SUCCESS,
    LOGIN_CLIENT_FAILURE,
    LOGOUT_CLIENT,
    LOGOUT_CLIENT_SUCCESS,
    LOGOUT_CLIENT_FAILURE,
    ME_REQUEST,
    ME_REQUEST_SUCCESS,
    ME_REQUEST_FAILURE,
    SEND_PASSWORD_RECOVERY_EMAIL,
    SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS,
    SEND_PASSWORD_RECOVERY_EMAIL_FAILURE,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_SUCCESS,
    RECOVER_PASSWORD_FAILURE,
    SEND_REGISTER_CONFIRMATION_EMAIL,
    SEND_REGISTER_CONFIRMATION_EMAIL_SUCCESS,
    SEND_REGISTER_CONFIRMATION_EMAIL_FAILURE
} from './authActionTypes';
import { NotificationManager } from 'react-notifications';


const initialState = {
    user: (localStorage.getItem('user_id') ? localStorage.getItem('user_id') : sessionStorage.getItem('user_id')),
    registerData: {},
    signinData: {},
    loading: false,
    loadingSignin: false,
    loginError: false,
    loadingRegister: false,
    loadingLogOut: false,
    me: null,
    userEmail: "",
    emailDoesNotExist: false,
    sentMail: false,
    tokenExpired: false,
    sendingEmail: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case BUILD_EMPTY_REGISTER_DATA:
            return {
                ...state, registerData: {
                    email: "",
                    password: "",
                    passwordConfirmation: ""
                }
            };

        case BUILD_EMPTY_SIGNIN_DATA:
            return {
                ...state, signinData: {
                    email: "",
                    password: "",
                }
            };

        case REGISTER:
            return { ...state, loadingRegister: true };
        case REGISTER_SUCCESS:
            return { ...state, userEmail: action.payload, loadingRegister: false };
        case REGISTER_FAILURE:
            NotificationManager.error('Se ha producido un error durante el alta del usuario.');
            return { ...state, loadingRegister: false };

        case CONFIRM_REGISTER:
            return { ...state, loading: true };
        case CONFIRM_REGISTER_SUCCESS:
            return { ...state, user: action.payload, loading: false };
        case CONFIRM_REGISTER_FAILURE:
            NotificationManager.error('Se ha producido un error durante la confirmación del usuarrio.');
            return { ...state, loading: false };

        case LOGIN_CLIENT:
            return { ...state, loadingSignin: true };
        case LOGIN_CLIENT_SUCCESS:
            return { ...state, loadingSignin: false, user: action.payload.user, loginError: false };
        case LOGIN_CLIENT_FAILURE:
            NotificationManager.error("Credenciales erróneas");
            return { ...state, loadingSignin: false, loginError: true };

        case LOGOUT_CLIENT:
            return { ...state, loadingLogOut: true };

        case LOGOUT_CLIENT_SUCCESS:
            return { ...state, user: null, loadingLogOut: false };

        case LOGOUT_CLIENT_FAILURE:
            return { ...state, loadingLogOut: false };

        case ME_REQUEST:
            return { ...state, loading: true };

        case ME_REQUEST_SUCCESS:
            return { ...state, loading: false, me: action.payload };

        case ME_REQUEST_FAILURE:
            return { ...state, loading: false };

        case SEND_PASSWORD_RECOVERY_EMAIL:
            return { ...state, loading: true, sentMail: false };

        case SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS:
            return { ...state, loading: false, emailDoesNotExist: false, sentMail: true };

        case SEND_PASSWORD_RECOVERY_EMAIL_FAILURE:
            return { ...state, loading: false, emailDoesNotExist: true, sentMail: false };

        case RECOVER_PASSWORD:
            return { ...state, loading: true, passwordRecovered: false, tokenExpired: false };

        case RECOVER_PASSWORD_SUCCESS:
            return { ...state, loading: false, passwordRecovered: true, tokenExpired: false };

        case RECOVER_PASSWORD_FAILURE:
            return { ...state, loading: false, tokenExpired: true };

        case SEND_REGISTER_CONFIRMATION_EMAIL:
            return { ...state, sendingEmail: true, sentMail: false };

        case SEND_REGISTER_CONFIRMATION_EMAIL_SUCCESS:
            return { ...state, sendingEmail: false, sentMail: true };

        case SEND_REGISTER_CONFIRMATION_EMAIL_FAILURE:
            return { ...state, sendingEmail: false, sentMail: false };

        default:
            return { ...state };
    }
}

export default authReducer;
