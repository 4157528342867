/**
 * Password Recovery Form
 */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { FormGroup, Form, Input } from 'reactstrap';
import Button from '@material-ui/core/Button';

import { FormattedMessage } from 'react-intl';

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    passwordConfirmation: Yup.string()
      .required('Debe repetir la nueva contraseña')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
    password: Yup.string()
      .required('Debe indicar la nueva contraseña')
      .min(6, 'La longitud de la contraseña debe ser de, al menos, 6 caracteres')

  }),
  mapPropsToValues: props => ({
    password: props.password,
    passwordConfirmation: props.passwordConfirmation,
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },
  enableReinitialize: true
});

const PasswordRecoveryForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className="has-wrapper" error={errors.password && touched.password ? 'true' : 'false'}>
        <FormattedMessage id="passwordRecovery.placeholder.password">
          {placeholder =>
            <Input autoComplete="new-password"
              type="Password"
              value={values.password}
              name="password"
              id="password"
              className="has-input input-lg"
              placeholder={placeholder}
              onChange={handleChange}
              invalid={errors.password && touched.password}
            />
          }
        </FormattedMessage>
        <span className="has-icon"><i className="ti-lock"></i></span>
        {errors.password && touched.password &&
          <div className="input-error">{errors.password}</div>
        }
      </FormGroup>
      <FormGroup className="has-wrapper" error={errors.passwordConfirmation && touched.passwordConfirmation ? 'true' : 'false'}>
        <FormattedMessage id="passwordRecovery.placeholder.repeatPassword">
          {placeholder =>
            <Input autoComplete="new-password"
              type="Password"
              value={values.passwordConfirmation}
              name="passwordConfirmation"
              id="passwordConfirmation"
              className="has-input input-lg"
              placeholder={placeholder}
              onChange={handleChange}
              invalid={errors.passwordConfirmation && touched.passwordConfirmation}
            />
          }
        </FormattedMessage>
        <span className="has-icon"><i className="ti-lock"></i></span>
        {errors.passwordConfirmation && touched.passwordConfirmation &&
          <div className="input-error">{errors.passwordConfirmation}</div>
        }
      </FormGroup>
      {props.tokenExpired &&
        <div className="password-confirmation-error">
          <FormattedMessage id="passwordRecovery.error.tokenExpired" />
        </div>
      }
      <FormGroup className="mt-15">
        {props.loading ?
          <div className="buttons mt-10">
            <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
              <FormattedMessage id="passwordRecovery.button.save" />
            </button>
          </div>
          :
          <div className="buttons mt-10">
            <button type="submit" className="btn btn-primary">
              <FormattedMessage id="passwordRecovery.button.save" />
            </button>
          </div>
        }
      </FormGroup>
    </Form>
  );
};

export default formikEnhancer(PasswordRecoveryForm);
