// react
import React from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import { Helmet } from 'react-helmet-async';

// blocks
import BlockBanner from '../blocks/BlockBanner';
import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockPosts from '../blocks/BlockPosts';
import BlockProductColumns from '../blocks/BlockProductColumns';
import BlockProducts from '../blocks/BlockProducts';
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';
import BlockTabbedProductsCarouselNew from '../blocks/BlockTabbedProductsCarouselNew';
import BlockBestSellerProducts from '../blocks/BlockBestSellerProducts';

// data stubs
import categories from '../../data/shopBlockCategories';
import posts from '../../data/blogPosts';
import products from '../../data/shopProducts';
import theme from '../../data/theme';


function HomePageTwo() {
    const columns = [
        {
            title: 'home.top',
            products: products.slice(0, 3),
        },
        {
            title: 'home.specialOffers',
            products: products.slice(3, 6),
        },
        {
            title: 'home.bestsellers',
            products: products.slice(6, 9),
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${theme.name}`}</title>
                <meta name="description" content="Empresa de suministro industrial, alquiler y reparación de maquinaria. Especialistas en soldadura. Certificación ISO 9001. Marcas punteras."></meta>
            </Helmet>

            <BlockSlideShow />

            {/*<BlockFeatures layout="boxed" />*/}

            <FormattedMessage id="home.featured">
                {title=>
                <BlockTabbedProductsCarousel title={title} layout="grid-5" rows={1} />
                }
            </FormattedMessage>
            

            {/*<BlockBanner />*/}

            {/*<FormattedMessage id="home.bestsellers">
                {title=>
                    <BlockBestSellerProducts
                        title= {title}
                        layout="large-last"
                    />  
                }
            </FormattedMessage>*/}

            {/*<FormattedMessage id="home.popular">
                { title =>
                    <BlockCategories title={title} layout="compact" categories={categories} />
                }
            </FormattedMessage>*/}

            <FormattedMessage id="home.new">
                {title=>
                    <BlockTabbedProductsCarouselNew title={title} layout="grid-5" />
                }
            </FormattedMessage>

            {/*<FormattedMessage id="home.news">
                {title=>
                     <BlockPosts title={title} layout="grid-nl" posts={posts} />
                }
            </FormattedMessage>*/}
            {/*<BlockBrands />*/}

            {/*<BlockProductColumns columns={columns} />*/}
        </React.Fragment>
    );
}

export default HomePageTwo;
