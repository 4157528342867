// eslint-disable-next-line import/prefer-default-export
export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const BUILD_EMPTY_REGISTER_DATA = 'BUILD_EMPTY_REGISTER_DATA';
export const BUILD_EMPTY_SIGNIN_DATA = 'BUILD_EMPTY_SIGNIN_DATA';
export const CONFIRM_REGISTER = 'CONFIRM_REGISTER';
export const CONFIRM_REGISTER_SUCCESS = 'CONFIRM_REGISTER_SUCCESS';
export const CONFIRM_REGISTER_FAILURE = 'CONFIRM_REGISTER_FAILURE';
export const LOGIN_CLIENT = 'LOGIN_CLIENT';
export const LOGIN_CLIENT_SUCCESS = 'LOGIN_CLIENT_SUCCESS';
export const LOGIN_CLIENT_FAILURE = 'LOGIN_CLIENT_FAILURE';
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT';
export const LOGOUT_CLIENT_SUCCESS = 'LOGOUT_CLIENT_SUCCESS';
export const LOGOUT_CLIENT_FAILURE = 'LOGOUT_CLIENT_FAILURE';

export const ME_REQUEST = 'ME_REQUEST';
export const ME_REQUEST_SUCCESS = 'ME_REQUEST_SUCCESS';
export const ME_REQUEST_FAILURE = 'ME_REQUEST_FAILURE';

export const SEND_PASSWORD_RECOVERY_EMAIL = 'SEND_PASSWORD_RECOVERY_EMAIL';
export const SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS = 'SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS';
export const SEND_PASSWORD_RECOVERY_EMAIL_FAILURE = 'SEND_PASSWORD_RECOVERY_EMAIL_FAILURE';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';

export const SEND_REGISTER_CONFIRMATION_EMAIL = 'SEND_REGISTER_CONFIRMATION_EMAIL';
export const SEND_REGISTER_CONFIRMATION_EMAIL_SUCCESS = 'SEND_REGISTER_CONFIRMATION_EMAIL_SUCCESS';
export const SEND_REGISTER_CONFIRMATION_EMAIL_FAILURE = 'SEND_REGISTER_CONFIRMATION_EMAIL_FAILURE';