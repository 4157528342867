/**
 * Class to Validate Identity Documents
 */

export const validateIdentityDocument = function(nifToValidate) {
    const DNI_REGEX = /^(\d{8})([A-Z])$/;
    const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
  
    let document = nifToValidate;
    let documentOk = false;
    if (document) {
      document = document.toUpperCase().replace(/\s/, '');
  
      // get document type
      let documentType;
      if (document.match( DNI_REGEX ) ) {
          documentType = 'dni';
      } else if ( document.match( CIF_REGEX ) ) {
          documentType = 'cif';
      } else if ( document.match( NIE_REGEX ) ) {
          documentType = 'nie';
      }
      console.log(documentType);
  
      const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
      let letter = '';
      switch (documentType) {
          case 'dni':
            documentOk = validateDni(document);
            break;
          case 'nie':
            documentOk = validateNie(document);
            break;
          case 'cif':
            documentOk = validateCif(document, CIF_REGEX);
            break;
          default:
            documentOk = false;
            break;
        }
    }
  
    return documentOk;
  }
  
  const validateDni = function(document) {
    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    let letter = dni_letters.charAt(parseInt(document, 10) % 23 );
  
    return letter == document.charAt(8);
  }
  
  const validateNie = function(document) {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix = document.charAt( 0 );
  
    switch (nie_prefix) {
      case 'X': nie_prefix = 0; break;
      case 'Y': nie_prefix = 1; break;
      case 'Z': nie_prefix = 2; break;
    }
    return validateDni(nie_prefix + document.substr(1));
  
  }
  
  const validateCif = function(document, cif_regex) {
    var match = document.match( cif_regex );
    let letter  = match[1],
        number  = match[2],
        control = match[3];
  
    let even_sum = 0;
    let odd_sum = 0;
    let n;
  
    for ( var i = 0; i < number.length; i++) {
      n = parseInt( number[i], 10 );
  
      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if ( i % 2 === 0 ) {
        // Odd positions are multiplied first.
        n *= 2;
  
        // If the multiplication is bigger than 10 we need to adjust
        odd_sum += n < 10 ? n : n - 9;
  
      // Even positions
      // Just sum them
      } else {
        even_sum += n;
      }
    }
  
    // let control_digit = (10 - parseInt((even_sum + odd_sum).toString().substr(-1)) );
    let control_digit = (10 - (even_sum + odd_sum).toString().substr(-1)).toString().substr(-1) ;
    let control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );
  
    // Control must be a digit
    if ( letter.match( /[ABEH]/ ) ) {
      return control == control_digit;
  
    // Control must be a letter
    } else if ( letter.match( /[KPQS]/ ) ) {
      return control == control_letter;
  
    // Can be either
    } else {
      return control == control_digit || control == control_letter;
    }
  
  }
  