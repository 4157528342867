// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';
import {
    setOpenFormAddress,
    buildEmptyAddress,
    getRegions,
    setUserAddress
} from '../../store/user';
import AccountPageEditAddressForm from './AccountPageEditAddressForm';


import SitePageAccessDenied from '../site/SitePageAccessDenied';


class AccountPageAddresses extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onOpenForm = this.onOpenForm.bind(this);
        this.onDeleteAddress = this.onDeleteAddress.bind(this);
        this.onSetMainAddress = this.onSetMainAddress.bind(this);
        this.regionName = this.regionName.bind(this);

    }

    componentDidMount() {
        const { me } = this.props;
        if (me) {
            this.props.setOpenFormAddress(false);
            this.props.buildEmptyAddress();
            this.props.getRegions();
        }
    }

    onSubmit(isCreating, dataForm) {
        const { me } = this.props;

        const data = {
            addresses: me.addresses && me.addresses.length > 0 ? me.addresses : [],
            _id: me._id
        };

        if (isCreating) {
            if (!data.addresses.length > 0) {
                dataForm.status = true;
            }
            data.addresses.push(dataForm);
            this.props.setUserAddress(data);
        } else {
            //Find index of specific object using findIndex method.    
            const objIndex = data.addresses.findIndex((obj => obj._id === dataForm._id));
            //Update object's name property.
            data.addresses[objIndex] = dataForm;
            this.props.setUserAddress(data);
        }
    }

    onOpenForm(open, address) {
        if (open) {
            this.props.getRegions();
        }

        this.props.setOpenFormAddress(open, address);
    }

    onDeleteAddress(address) {
        const { me } = this.props;

        const data = {
            addresses: me.addresses && me.addresses.length > 0 ? me.addresses : [],
            _id: me._id
        };
        //Find index of specific object using findIndex method.    
        const objIndex = data.addresses.findIndex((obj => obj._id === address._id));

        data.addresses.splice(objIndex, 1);


        this.props.setUserAddress(data);
    }

    onSetMainAddress(address) {
        const { me } = this.props;

        const data = {
            addresses: me.addresses && me.addresses.length > 0 ? me.addresses : [],
            _id: me._id
        };

        //Find index of specific object using findIndex method.    
        const objOldMainIndex = data.addresses.findIndex((obj => obj.status === true));
        //Update object's name property.
        data.addresses[objOldMainIndex].status = false;

        //Find index of specific object using findIndex method.    
        const objNewMainIndex = data.addresses.findIndex((obj => obj._id === address._id));
        //Update object's name property.
        data.addresses[objNewMainIndex].status = true;
        this.props.setUserAddress(data);


        this.props.setUserAddress(data);
    }

    regionName(regionCode) {
        const { regions } = this.props;
        if (regions && regions.length > 0) {
            //Find index of specific object using findIndex method.    
            const objIndex = regions.findIndex((obj => obj.code === regionCode));
            //Update object's name property.
            return regions[objIndex].name;
        } else {
            return "";

        }
    }

    render() {
        const { me, openFormAddress, address, regions, savingAddress } = this.props;
        let addresses = [];

        if (!me) {
            return (
                <SitePageAccessDenied />
            );
        }

        if (me && me.addresses && me.addresses.length > 0) {
            addresses = me.addresses.map((address) => (
                <React.Fragment key={address._id}>
                    <div className="addresses-list__item card address-card">
                        {address.status && <div className="address-card__badge"><FormattedMessage id="badge.addresses.default" /></div>}

                        <div className="address-card__body">
                            <div className="address-card__name">{`${address.name}`}</div>
                            <div className="address-card__row">
                                {address.postalCode}
                                , {" "}
                                {address.locality}
                                <br />
                                {this.regionName(address.region)}
                                <br />
                                {address.address}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title"><FormattedMessage id="header.phoneNumber" /></div>
                                <div className="address-card__row-content">{address.phone}</div>
                            </div>
                            {/*<div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">{address.email}</div>
                            </div>*/}
                            <div className="address-card__footer">
                                <Link to="#" onClick={() => this.onOpenForm(true, address)}><FormattedMessage id="button.edit" /></Link>
                                &nbsp;&nbsp;
                                {!address.status &&
                                    <Link to="#" onClick={() => this.onDeleteAddress(address)}><FormattedMessage id="button.remove" /></Link>

                                }
                                &nbsp;&nbsp;
                                {!address.status &&
                                    <Link to="#" onClick={() => this.onSetMainAddress(address)}><FormattedMessage id="address.button.default" /></Link>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="addresses-list__divider" />
                </React.Fragment>
            ));
        }

        if (openFormAddress) {
            return (
                <AccountPageEditAddressForm address={address} provinces={regions} onSubmit={this.onSubmit} loading={savingAddress} onBackPress={() => this.onOpenForm(false)} />
            );
        } else {
            return (
                <div className="addresses-list">
                    <Helmet>
                        <title>{`Direcciones — ${theme.name}`}</title>
                        <meta name="description" content={"Lista de direcciones del usuario"}></meta>
                    </Helmet>

                    <Link to="#" onClick={() => this.onOpenForm(true)} className="addresses-list__item addresses-list__item--new">
                        <div className="addresses-list__plus" />
                        <div className="btn btn-secondary btn-sm"><FormattedMessage id="button.new" /></div>
                    </Link>
                    <div className="addresses-list__divider" />
                    {addresses}
                </div>
            );
        }


    }
}

// map state to props
const mapStateToProps = ({ authApp, userApp, settings }) => {
    const { me } = authApp;
    const { openFormAddress, address, regions, savingAddress } = userApp;
    return { me, openFormAddress, address, regions, savingAddress, settings };
}

const mapDispatchToProps = {
    setOpenFormAddress,
    buildEmptyAddress,
    getRegions,
    setUserAddress
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageAddresses);