/**
 * Password Recovery Request Page
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import { Link } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';

import { FormattedMessage } from 'react-intl';

import PasswordRecoveryRequestForm from './PasswordRecoveryRequestForm';

// redux action
import {
    sendPasswordRecoveryEmail
} from '../../store/auth';

class PasswordRecoveryRequest extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        this.props.sendPasswordRecoveryEmail(values.email);
    }

    render() {
        const { loading, emailDoesNotExist, sentMail } = this.props;

        return (
            <div className="block about-us">
                <Helmet>
                    <title>{`Solicitud de restablecimiento de contraseña — ${theme.name}`}</title>
                    <meta name="description" content={"Introducir nuestro correo electrónico para recibir los pasos para cambiar la contraseña del usuario"}></meta>
                </Helmet>

                <div className="about-us__image" style={{ backgroundImage: `url(${require('../../assets/img/slides/slider7.jpg')})` }} />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-10">
                            <div className="about-us__body">
                                <div className="session-inner-wrapper">
                                    <div className="container">
                                        <div className="row row-eq-height justify-content-center">
                                            <div className="col-sm-12 col-md-8 col-lg-8">
                                                <div className="session-body text-center">
                                                    <div className="session-head mb-20">

                                                        <h3 className="login-header"><FormattedMessage id="passwordRecoveryRequest.label.info" /></h3>
                                                        {sentMail ?
                                                            <p><FormattedMessage id="passwordRecoveryRequest.message.sentEmail" /></p>
                                                            :
                                                            <p><FormattedMessage id="passwordRecoveryRequest.label.text" /></p>
                                                        }
                                                    </div>
                                                    {!sentMail &&
                                                        <PasswordRecoveryRequestForm
                                                            emailDoesNotExist={emailDoesNotExist}
                                                            email={''}
                                                            onSubmit={this.onSubmit}
                                                            loading={loading}
                                                        />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

// map state to props
const mapStateToProps = ({ authApp }) => {
    const { loading, emailDoesNotExist, sentMail } = authApp;
    return { loading, emailDoesNotExist, sentMail }
}

export default connect(mapStateToProps, {
    sendPasswordRecoveryEmail
})(PasswordRecoveryRequest);
