// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { NotificationContainer } from 'react-notifications';
import { Route, Switch, Redirect } from 'react-router-dom';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';
//routes
import Routes from './Routes';

// data stubs
import theme from '../data/theme';


function Layout(props) {
    const { match, headerLayout, homeComponent } = props;

    return (
            <React.Fragment>

                <Helmet>
                    <title>{theme.name}</title>
                    <meta name="description" content={theme.fullName} />
                </Helmet>

                <ToastContainer autoClose={5000} hideProgressBar />

                <Quickview />

                <MobileMenu />

                <div className="site">
                    <header className="site__header d-lg-none">
                        {window.innerWidth < 768 && <MobileHeader />}
                    </header>

                    <header className="site__header d-lg-block d-none">
                        <Header layout={headerLayout} />
                    </header>

                    <div className="site__body">
                        <NotificationContainer />
                        <Routes match={match} homeComponent={homeComponent}>
                        </Routes>
                    </div>

                    <footer className="site__footer">
                        <Footer />
                    </footer>
                </div>
            </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
