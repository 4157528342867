/**
 * Root Sagas
 */
import { all } from 'redux-saga/effects';
// VIACORE
import productsSagas from './products/productsSagas';
import familyGroupsSagas from './familygroups/familyGroupsSagas';
import authSagas from './auth/authSagas';
import userSagas from './user/userSagas';
import cartSagas from './cart/cartSagas';

export default function* rootSaga() {
    yield all([
        productsSagas(),
        familyGroupsSagas(),
        authSagas(),
        userSagas(),
        cartSagas()
    ]);
}