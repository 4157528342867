/**
* User Sagas
*/
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// api
import * as api from '../../api';

import * as selectors from './selectors';

// app config
import AppConfig from '../../util/AppConfig';

import {
    EDIT_USER,
    GET_REGIONS,
    SET_USER_ADDRESS,
    GET_USER_ORDERS,
    CHANGE_USER_PASSWORD
} from './userActionTypes';

import {
    editUserSuccess,
    editUserFailure,
    getRegionsSuccess,
    getRegionsFailure,
    setUserAddressSuccess,
    setUserAddressFailure,
    getUserOrdersSuccess,
    getUserOrdersFailure,
    changeUserPasswordSuccess,
    changeUserPasswordFailure
} from './userActions';

import {
    meRequestSuccess,
    meRequestFailure,
} from '../auth/authActions';

function* fetchMe() {
    try {
        const me = yield call(api.auth.fetchMeRequest);
        yield put(meRequestSuccess(me));
    }
    catch (error) {
        yield put(meRequestFailure(error));
    }
}

function* editUserFromServer({ payload }) {
    try {
        const response = yield call(api.user.editUserRequest, payload);
        yield put(editUserSuccess(response));
        // get me
        yield call(fetchMe);
    } catch (error) {
        yield put(editUserFailure(error));
    }
};

function* getRegionsFromServer() {
    try {
        const response = yield call(api.user.getRegionsRequest);
        yield put(getRegionsSuccess(response));
    } catch (error) {
        yield put(getRegionsFailure(error));
    }
};

function* setUserAddressFromServer({ payload }) {
    try {
        const response = yield call(api.user.setUserAddressRequest, payload);
        yield put(setUserAddressSuccess(response));
        // get me
        yield call(fetchMe);
    } catch (error) {
        yield put(setUserAddressFailure(error));
    }
};

function* getUserOrdersFromServer({ payload }) {
    try {
        const ordersPageIndex = yield select(selectors.ordersPageIndex);

        const response = yield call(api.user.getUserOrdersRequest, payload, ordersPageIndex - 1, AppConfig.ordersPageSize);
        yield put(getUserOrdersSuccess(response));
    } catch (error) {
        yield put(getUserOrdersFailure(error));
    }
};

function* changeUserPasswordFromServer({ payload }) {
    try {
        const { password, userId } = payload;
        const passwordBody = {
            password: password
        };

        const response = yield call(api.user.changeUserPasswordRequest, passwordBody, userId);
        yield put(changeUserPasswordSuccess(response));
    } catch (error) {
        yield put(changeUserPasswordFailure(error));
    }
};

export function* editUser() {
    yield takeEvery(EDIT_USER, editUserFromServer);
}

export function* getRegions() {
    yield takeEvery(GET_REGIONS, getRegionsFromServer);
}

export function* setUserAddress() {
    yield takeEvery(SET_USER_ADDRESS, setUserAddressFromServer);
}

export function* getUserOrders() {
    yield takeEvery(GET_USER_ORDERS, getUserOrdersFromServer);
}

export function* changeUserPassword() {
    yield takeEvery(CHANGE_USER_PASSWORD, changeUserPasswordFromServer);
}

export default function* rootSaga() {
    yield all([
        fork(editUser),
        fork(getRegions),
        fork(setUserAddress),
        fork(getUserOrders),
        fork(changeUserPassword)

    ]);
}