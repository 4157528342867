export default [
    {
        id: 1,
        name: 'ELECTRODO DE RUTIOLO',
        price: 749,
        compareAtPrice: null,
        images: ['../../assets/img/products/prod.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 2,
        name: 'ARDROX PMD 240 ',
        price: 1019,
        compareAtPrice: null,
        images: ['../assets/img/products/product-2.jpg', '../assets/img/products/product-2-1.jpg'],
        badges: ['hot'],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 3,
        name: 'L-AG66SN',
        price: 850,
        compareAtPrice: null,
        images: ['../assets/img/products/product-3.jpg', '../assets/img/products/product-3-1.jpg'],
        badges: [],
        rating: 4,
        reviews: 8,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 4,
        name: 'LFB-FC',
        price: 949,
        compareAtPrice: 1189,
        images: ['../assets/img/products/product-4.jpg', '../assets/img/products/product-4-1.jpg'],
        badges: ['sale'],
        rating: 3,
        reviews: 15,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 5,
        name: 'L-AG2',
        price: 1700,
        compareAtPrice: null,
        images: ['../assets/img/products/product-5.jpg', '../assets/img/products/product-5-1.jpg'],
        badges: [],
        rating: 4,
        reviews: 2,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 6,
        name: 'HILOS MACIZOS',
        price: 3199,
        compareAtPrice: null,
        images: ['../assets/img/products/product-6.jpg', '../assets/img/products/product-6-1.jpg'],
        badges: [],
        rating: 3,
        reviews: 21,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 7,
        name: 'SG-2',
        price: 24,
        compareAtPrice: null,
        images: ['../assets/img/products/product-7.jpg', '../assets/img/products/product-7-1.jpg'],
        badges: [],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 8,
        name: 'LINOX 309L',
        price: 15,
        compareAtPrice: null,
        images: ['../assets/img/products/product-8.jpg', '../assets/img/products/product-8-1.jpg'],
        badges: [],
        rating: 2,
        reviews: 5,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 9,
        name: 'JUNGO 4500',
        price: 19,
        compareAtPrice: null,
        images: ['../assets/img/products/product-9.jpg', '../assets/img/products/product-9-1.jpg'],
        badges: [],
        rating: 4,
        reviews: 34,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 10,
        name: 'INNERSHIELD NR-211MP',
        price: 15,
        compareAtPrice: null,
        images: ['../assets/img/products/product-10.jpg', '../assets/img/products/product-10-1.jpg'],
        badges: [],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 11,
        name: 'HANDLE KIT ALUTORCHE',
        price: 149,
        compareAtPrice: null,
        images: ['../assets/img/products/product-11.jpg', '../assets/img/products/product-11-1.jpg'],
        badges: [],
        rating: 4,
        reviews: 7,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 12,
        name: 'O RING 6X1 MM ALUTORCHE',
        price: 666.99,
        compareAtPrice: null,
        images: ['../assets/img/products/product-12.jpg', '../assets/img/products/product-12-1.jpg'],
        badges: [],
        rating: 5,
        reviews: 17,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 13,
        name: 'TRIGGER RED ALUTORCHE',
        price: 649,
        compareAtPrice: null,
        images: ['../assets/img/products/product-13.jpg', '../assets/img/products/product-13-1.jpg'],
        badges: [],
        rating: 2,
        reviews: 8,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 14,
        name: 'PLANETARY FEED HEAD ALUTORCHE',
        price: 1800,
        compareAtPrice: null,
        images: ['../assets/img/products/product-14.jpg', '../assets/img/products/product-14-1.jpg'],
        badges: [],
        rating: 3,
        reviews: 14,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 15,
        name: 'COMPLET HANDLE GUN',
        price: 290,
        compareAtPrice: null,
        images: ['../assets/img/products/product-15.jpg', '../assets/img/products/product-15-1.jpg'],
        badges: [],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
    {
        id: 16,
        name: 'FRONT BODY',
        price: 1499,
        compareAtPrice: null,
        images: [
            '../assets/img/products/product-16.jpg',
            '../assets/img/products/product-16-1.jpg',
            '../assets/img/products/product-16-2.jpg',
            '../assets/img/products/product-16-3.jpg',
            '../assets/img/products/product-16-4.jpg',
        ],
        badges: [],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        features: [
            { name: 'Speed', value: '750 RPM' },
            { name: 'Power Source', value: 'Cordless-Electric' },
            { name: 'Battery Cell Type', value: 'Lithium' },
            { name: 'Voltage', value: '20 Volts' },
            { name: 'Battery Capacity', value: '2 Ah' },
        ],
        options: [],
    },
];
