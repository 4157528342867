// react
import React from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import Collapse from '../shared/Collapse';
import FilterCheckbox from '../filters/FilterCheckbox';


function WidgetFilters(props) {
    const {
        title,
        offcanvas,
        groupsFilter,
        onGroupSelect,
        onFamilyGroupSelect,
        selectedGroups,
        familyGroupsFilter,
        selectedFamilyGroups,
        onFamilySelect,
        selectedFamilies,
        familiesFilter,
        manufacturersFilter,
        onManufacturerSelect,
        selectedManufacturers,
        onCleanFilters
     } = props;

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{title}</h4>

            <div className="widget-filters__list">
                {familiesFilter && familiesFilter.length > 0 &&
                    <Collapse
                        toggleClass="filter--opened"
                        render={({ toggle, setItemRef, setContentRef }) => (
                            <div className="filter filter--opened" ref={setItemRef}>
                                <p className="filter__title" >
                                    <FormattedMessage id="products.filter.families" />
                                </p>
                                <div className="filter__body" ref={setContentRef}>
                                    <div className="filter__container">
                                        <FilterCheckbox onSelect={onFamilySelect} items={familiesFilter} selectedItems={selectedFamilies}/>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                }

                {familyGroupsFilter && familyGroupsFilter.length > 0 &&
                    <Collapse
                        toggleClass="filter--opened"
                        render={({ toggle, setItemRef, setContentRef }) => (
                            <div className="filter filter--opened" ref={setItemRef}>
                                <p className="filter__title" >
                                    <FormattedMessage id="products.filter.familygroups" />
                                </p>
                                <div className="filter__body" ref={setContentRef}>
                                    <div className="filter__container">
                                        <FilterCheckbox onSelect={onFamilyGroupSelect} items={familyGroupsFilter} selectedItems={selectedFamilyGroups} />
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                }

                {groupsFilter && groupsFilter.length > 0 &&
                    <Collapse
                        toggleClass="filter--opened"
                        render={({ toggle, setItemRef, setContentRef }) => (
                            <div className="filter filter--opened" ref={setItemRef}>
                                <p className="filter__title" >
                                    <FormattedMessage id="products.filter.subfamilies" />
                                </p>
                                <div className="filter__body" ref={setContentRef}>
                                    <div className="filter__container">
                                        <FilterCheckbox onSelect={onGroupSelect} items={groupsFilter} selectedItems={selectedGroups} />
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                }
            </div>

            <div className="widget-filters__actions d-flex">
                {/*<button type="button" className="btn btn-primary btn-sm"><FormattedMessage id="app.button.filter"/></button>*/}
                <button type="button" onClick={() => {onCleanFilters()}} className="btn btn-primary btn-sm"><FormattedMessage id="app.button.reset" /></button>
            </div>
        </div>
    );
}

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * array of filters
     */
    filters: PropTypes.array,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
    onGroupSelect: PropTypes.func.isRequired,
    onFamilyGroupSelect: PropTypes.func.isRequired,
    selectedFamilyGroups: PropTypes.array.isRequired,
    selectedGroups: PropTypes.array.isRequired,
    familiesFilter: PropTypes.array.isRequired,
    groupsFilter: PropTypes.array.isRequired,
    familyGroupsFilter: PropTypes.array.isRequired,
    onFamilySelect: PropTypes.func.isRequired,
    selectedFamilies: PropTypes.array.isRequired,
    manufacturersFilter: PropTypes.array.isRequired,
    onManufacturerSelect: PropTypes.func.isRequired,
    selectedManufacturers: PropTypes.array.isRequired,
    onCleanFilters: PropTypes.func.isRequired,
};

WidgetFilters.defaultProps = {
    filters: [],
    offcanvas: 'mobile',
};

export default WidgetFilters;
