import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import wishlistReducer from './wishlist';
import productsReducer from './products';
import familyGroupsReducer from './familygroups';
import authReducer from './auth';
import userReducer from './user';


export default combineReducers({
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    productsApp: productsReducer,
    familyGroupsApp: familyGroupsReducer,
    authApp: authReducer,
    userApp: userReducer
});
