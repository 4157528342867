// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';

// data stubs
import currencies from '../../data/shopCurrencies';
import mobileMenuLinks from '../../data/mobileMenu';

//Otener grupos familia con productos
function getFamilyGroupsWithProducts(arr1, arr2) {
    const familyGroupsWithProducts = [];
    arr1.forEach((a1) => arr2.forEach((a2) => { if (a1._id === a2._id) { familyGroupsWithProducts.push({ type: 'link', url: '/busqueda-avanzada?familyGroup=' + a2._id, _id: a2._id, label: a2.title }) } }));
    return familyGroupsWithProducts;
}

function getResult(arr1, familyId) {
    let result = false;
    if (arr1.some(e => e.family === familyId)) {
        result = true;
    }
    return result;
}

function MobileMenu(props) {
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
        me
    } = props;

    let categoriesMenu = [
        {
            type: 'link', url: '/', noUrl: true, label: 'Inicio'
        },
        me && me._id ? {
            type: 'link', url: '/mi-cuenta/editar-perfil', noUrl: true, label: 'Mi cuenta', children: [
                { type: 'link', url: '/account/addresses', noUrl: true, label: 'Direcciones' }
            ]
        } : {},
        {
            type: 'link', url: '/', noUrl: true, label: 'Buscar por categoría', children: []
        },
    ];

    for (var itemFamily of props.familiesWithFamilyGroups) {
        if (getResult(props.familyGroupsFilter, itemFamily._id)) {
            categoriesMenu[2].children.push({ _id: itemFamily._id, type: 'link', url: '#', noUrl: true, label: itemFamily.name, children: getFamilyGroupsWithProducts(props.familyGroupsFilter, itemFamily.familyGroups) });

        }
    }


    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={categoriesMenu} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    groups: state.productsApp.groups,
    familyGroupsFilter: state.productsApp.familyGroupsFilter,
    familiesWithFamilyGroups: state.familyGroupsApp.familiesWithFamilyGroups,
    me: state.authApp.me
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
