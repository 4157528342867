import client from './client';

export const editUserRequest = async (data) => {
    let queryUrl = `/users/shop/${data._id}`;
    return await client.put(queryUrl, data)
        .then(response => response.data);       
};

export const getRegionsRequest = async () => {
    let queryUrl = `/provinces/all`;
    return await client.get(queryUrl)
        .then(response => response.data);       
};

export const setUserAddressRequest = async (data) => {
    let queryUrl = `/users/shop/set-address`;
    return await client.post(queryUrl, data)
        .then(response => response.data);       
};

export const getUserOrdersRequest = async (data, pageIndex, pageSize) => {
    let queryUrl = `/orders?user=${data}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    return await client.get(queryUrl, data)
        .then(response => response.data);       
};

export const changeUserPasswordRequest = async (passwordBody, userId) => {
    let queryUrl = `/users/shop/change-password/${userId}`;
    return await client.put(queryUrl, passwordBody)
        .then(response => response.data);       
};