/**
 * Password Recovery Request Page
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import { Link } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';

import { FormattedMessage } from 'react-intl';

import AccountRestorePasswordForm from './AccountRestorePasswordForm';

// redux action
import {
    recoverPassword
} from '../../store/auth';

class PasswordRecoveryRequest extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        this.props.recoverPassword(values.password, this.props.location.search.substring(1), this.props.history);
    }

    render() {
        const { loading, tokenExpired, passwordRecovered } = this.props;

        return (
            <div className="block about-us">
                <Helmet>
                    <title>{`Nueva contraseña — ${theme.name}`}</title>
                    <meta name="description" content={"Formulario para realizar el cambio de contraseña"}></meta>
                </Helmet>

                <div className="about-us__image" style={{ backgroundImage: `url(${require('../../assets/img/slides/slider7.jpg')})` }} />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-10">
                            <div className="about-us__body">
                                <div className="session-inner-wrapper">

                                    {!passwordRecovered ?
                                        <div className="container">
                                            <div className="row row-eq-height justify-content-center">
                                                <div className="col-sm-12 col-md-8 col-lg-8">
                                                    <div className="session-body text-center">
                                                        <AccountRestorePasswordForm
                                                            onSubmit={this.onSubmit}
                                                            password={''}
                                                            passwordConfirmation={''}
                                                            tokenExpired={tokenExpired}
                                                            loading={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="container">
                                            <div className="row row-eq-height justify-content-center">
                                                <div className="session-head mb-30">
                                                    <h3 className="login-header"><FormattedMessage id="passwordRecoveryDone.label.info" /></h3>
                                                </div>
                                                <div >
                                                    <p className="mt-10"><FormattedMessage id="passwordRecoveryDone.text.firstLine" /></p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

// map state to props
const mapStateToProps = ({ authApp }) => {
    const { loading, tokenExpired, passwordRecovered } = authApp;
    return { loading, tokenExpired, passwordRecovered }
}

export default connect(mapStateToProps, {
    recoverPassword
})(PasswordRecoveryRequest);
