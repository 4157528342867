// react
import React from 'react';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';


export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title"><FormattedMessage id="home.contact" /></h5>

            <div className="footer-contacts__text">
                <FormattedMessage id="footer.contactUs.message" />
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    <FormattedMessage id={theme.contacts.address} />
                </li>
                <li>
                    <FormattedMessage id={theme.contacts.postalCode} />
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    <FormattedMessage id={theme.contacts.email} />
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    <FormattedMessage id={theme.contacts.phone} />
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    <FormattedMessage id="footer.contactUs.schedule" />
                </li>
            </ul>
        </div>
    );
}
