import client from './client';

export const registerRequest = async (userCredentials) => {
    let queryUrl = `/register`;
    return await client.post(queryUrl, userCredentials)
        .then(response => response.data);
};

export const confirmRegisterRequest = async (token) => {
    let queryUrl = `/confirm-register/${token}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const signInClientWithEmailPasswordRequest = async (credentials) => {
    let queryUrl = `/login-client`;
    return await client.post(queryUrl, credentials)
        .then(response => response.data);
};

export const fetchMeRequest = async () => {
    let queryUrl = `/me`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const sendPasswordRecoveryEmailToServer = async (email) => {
    await client.put('send-password-recovery-email', { 'email': email })
        .then(response => response);
};

export const sendRecoverPasswordRequestToServer = async (password, token) => {
    await client.put('shop/recover-password', { 'password': password, 'token': token })
        .then(response => response);
};

export const sendRegisterConfirmationEmailToServer = async (email) => {
    await client.put('send-confirm-registration-email', { 'email': email })
        .then(response => response);
};
