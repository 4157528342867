// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// application
import Pagination from '../shared/Pagination';
import Rating from '../shared/Rating';

import { FormattedMessage } from 'react-intl';
import ProductReviewForm from './ProductReviewForm';

import { buildEmptyProductReview, addProductReview, getProductReviews } from '../../store/products';
import RctSectionLoader from '../../components/RctSectionLoader/RctSectionLoader';
import moment from 'moment';
import 'moment-timezone';

class ProductTabReviews extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }


    componentDidMount() {
        const { product } = this.props;
        this.props.buildEmptyProductReview(product._id);
        //this.props.getProductReviews(product._id);
    }

    onSubmit(data) {
        const { product } = this.props;
        this.props.addProductReview(data);
        //this.props.getProductReviews(product._id);
    }

    render() {
        const { productReview, savingReview, loadingReviews, reviews, me } = this.props;

        let reviewsList = [];
        if (reviews && reviews.length > 0) {
            reviewsList = reviews.map((review, index) => (
                <li key={index} className="reviews-list__item">
                    <div className="review">
                        <div className=" review__content">
                            <div className=" review__author">{review.reviewBy}</div>
                            <div className=" review__rating">
                                <Rating value={review.stars} />
                            </div>
                            <div className=" review__text">{review.review}</div>
                            <div className=" review__date">{moment(review.createdAt.date, moment.ISO_8601).tz(review.createdAt.timezone).format("DD/MM/YYYY hh:mm")}</div>
                        </div>
                    </div>
                </li>
            ));
        }

        if (loadingReviews) {
            return (
                <RctSectionLoader />
            );
        }

        return (
            <React.Fragment>
                <div className="reviews-view">
                    <div className="reviews-view__list">
                        <h3 className="reviews-view__header">{<FormattedMessage id="product.review.title" />}</h3>

                        <div className="reviews-list">
                            <ol className="reviews-list__content">
                                {reviewsList}
                            </ol>
                        </div>
                    </div>

                    {me &&
                        <ProductReviewForm productReview={productReview} onSubmit={this.onSubmit} savingReview={savingReview} />
                    }
                </div>
            </React.Fragment>

        );
    }
}

// map state to props
const mapStateToProps = ({ productsApp, authApp, settings }) => {
    const { me } = authApp;
    const { productReview, savingReview, loadingReviews, reviews } = productsApp;
    return { productReview, savingReview, loadingReviews, reviews, me, settings };
}

const mapDispatchToProps = {
    buildEmptyProductReview,
    addProductReview,
    getProductReviews
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductTabReviews);