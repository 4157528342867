// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';


function SitePageLegalWarning() {
    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: <FormattedMessage id="legal.warning.title" />, url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Aviso Legal`}</title>
                <meta name="description" content={"AVISO LEGAL del dominio www.vercanminimizados.com correspondiente al Responsable del tratamiento: VERCAN MINIMIZADOS, S.L. con nº de CIF B39521661 ..."}></meta>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title"><FormattedMessage id="legal.warning.title" /></h1>
                            {/* <div className="document__subtitle">This Agreement was last modified on 27 May 2018.</div> */}
                        </div>
                        <div className="document__content typography text-justify">
                            <p>
                                AVISO LEGAL del dominio <a href="http://tienda.vercanminimizados.com/">www.vercanminimizados.com</a> correspondiente al  Responsable del tratamiento: VERCAN MINIMIZADOS, S.L. con nº de CIF B39521661 y con domicilio en P.I. La Cerrada I, nave 28. 39600 Maliaño – Cantabria.
                            </p>

                            <h2>Condiciones generales de utilización de la web</h2>
                            <p>
                                VERCAN MINIMIZADOS, S.L. le informa de que el acceso y utilización de la página web www.vercanminimizados.com y todas las URLs, subdominios y directorios incluidos bajo la misma, así como los servicios o contenidos que a través de este sitio se puedan obtener, están sujetos a los términos recogidos y detallados en este Aviso Legal, sin perjuicio de que el acceso a alguno de dichos servicios o contenidos pueda precisar de la aceptación de unas condiciones generales, particulares o adicionales.
                                Por consiguiente, si las consideraciones detalladas en este Aviso Legal no son de su conformidad, rogamos que no haga uso de esta web, ya que cualquier utilización que haga de ella o de los servicios y contenidos en ella incluidos, implicará la aceptación de los términos legales recogidos en el texto de este Aviso Legal.                            </p>

                            <p>
                                Debido a la propia naturaleza de Internet, dada la posibilidad de que se pueda acceder a esta página desde cualquier parte del mundo, los contenidos, así como los servicios que en general ofrece VERCAN MINIMIZADOS, S.L. están dirigidos a clientes que se mueven en cualquier país. No obstante lo anterior, al solicitar la contratación de cualquier tipo de servicios y contenidos ofrecidos, VERCAN MINIMIZADOS, S.L. se reserva el derecho a rechazar la prestación de servicios o el envío de contenidos, en aquellos casos que estime oportuno.
                            </p>

                            <p>
                                VERCAN MINIMIZADOS, S.L. se reserva el derecho a realizar cambios en la web sin previo aviso, con el objeto de actualizar, corregir, modificar, añadir, cancelar o eliminar los contenidos o el diseño de la web. Los servicios y contenidos de la web son susceptibles de actualizarse periódicamente y debido a que la actualización de la información no es inmediata, le sugerimos que compruebe siempre la vigencia y exactitud de la información, servicios y contenidos recogidos aquí.
                            </p>

                            <p>
                                Las condiciones y términos de utilización que se recogen en el presente Aviso Legal pueden cambiar, por lo que le proponemos que revise estos términos cuando visite de nuevo la web o solicite un nuevo servicio. Asimismo, el presente Aviso Legal se entenderá sin perjuicio de cualesquiera otras Condiciones Generales, y particulares, que regulen el acceso a bienes y servicios concretos dentro de la web.
                            </p>

                            <h2>Derechos de propiedad intelectual y de propiedad industrial</h2>

                            <p>
                                Tanto el diseño de este sitio web, sus códigos fuente, logotipos, imágenes, melodías, marcas y demás signos distintivos que aparecen, pertenecen a sus respectivos autores y están protegidos por los correspondientes derechos de propiedad intelectual e industrial.
                                Su utilización, reproducción, distribución, comunicación pública, transformación o cualquier otra acción semejante, está totalmente prohibida salvo autorización expresa por escrito de su creador o propietario de los derechos.
    En todo caso, VERCAN MINIMIZADOS, S.L. declara su respeto a los derechos de propiedad intelectual e industrial de terceros; por ello, si considera que este sitio pudiera estar violando sus derechos, rogamos se ponga en contacto con  VERCAN MINIMIZADOS, S.L.
                            </p>

                            <h2>Links o hiperenlaces</h2>

                            <p>
                                Desde esta web, VERCAN MINIMIZADOS, S.L. le proporciona o puede proporcionarle el acceso a otras páginas web que considera pueden ser de su interés. El objeto de dichos enlaces es meramente el facilitar la búsqueda de los recursos que le puedan interesar en Internet. No obstante, dichas páginas no pertenecen a VERCAN MINIMIZADOS, S.L., ni hace una revisión de sus contenidos, por ello, no se hace responsable de los mismos, del funcionamiento de la página enlazada o de los posibles daños que puedan derivarse del acceso o uso de la misma. Asimismo, VERCAN MINIMIZADOS, S.L. se muestra plenamente respetuosa con los derechos de propiedad intelectual o industrial que correspondan o puedan corresponder a terceras personas, sobre las páginas web a las que se refieran los citados enlaces. Por tal motivo, si considera que el establecimiento de los citados enlaces pudiera estar violando sus derechos, rogamos se ponga en contacto con VERCAN MINIMIZADOS, S.L.
                            </p>

                            <p>
                                Con carácter general se autoriza el enlace de páginas web o de direcciones de correo electrónico a la web, excepción hecha de aquellos supuestos en los que, expresamente VERCAN MINIMIZADOS, S.L. manifieste lo contrario. Adicionalmente, y en todo caso para entender aplicable esta autorización general, dichos enlaces deberán respetar, necesariamente, la siguiente condición: el establecimiento del enlace no supondrá, por sí mismo, ningún tipo de acuerdo, contrato, patrocinio ni recomendación por parte de VERCAN MINIMIZADOS, S.L. de la página que realiza el enlace.
    No obstante lo anterior, en cualquier momento VERCAN MINIMIZADOS, S.L. podrá retirar la autorización mencionada en el párrafo anterior, sin necesidad de alegar causa alguna. En tal caso, la página que haya realizado el enlace deberá proceder a su inmediata supresión, tan pronto como reciba la notificación de la revocación de la autorización por parte de VERCAN MINIMIZADOS, S.L.
                            </p>

                            <h2>Frames o marcos</h2>

                            <p>
                                VERCAN MINIMIZADOS, S.L. prohíbe expresamente la realización de "framings" o la utilización por parte de terceros de cualesquiera otros mecanismos que alteren el diseño, configuración original o contenidos web.
                            </p>

                            <h2>Publicidad</h2>

                            <p>
                                En la web se insertará aquella publicidad de VERCAN MINIMIZADOS, S.L. o de terceras empresas anunciantes que consideremos pueda ser de su interés.
    Sin perjuicio de lo establecido en la Política de Privacidad, VERCAN MINIMIZADOS, S.L. se compromete a no facilitar información suya a los anunciantes, salvo datos estadísticos anónimos sobre la utilización de la web, con la finalidad de que se pueda mejorar el servicio y ofrecer productos conforme a las expectativas de los usuarios.
    No obstante, VERCAN MINIMIZADOS, S.L. le informa de que los anunciantes y proveedores, por medio de cookies, pueden tener la posibilidad de obtener información sobre usted, los usos que hace de los servicios y sus movimientos a través de la red. Por no tener vinculación con los usos que de dicha información pueda realizar la empresa anunciante, VERCAN MINIMIZADOS, S.L. no se hace responsable de la recogida de información por dichas empresas.
    VERCAN MINIMIZADOS, S.L. puede utilizar cookies cuando un usuario navega por sus sitios y páginas web. Las cookies que se puedan utilizar en los sitios y páginas web de VERCAN MINIMIZADOS, S.L. se pueden consultar en nuestra Política de Cookies.
                            </p>

                            <h2>Privacidad</h2>

                            <p>
                                Este sitio Web, se compromete en el cumplimiento del REGLAMENTO (UE) 2016/679 de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y de la Ley 03/2018 de 5 de diciembre (LOPDGDD), manteniendo una política de privacidad sobre los datos personales en el uso que de ellos hace VERCAN MINIMIZADOS, S.L., pudiendo consultarla en nuestra Política de Privacidad.
                            </p>

                            <h2>Responsabilidades del usuario</h2>

                            <p>
                                El usuario se compromete a utilizar los servicios de la web de acuerdo con los términos expresados en el presente Aviso Legal, siendo responsable de su uso correcto.
                            </p>

                            <p>
                                El usuario que actúe contra la imagen, buen nombre o reputación de VERCAN MINIMIZADOS, S.L. así como quien utilice ilícita o fraudulentamente los diseños, logos o contenidos de la web y/o atente en cualquier forma contra los derechos de propiedad intelectual e industrial de la web o de los contenidos y servicios de la misma, será responsable frente a VERCAN MINIMIZADOS, S.L. de su actuación.
                            </p>

                            <h2>Responsabilidades de VERCAN MINIMIZADOS, S.L.</h2>

                            <p><strong>Utilización incorrecta:</strong></p>
                            <p>VERCAN MINIMIZADOS, S.L. ha creado la web para la promoción de sus productos y/o servicios, pero no puede controlar la utilización del mismo de forma distinta a la prevista en el presente Aviso Legal; por lo tanto, el acceso y uso correcto de la información contenida en la web son responsabilidad de quien realiza estas acciones, no siendo responsable VERCAN MINIMIZADOS, S.L. por el uso incorrecto, ilícito o negligente que del mismo pudiere hacer el usuario.</p>

                            <p><strong>Contenidos:</strong></p>

                            <p>VERCAN MINIMIZADOS, S.L. facilita todos los contenidos de su web, bajo determinadas condiciones de buena fe, y se esforzará en la medida de lo posible para que los mismos estén actualizados y vigentes; no obstante, VERCAN MINIMIZADOS, S.L. no puede asumir responsabilidad alguna respecto al uso o acceso que realicen los usuarios fuera del ámbito al que se dirige la web, cuya responsabilidad final recaerá sobre el usuario. Asimismo VERCAN MINIMIZADOS, S.L. no puede controlar los contenidos que no hayan sido elaborados por ella o por terceros cumpliendo su encargo por lo que, no responderá en ningún caso de los daños, contenidos e indisponibilidades técnicas que pudieran causarse por parte de dichos terceros.
                            </p>

                            <p><strong>Publicidad:</strong></p>
                            <p>
                                VERCAN MINIMIZADOS, S.L. incluirá en la web publicidad propia o de terceros para ofrecerle productos o servicios que entienda que pueden ser de su interés. Sin embargo, y en lo que respecta a la publicidad de terceros, VERCAN MINIMIZADOS, S.L. no puede controlar la apariencia de dicha publicidad, ni la calidad y adecuación de los productos o servicios a que esta se refiera, por lo que no responderá de ningún daño que se pudiera generar al usuario por dichas causas atribuibles a terceros.
                            </p>

                            <p><strong>Virus:</strong></p>
                            <p>
                                VERCAN MINIMIZADOS, S.L. se compromete a aplicar en la medida de lo posible, las medidas oportunas a su alcance para intentar garantizar al usuario la ausencia de virus, gusanos, troyanos, spam, etc… en su web. No obstante, estas medidas no son 100% infalibles y, por ello, VERCAN MINIMIZADOS, S.L. no puede asegurar totalmente la ausencia de dichos elementos indeseables. En consecuencia, VERCAN MINIMIZADOS, S.L. no será responsable de los daños que los mismos pudieran producir al usuario.
                            </p>

                            <p><strong>Fallos tecnológicos:</strong></p>
                            <p>
                                VERCAN MINIMIZADOS, S.L. pone los medios necesarios a su alcance para la continuidad de esta web y realizará sus mejores esfuerzos para que el mismo no sufra interrupciones, pero no puede garantizar la ausencia de fallos tecnológicos, ni la permanente disponibilidad de la web y de los servicios contenidos en él, en consecuencia no se asume responsabilidad alguna por los daños y perjuicios que puedan generarse por la falta de disponibilidad y por los fallos en el acceso ocasionados por desconexiones, averías, sobrecargas o caídas de la red no imputables a VERCAN MINIMIZADOS, S.L.
                            </p>

                            <h2>Ley aplicable y jurisdicción</h2>

                            <p>
                                La Ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman este Aviso Legal, así como cualquier cuestión relacionada con los servicios de la presente web, será la española.
    Para la resolución de cualquier conflicto que pueda surgir con ocasión de la visita a la web, VERCAN MINIMIZADOS, S.L. y el usuario acuerdan someterse a los jueces y tribunales de lo mercantil de CANTABRIA.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageLegalWarning;
