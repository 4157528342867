/**
 * Login Form
 */
import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../Form/FormInput';
import { FormattedMessage } from 'react-intl';
import { Form, Label, Input, FormGroup } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { validateIdentityDocument } from "../../validators/IdentityDocumentValidator";
import { Helmet } from 'react-helmet-async';
import theme from '../../data/theme';

const formikEnhancer = withFormik({
    validationSchema: (props) => {
        Yup.addMethod(Yup.string, 'nifValidation', function (message) {
            return this.test('nifValidation', message, function (value) {
                const { path, createError } = this;
                const document = value;
                let documentOk = validateIdentityDocument(document);
                if (!value) {
                    return true;
                }

                if (!documentOk) {
                    return createError({ path, message: 'El documento de identidad no es correcto' });
                } else {
                    return true;
                }
            });
        });
        return Yup.object().shape({
            name: Yup.string()
                .required('Debe indicar el nombre'),
            address: Yup.string()
                .required('Debe indicar una dirección'),
            postalCode: Yup.number()
                .required('Debe indicar el código postal'),
            locality: Yup.string()
                .required('Debe indicar la localidad'),
            region: Yup.string()
                .required('Debe indicar la provincia'),
            phone: Yup.string()
                .matches(/^$|^(\\+34|0034|34)?[\\s|\\-|\\.]?[6|7|8|9][\\s|\\-|\\.]?([0-9][\\s|\\-|\\.]?){8}$/, "El teléfono no es correcto"),
            nif: Yup.string()
                .nifValidation(Yup.string(), 'El NIF no es correcto'),
        });
    },
    mapPropsToValues: props => ({
        _id: props.address._id,
        status: props.address.status,
        companyName: props.address.companyName,
        phone: props.address.phone,
        address: props.address.address,
        postalCode: props.address.postalCode,
        locality: props.address.locality,
        region: props.address.region,
        additionalInformation: props.address.additionalInformation,
        name: props.address.name,
        nif: props.address.nif
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        props.onSubmit(values._id ? false : true, values);
        setSubmitting(false);
    },
    enableReinitialize: true
});

const EditAddressForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        onBackPress
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <Helmet>
                <title>{`Modificar dirección — ${theme.name}`}</title>
                <meta name="description" content={"Formulario para modificar datos de una dirección"}></meta>
            </Helmet>

            <input type='hidden' name='_id' id='_id' value={values._id} />
            <input type='hidden' name='status' id='status' value={values.status} />

            <div className="row">
                <div className="col-sm-12 col-md-7 col-lg-7">
                    <FormattedMessage id="address.user.name.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="address.user.name.label" />}
                                type="text"
                                name="name"
                                id="name"
                                placeholder={placeholder}
                                value={values.name}
                                messageError={errors.name}
                                invalid={errors.name && touched.name}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3">
                    <FormattedMessage id="edit.profile.nif.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="edit.profile.nif.label" />}
                                type='text'
                                name='nif'
                                id='nif'
                                placeholder={placeholder}
                                value={values.nif}
                                messageError={errors.nif}
                                invalid={errors.nif && touched.nif}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>

                <div className="col-sm-12 col-md-2 col-lg-2">
                    <FormattedMessage id="address.phone.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="address.phone.label" />}
                                type='text'
                                name='phone'
                                id='phone'
                                placeholder={placeholder}
                                value={values.phone}
                                messageError={errors.phone}
                                invalid={errors.phone && touched.phone}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-8">
                    <FormattedMessage id="address.companyName.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="address.companyName.label" />}
                                type="text"
                                name="companyName"
                                id="companyName"
                                placeholder={placeholder}
                                value={values.companyName}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="address.streetAddress.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="address.streetAddress.label" />}
                                type='textarea'
                                name='address'
                                id='address'
                                placeholder={placeholder}
                                value={values.address}
                                messageError={errors.address}
                                invalid={errors.address && touched.address}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-5 col-lg-5">
                    <FormattedMessage id="address.locality.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="address.locality.placeholder" />}
                                type="text"
                                name="locality"
                                id="locality"
                                placeholder={placeholder}
                                value={values.locality}
                                messageError={errors.locality}
                                invalid={errors.locality && touched.locality}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4">
                    <FormGroup error={errors.region && touched.region}>
                        <Label>{<FormattedMessage id="address.region.label" />}</Label>
                        <Input invalid={errors.region && touched.region} className={values.region && values.region !== "" ? "select-with-label" : "select-with-label empty-select"} type="select" name="region" id="region" value={values.region} onChange={handleChange("region")}>
                            <FormattedMessage id="address.region.placeholder">
                                {placeholder =>
                                    <option key="" value="">{placeholder}</option>
                                }
                            </FormattedMessage>
                            {props.provinces && props.provinces.map((item, key) => (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            ))}
                        </Input>
                        {errors.region && touched.region &&
                            <div className="input-error">{errors.region}</div>
                        }
                    </FormGroup>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3">
                    <FormGroup error={errors.postalCode && touched.postalCode}>
                        <Label for="postalCode"><FormattedMessage id="address.postalcode.label" /></Label>
                        <FormattedMessage id="address.postalcode.placeholder">
                            {placeholder =>
                                <NumberFormat autoComplete="new-password"
                                    id="postalCode"
                                    name="postalCode"
                                    placeholder={placeholder}
                                    value={values.postalCode}
                                    isNumericString={true}
                                    thousandSeparator={""}
                                    decimalSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                    onValueChange={vals => setFieldValue('postalCode', vals.formattedValue)}
                                    className={errors.postalCode && touched.postalCode ? "form-control custom-input-error" : "form-control"}
                                />
                            }
                        </FormattedMessage>
                        {errors.postalCode && touched.postalCode &&
                            <div className="input-error">{errors.postalCode}</div>
                        }
                    </FormGroup>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="address.additionalInformation.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="address.additionalInformation.label" />}
                                type='textarea'
                                name='additionalInformation'
                                id='additionalInformation'
                                placeholder={placeholder}
                                value={values.additionalInformation}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            {props.loading ?
                <div className="buttons">
                    <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                        <FormattedMessage id="button.save" />
                    </button>
                </div>
                :
                <div className="buttons">
                    <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="button.save" />
                    </button>
                    {" "}
                    <button className="btn" onClick={() => onBackPress()}>
                        <FormattedMessage id="button.back" />
                    </button>
                </div>
            }
        </Form>
    );
};

export default formikEnhancer(EditAddressForm);
