/**
 * Password Recovery Request Form
 */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

import { FormGroup, Form, Input } from 'reactstrap';
import Button from '@material-ui/core/Button';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .required('Debe indicar una dirección de correo electrónico')
            .email('Debe indicar una dirección de correo electrónico válida')
    }),
    mapPropsToValues: props => ({
        email: props.email
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        props.onSubmit(values);
        setSubmitting(false);
    },
    enableReinitialize: true
});

const PasswordRecoveryRequestForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup className="has-wrapper" error={errors.email && touched.email ? 'true' : 'false'}>
                <FormattedMessage id="passwordRecoveryRequest.placeholder.email">
                    {placeholder =>
                        <Input autoComplete="new-password"
                            type="mail"
                            value={values.email}
                            name="email"
                            id="email"
                            className="has-input input-lg"
                            placeholder={placeholder}
                            onChange={handleChange}
                            invalid={errors.email && touched.email}
                        />
                    }
                </FormattedMessage>
                <span className="has-icon"><i className="ti-email"></i></span>
                {errors.email && touched.email &&
                    <div className="input-error">{errors.email}</div>
                }
            </FormGroup>
            {props.emailDoesNotExist &&
                <div className="password-confirmation-error mb-20">
                    <FormattedMessage id="passwordRecoveryRequest.error.emailDoesNotExist" />
                </div>
            }
            <FormGroup className="mb-15">
                {props.loading ?
                    <div className="buttons mt-10">
                        <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                            <FormattedMessage id="passwordRecoveryRequest.button.sendEmail" />
                        </button>
                    </div>
                    :
                    <div className="buttons mt-10">
                        <button type="submit" className="btn btn-primary">
                            <FormattedMessage id="passwordRecoveryRequest.button.sendEmail" />
                        </button>
                    </div>
                }
            </FormGroup>
        </Form>
    );
};

export default formikEnhancer(PasswordRecoveryRequestForm);
