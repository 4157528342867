// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';

function SitePageCookiesPolicy() {
    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: <FormattedMessage id="cookies.title" />, url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{"Política de cookies"}</title>
                <meta name="description" content={"Política de cookies De acuerdo con lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico ..."}></meta>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title"><FormattedMessage id="cookies.title" /></h1>
                            {/* <div className="document__subtitle">This Agreement was last modified on 27 May 2018.</div> */}
                        </div>
                        <div className="document__content typography text-justify">
                            <p>De acuerdo con lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI), el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo del 27 de abril de 2016 (RGPD) y demás normativa aplicable en materia de cookies, se informa al usuario que la página web utiliza cookies tanto propias como de terceros con diversas finalidades que se detallan a continuación.</p>
                            <p>Se recomienda al usuario que lea atentamente esta Política de Cookies para informarse sobre el uso responsable que la página web hace de ellas y sobre las opciones que el usuario tiene para configurar su navegador y gestionarlas.</p>

                            <h2>1. ¿Qué es una Cookie?</h2>
                            <p>Una cookie es un pequeño archivo de texto que se almacena en el directorio del navegador y le permite recordar la información que se genera entre las páginas web y el navegador. Las cookies, tanto propias como de terceros, permiten recordar cada parte de los procesos online realizados a medida que se avanza a través de la página web y así poder mostrar el contenido adecuado en función de las opciones seleccionadas. También ayuda a entender el comportamiento del usuario dentro de la página web permitiendo mejorar su experiencia a través de esta.</p>
                            <p>Las cookies que las páginas web utilizan pueden almacenar algún dato personal del usuario, como datos de conexión, IP, geolocalización y navegación, así como información que pueda servir para identificarle si el propio usuario se ha registrado voluntariamente y cumplimentado alguno de los formularios de los que dispone la web.</p>

                            <h2>2. Consentimiento</h2>
                            <p>Se informa al usuario que la presente página web, en caso de que el usuario haya dado su consentimiento, podrá utilizar cookies que permitirán tener más información acerca de las preferencias del usuario, personalizando la web de conformidad con los intereses individuales. No se requerirá el consentimiento del usuario en los supuestos en los que las cookies sean necesarias para la navegación por la misma.</p>
                            <p>Cada vez que el usuario acceda a la presente página web le aparecerá un mensaje de aviso de cookies en el que podrá activar y desactivar aquellas que considere oportuno.</p>

                            <h2>3. Tipos de Cookies</h2>
                            <p>Las cookies se pueden clasificar en función de su procedencia, de su duración y de su finalidad.</p>
                            <ul>
                                <li>
                                    En función de su procedencia/titularidad:
                                <ul>
                                        <li>
                                            Cookies propias: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el titular de la página web y desde la que se presta el servicio solicitado por el usuario.
                                </li>
                                        <li>
                                            Cookies de tercero: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el titular de la página web desde la que se presta el servicio solicitado por el usuario, sino por otra entidad que trata los datos obtenidos través de las cookies. Asimismo, en el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio titular de la página web, pero la información que se recoja mediante éstas sea gestionada por un tercero, también serán consideradas como cookies de terceros.
                                </li>
                                    </ul>
                                </li>

                                <li>
                                    En función de su duración:
                                <ul>
                                        <li>
                                            Cookies de sesión: Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Estas cookies no quedan almacenadas en el ordenador del usuario cuando caduca la sesión o este cierra el navegador.
                                        </li>
                                        <li>
                                            Cookies permanentes: Son un tipo de cookies en el que los datos siguen almacenados en el ordenador del usuario y pueden ser accedidos y tratados cuando el usuario abandona la página web y cuando se vuelva a conectar a ella. Estas cookies pueden ser borradas en cualquier momento por el Usuario.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    En función de su finalidad:
                                <ul>
                                        <li>
                                            Cookies técnicas: Son aquéllas necesarias e imprescindibles para el buen funcionamiento de la web, que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.
                                        </li>
                                        <li>
                                            Cookies de análisis: Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de la página web al que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de la web, plataforma o aplicación y para la elaboración de perfiles de navegación de los usuarios de dicha web, plataforma o aplicación, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios.
                                        </li>
                                        <li>
                                            Cookies de personalización: Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.
                                        </li>
                                        <li>
                                            Cookies de Geolocalización: Se utilizan para saber en qué lugar se encuentra un usuario cuando solicita un servicio. Esta cookie es anónima y se usa por ejemplo para ofrecerle la información adecuada dependiendo del país en el que se encuentre.
                                        </li>
                                        <li>
                                            Cookies publicitarias: Son aquéllas que permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web.
                                        </li>
                                        <li>
                                            Cookies de publicidad comportamental: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que hay en la página web. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.
                                        </li>
                                        <li>
                                            Cookies de redes sociales externas: Se utilizan para que los usuarios puedan interactuar con el contenido de diferentes plataformas sociales (facebook, youtube, twitter, linkedIn, etc..) y que se generen únicamente para los usuarios de dichas redes sociales. Las condiciones de utilización de estas cookies y la información recopilada se regulan mediante la política de privacidad de la plataforma social correspondiente.
                                        </li>
                                    </ul>
                                </li>

                            </ul>

                            <h2>4. Cookies utilizadas en este sitio web</h2>
                            <p>Se informa al usuario sobre el tipo de cookies que están siendo utilizadas en esta página web, así como su finalidad, procedencia/titularidad y duración:</p>

                            <div className="card-table">
                                <div className="table-responsive-sm">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Nombre Cookie</th>
                                                <th>Finalidad</th>
                                                <th>Titularidad</th>
                                                <th>Descripción</th>
                                                <th>Duración</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1P_JAR</td>
                                                <td>Transfiere datos a Google</td>
                                                <td>gstatic.com</td>
                                                <td>Cookie que transfiere datos a Google para hacer la publicidad más atractiva.</td>
                                                <td>15 días.</td>
                                            </tr>
                                            <tr>
                                                <td>CookieConsent</td>
                                                <td>Aceptación de cookies del sitio web</td>
                                                <td>Propia</td>
                                                <td>Aceptación de cookies del sitio web</td>
                                                <td>Permanente</td>
                                            </tr>
                                            <tr>
                                                <td>CookieConsent-legacy</td>
                                                <td>Aceptación de cookies del sitio web</td>
                                                <td>Propia</td>
                                                <td>Aceptación de cookies del sitio web</td>
                                                <td>Permanente</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <h2>5. Deshabilitar o eliminar cookies (en los navegadores más usados)</h2>
                            <p>El uso de cookies tiene un efecto sobre la privacidad de los usuarios dado que, por regla general, permite relacionar su contenido con la dirección IP de conexión y con otros datos de carácter personal del usuario como los aportados voluntariamente.</p>
                            <p>Mediante la aceptación de la presente Política de Cookies, el usuario estará consintiendo la generación de cookies para las finalidades arriba mencionadas.</p>
                            <p>No obstante, en caso de que el usuario desee con posterioridad eliminar las cookies que se hayan almacenado en su ordenador y que necesariamente precisen de su consentimiento, podrá hacerlo usando las herramientas que le proporcione su navegador a dichos efectos. En este sentido, dependiendo de su navegador, en los siguientes enlaces se detalla el procedimiento para desactivar las cookies:</p>
                            <ul>
                                <li>
                                    <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" rel="noopener noreferrer" target="_blank">Safari</a>
                                </li>

                                <li>
                                    <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=es" rel="noopener noreferrer" target="_blank">Chrome</a>

                                </li>

                                <li>
                                    Opera
                                </li>

                                <li>
                                    <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" rel="noopener noreferrer" target="_blank">FireFox</a>

                                </li>

                                <li>
                                    <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" rel="noopener noreferrer" target="_blank"> Internet Explorer
                                    </a>
                                </li>
                            </ul>

                            <h2>6. Responsabilidades</h2>
                            <ul>
                                <li>
                                    Ni la presente página web ni sus representantes legales, se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad / políticas de cookies que puedan tener los terceros mencionados en la presente política de cookies.
                                </li>

                                <li>
                                    Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni la presente página web ni sus representantes legales, pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.
                                </li>

                                <li>
                                    En el caso de bloquear o eliminar las cookies, la página web no podrá mantener las preferencias del usuario y es posible que algunas características de la página web no estén operativas, o haya que solicitarle de nuevo al usuario autorización para su uso.
                                </li>
                            </ul>

                            <h2>7. Actualización de la Política de Cookies y contacto</h2>
                            <p>La presente política de cookies puede ir variando en función de las cookies que se utilicen o en función de cambios en la normativa aplicable en materia de cookies.</p>
                            <p>Se recomienda al usuario revisar la política de cookies cada vez que acceda a la página web con el objetivo de estar adecuadamente informado sobre el uso y finalidades de las cookies, así como para tener conocimiento de cualquier cambio en el tipo de datos que se recogen.</p>
                            <p>En el caso de que el usuario tenga algún problema o duda relacionada con el uso de las cookies en este sitio web, puede contactar a través de la siguiente dirección de correo electrónico indicada en la página web.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageCookiesPolicy;
