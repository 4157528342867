// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';

// blocks
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
import categories from '../../data/shopWidgetCategories';
import products from '../../data/shopProducts';
import theme from '../../data/theme';
import { Redirect } from 'react-router-dom';

// application
import { getProduct, getRelatedProducts, getProductReviews } from '../../store/products';
import { cartAddItemSuccess, addingToCart } from '../../store/cart';
import {quickviewOpenSuccess} from '../../store/quickview';
import { wishlistAddItem } from '../../store/wishlist';

class ShopPageProduct extends Component {

    constructor(props) {
        super(props);
    };

    componentDidMount() {
        const { match } = this.props;
        this.props.getProduct(match.params.productId);
        this.props.addingToCart(false);
        this.props.getProductReviews(match.params.productId);
        //this.props.getRelatedProducts(match.params.productId);
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (prevProps.match.params.productId !== match.params.productId.toString()) {
            this.props.getProduct(match.params.productId.toString());
        }
    }

    onAddCartClick = (product) => {
        this.props.addingToCart(true);
        this.props.cartAddItemSuccess(product);
    };

    viewClick = (product) => {
        this.props.quickviewOpenSuccess(product);
    };

    onAddWishListClick = (product) => {
        this.props.wishlistAddItem(product);
    };

    render() {

        const { layout, sidebarPosition, selectedProduct, adding, loading, error, relatedProducts } = this.props;
        if (error && error.code === "404"){
            return <Redirect to="/pagina-no-encontrada" />;
        }
        if (!loading && selectedProduct) {

            const breadcrumb = [
                { title: 'Inicio', url: '/' },
                { title: selectedProduct && selectedProduct.group ?  selectedProduct.group.name: '' },
                { title: selectedProduct && selectedProduct.family ?  selectedProduct.family.name: '' },
                { title: selectedProduct && selectedProduct.familyGroup ?  selectedProduct.familyGroup.name: '' },
                { title: selectedProduct ? selectedProduct.name : '', url: '' },

            ];

            let content;

            let metaDescription = ""
            if (selectedProduct) {
                metaDescription += selectedProduct.name;
                if (selectedProduct.group) {
                    metaDescription += " - "+selectedProduct.group.name
                }
                if (selectedProduct.family) {
                    metaDescription += " - "+selectedProduct.family.name
                }
                if (selectedProduct.familyGroup) {
                    metaDescription += " - "+selectedProduct.familyGroup.name
                }
                if(metaDescription.length > 160) {
                metaDescription = metaDescription.substring(0,160)}
            }

            content = (
                <React.Fragment>
                    <div className="block">
                        <div className="container">
                            <Product product={selectedProduct} layout={layout} onAddCartClick={this.onAddCartClick} adding={adding} onAddWishListClick={this.onAddWishListClick} />
                            <ProductTabs product={selectedProduct} />
                        </div>
                    </div>

                    <BlockProductsCarousel title="Productos recomendados" layout="grid-5" showRelatedProducts={true}
                     relatedProducts={relatedProducts}  onAddCartClick={this.onAddCartClick} adding={adding} onAddWishListClick={this.onAddWishListClick} viewClick={this.viewClick} />
                </React.Fragment>
            );


            return (
                <React.Fragment>
                    <Helmet>
                        <title>{`${selectedProduct.name} — ${theme.name}`}</title>
                        <meta name="description" content={metaDescription}></meta>
                    </Helmet>

                    <PageHeader breadcrumb={breadcrumb} />

                    {content}
                </React.Fragment>
            );
        } else {
            return (
                <RctSectionLoader />
            );
        }

    }
};

ShopPageProduct.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

const mapStateToProps = ({ productsApp, cart, settings }) => {
    const { selectedProduct, relatedProducts, error } = productsApp;
    const { adding } = cart;

    return {
        selectedProduct,
        adding,
        relatedProducts,
        settings,
        error
    };
};

const mapDispatchToProps = {
    getProduct,
    getRelatedProducts,
    cartAddItemSuccess,
    addingToCart,
    quickviewOpenSuccess,
    wishlistAddItem,
    getProductReviews
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopPageProduct);