/**
* Products Sagas
*/
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// api
import * as api from '../../api';
import * as selectors from './selectors';
import {
    GET_PRODUCTS,
    GET_FEATURED_PRODUCTS,
    GET_GROUPS,
    GET_NEW_RELEASE_PRODUCTS,
    GET_BEST_SELLER_PRODUCTS,
    GET_PRODUCT,
    GET_RELATED_PRODUCTS,
    GET_PRODUCTS_AFTER_SCROLLING,
    GET_PRODUCTS_BY_CRITERIA,
    GET_GROUPS_FILTER,
    GET_FAMILY_GROUPS_FILTER,
    GET_FAMILIES_FILTER,
    GET_MANUFACTURERS_FILTER,
    ADD_PRODUCT_REVIEW,
    GET_PRODUCT_REVIEWS,
    GET_FAMILY_GROUPS
} from './productsActionTypes';

import {
    getFeaturedProductsSuccess,
    getFeaturedProductsFailure,
    getGroupsSuccess,
    getGroupsFailure,
    getNewReleaseProductsSuccess,
    getNewReleaseProductsFailure,
    getBestSellerProductsSuccess,
    getBestSellerProductsFailure,
    getProductSuccess,
    getProductFailure,
    getRelatedProductsSuccess,
    getRelatedProductsFailure,
    getProductsSuccess,
    getProductsFailure,
    getProductsAfterScrollingSuccess,
    getProductsAfterScrollingFailure,
    getProductsByCriteriaSuccess,
    getProductsByCriteriaFailure,
    getGroupsFilterSuccess,
    getGroupsFilterFailure,
    getFamilyGroupsFilterSuccess,
    getFamilyGroupsFilterFailure,
    getFamiliesFilterSuccess,
    getFamiliesFilterFailure,
    getManufacturersFilterSuccess,
    getManufacturersFilterFailure,
    addProductReviewSuccess,
    addProductReviewFailure,
    getProductReviews,
    getProductReviewsSuccess,
    getProductReviewsFailure,
    getFamilyGroupsSuccess,
    getFamilyGroupsFailure
} from './productsActions';

function* getProductsFromServer({ payload }) {

    try {
        const pageSize = yield select(selectors.pageSize);
        const pageIndex = yield select(selectors.productsPageIndex);
        const selectGroups = yield select(selectors.productsSelectedGroups);
        const selectFamilyGroups = yield select(selectors.productsSelectedFamilyGroups);
        const selectFamilies = yield select(selectors.productsSelectedFamilies);
        const selectManufacturers = yield select(selectors.productsSelectedManufacturers);
        const productsSortField = yield select(selectors.productsSortField);
        const isOutletScreen = yield select(selectors.isOutletScreen);

        const response = yield call(api.products.getProductsRequest,
            pageIndex - 1,
            pageSize,
            payload.familyGroup,
            selectGroups,
            selectFamilyGroups,
            selectFamilies,
            selectManufacturers,
            productsSortField,
            isOutletScreen,
            payload.group,
            );
        yield put(getProductsSuccess(response));
    } catch (error) {
        yield put(getProductsFailure(error));
    }
}

/**
 * Get products After Scrolling From Server
 */
function* getProductsAfterScrollingFromServer({ payload }) {
    try {
        const pageSize = yield select(selectors.pageSize);
        const pageIndex = yield select(selectors.productsPageIndex);
        const selectGroups = yield select(selectors.productsSelectedGroups);

        const response = yield call(api.products.getProductsRequest, pageIndex - 1, pageSize, payload, selectGroups);
        yield put(getProductsAfterScrollingSuccess(response));
    } catch (error) {
        yield put(getProductsAfterScrollingFailure(error));
    }
}

function* getFeaturedProductsFromServer(payload) {
    try {
        const showFeatured = true;
        const response = yield call(api.products.getFeaturedProductsRequest, payload.payload, showFeatured);
        yield put(getFeaturedProductsSuccess(response));
    } catch (error) {
        yield put(getFeaturedProductsFailure(error));
    }
}

function* getGroupsFromServer() {
    try {
        const response = yield call(api.groups.getGroupsRequest);
        yield put(getGroupsSuccess(response));
    } catch (error) {
        yield put(getGroupsFailure(error));
    }
}

function* getNewReleaseProductsFromServer(payload) {
    try {
        const showNewReleases = true;
        const response = yield call(api.products.getNewReleaseProductsRequest, payload.payload, showNewReleases);
        yield put(getNewReleaseProductsSuccess(response));
    } catch (error) {
        yield put(getNewReleaseProductsFailure(error));
    }
};

function* getBestSellerProductsFromServer(payload) {
    try {
        const showBestSellers = true;
        const response = yield call(api.products.getBestSellerProductsRequest, payload.payload, showBestSellers);
        yield put(getBestSellerProductsSuccess(response));
    } catch (error) {
        yield put(getBestSellerProductsFailure(error));
    }
};

function* getProductFromServer({ payload }) {
    try {
        const response = yield call(api.products.getProductRequest, payload);

        try {
            const familyGroup = response.familyGroup && response.familyGroup._id ? response.familyGroup._id : "";
            let group = null
            if (!familyGroup) {
            group = response.group && response.group._id ? response.group._id : "";
            }
            const responseRelatedProducts = yield call(api.products.getRelatedProductsRequest, familyGroup, group);
            yield put(getRelatedProductsSuccess(responseRelatedProducts));
        } catch (error) {
            console.log(JSON.stringify(error));
            yield put(getRelatedProductsFailure(error));
        }
        if (response.showOnVercan) {
            yield put(getProductSuccess(response));
        }
        else {
            yield put(getProductFailure({code: "404", message: "No encontrado"}));
        }
       
    } catch (error) {
        yield put(getProductFailure(error));
    }
}

function* getRelatedProductsFromServer(payload) {
    try {
        const response = yield call(api.products.getRelatedProductsRequest, payload.payload);
        yield put(getRelatedProductsSuccess(response));
    } catch (error) {
        yield put(getRelatedProductsFailure(error));
    }
};

function* getProductsByCriteriaFromServer(payload) {
    try {
        const criteria = yield select(selectors.productsCriteriaSearchBar);

        const response = yield call(api.products.getProductsByCriteriaRequest, criteria);
        yield put(getProductsByCriteriaSuccess(response));
    } catch (error) {
        yield put(getProductsByCriteriaFailure(error));
    }
};

function* getGroupsFilterFromServer() {
    try {
        const isOutletScreen = yield select(selectors.isOutletScreen);

        const response = yield call(api.products.getGroupsFilterRequest, isOutletScreen);
        yield put(getGroupsFilterSuccess(response));
    } catch (error) {
        yield put(getGroupsFilterFailure(error));
    }
}

function* getFamilyGroupsFilterFromServer() {
    try {
        const isOutletScreen = yield select(selectors.isOutletScreen);

        const response = yield call(api.products.getFamilyGroupsFilterRequest, isOutletScreen);
        yield put(getFamilyGroupsFilterSuccess(response));
    } catch (error) {
        yield put(getFamilyGroupsFilterFailure(error));
    }
}

function* getFamiliesFilterFromServer() {
    try {
        const isOutletScreen = yield select(selectors.isOutletScreen);

        const response = yield call(api.products.getFamiliesFilterRequest, isOutletScreen);
        yield put(getFamiliesFilterSuccess(response));
    } catch (error) {
        yield put(getFamiliesFilterFailure(error));
    }
}

function* getManufacturersFilterFromServer() {
    try {
        const response = yield call(api.products.getManufacturersFilterRequest);
        yield put(getManufacturersFilterSuccess(response));
    } catch (error) {
        yield put(getManufacturersFilterFailure(error));
    }
}

function* addProductReviewFromServer({ payload }) {
    try {
        const response = yield call(api.products.addProductReviewRequest, payload);
        yield put(addProductReviewSuccess(response));
        yield put(getProductReviews(response.product));
    } catch (error) {
        console.log(error);
        yield put(addProductReviewFailure(error));
    }
}

function* productReviewsFromServer({ payload }) {
    try {
        const response = yield call(api.products.getProductReviewsRequest, payload);
        yield put(getProductReviewsSuccess(response));
    } catch (error) {
        yield put(getProductReviewsFailure(error));
    }
}

function* getFamilyGroupsFromServer() {
    try {
        const response = yield call(api.products.getFamilyGroupsFilterRequest);
        yield put(getFamilyGroupsSuccess(response));
    } catch (error) {
        yield put(getFamilyGroupsFailure(error));
    }
}

export function* fetchProducts() {
    yield takeEvery(GET_PRODUCTS, getProductsFromServer);
}

export function* fetchFeaturedProducts() {
    yield takeEvery(GET_FEATURED_PRODUCTS, getFeaturedProductsFromServer);
}

export function* fetchGroups() {
    yield takeEvery(GET_GROUPS, getGroupsFromServer);
}

export function* fetchNewReleasesProducts() {
    yield takeEvery(GET_NEW_RELEASE_PRODUCTS, getNewReleaseProductsFromServer);
};

export function* fetchBestSellerProducts() {
    yield takeEvery(GET_BEST_SELLER_PRODUCTS, getBestSellerProductsFromServer);
};

export function* fetchGetProduct() {
    yield takeEvery(GET_PRODUCT, getProductFromServer);
};

export function* fetchRelatedProducts() {
    yield takeEvery(GET_RELATED_PRODUCTS, getRelatedProductsFromServer);
};

/**
 * Get products After Scrolling
 */
export function* fetchProductsAfterScrolling() {
    yield takeEvery(GET_PRODUCTS_AFTER_SCROLLING, getProductsAfterScrollingFromServer);
}

export function* fetchGetProductsByCriteria() {
    yield takeEvery(GET_PRODUCTS_BY_CRITERIA, getProductsByCriteriaFromServer);
};

export function* fetchGroupsFilter() {
    yield takeEvery(GET_GROUPS_FILTER, getGroupsFilterFromServer);
};

export function* fetchFamilyGroupsFilter() {
    yield takeEvery(GET_FAMILY_GROUPS_FILTER, getFamilyGroupsFilterFromServer);
};

export function* fetchFamiliesFilter() {
    yield takeEvery(GET_FAMILIES_FILTER, getFamiliesFilterFromServer);
};

export function* fetchManufacturersFilter() {
    yield takeEvery(GET_MANUFACTURERS_FILTER, getManufacturersFilterFromServer);
};

export function* addProductReview() {
    yield takeEvery(ADD_PRODUCT_REVIEW, addProductReviewFromServer);
};

export function* fetchProductReviews() {
    yield takeEvery(GET_PRODUCT_REVIEWS, productReviewsFromServer);
};

export function* fetchFamilyGroup() {
    yield takeEvery(GET_FAMILY_GROUPS, getFamilyGroupsFromServer);
};

export default function* rootSaga() {
    yield all([
        fork(fetchFeaturedProducts),
        fork(fetchGroups),
        fork(fetchNewReleasesProducts),
        fork(fetchBestSellerProducts),
        fork(fetchGetProduct),
        fork(fetchRelatedProducts),
        fork(fetchProducts),
        fork(fetchProductsAfterScrolling),
        fork(fetchGetProductsByCriteria),
        fork(fetchGroupsFilter),
        fork(fetchFamilyGroupsFilter),
        fork(fetchFamiliesFilter),
        fork(fetchManufacturersFilter),
        fork(addProductReview),
        fork(fetchProductReviews),
        fork(fetchFamilyGroup)
    ]);
}