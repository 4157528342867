// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// application
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';

import { createOrder, setPaymentData } from '../../store/cart';
import SitePageAccessDenied from '../site/SitePageAccessDenied';
import { IvaValue } from '../../util/Enums';

class ShopPageCheckoutPayment extends Component {
    payments = payments;

    constructor(props) {
        super(props);
        this.state = {
            areWarningPrivacyAccepted: false,
            areTermsAccepted: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChangeWarningPrivacy = (e) => {
        const { checked } = e.target
        this.setState({
            areWarningPrivacyAccepted: checked
        })
    }

    handleChangeTerms = (e) => {
        const { checked } = e.target
        this.setState({
            areTermsAccepted: checked
        })
    }

    onSubmit() {
        const { me, shippingAddress, cart } = this.props;
        let products = [];

        cart.items.map((item) => (
            products.push({
                product: item.product._id,
                name: item.product.name,
                quantity: item.quantity,
                price: item.total,
            })
        ));

        const formData = {
            //formData,
            user: me && me._id ? me._id : "",
            shippingAddress: shippingAddress,
            billingAddress: shippingAddress.billingAddress,
            products: products,
            subtotal: cart.subtotal,
            total: cart.finalTotal,
            tax: cart.finalTax,
            shipping: cart.shipping,
            iva: IvaValue.IVA
        }
        this.props.createOrder(formData, this.props.history);
    }

    regionName(regionCode) {
        const { regions } = this.props;
        if (regions && regions.length > 0) {
            //Find index of specific object using findIndex method.    
            const objIndex = regions.findIndex((obj => obj.code === regionCode));
            //Update object's name property.
            return regions[objIndex].name;
        } else {
            return "";

        }
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th><FormattedMessage id="amount.subtotal"/></th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    <tr key="shipping">
                        <th><FormattedMessage id="amount.shipping" /></th>
                        <td>
                            <Currency value={cart.shipping} />
                           
                        </td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} × ${item.quantity}`}</td>
                <td><Currency value={item.total} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th><FormattedMessage id="checkout.products.label" /></th>
                        <th><FormattedMessage id="amount.total"/></th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot>
                    <tr>
                        <FormattedMessage id="cart.checkout.tax.text" />
                        <Currency value={cart.finalTax} />

                    </tr>
                </tfoot>
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th><FormattedMessage id="amount.total"/></th>
                        <td><Currency value={cart.finalTotal} /></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderShipping() {
        const { shippingAddress } = this.props;

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th><FormattedMessage id="checkout.streetAddress.label"/></th>
                        <th></th>

                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    <tr key={shippingAddress._id}>
                        <td>{`${shippingAddress.address} - ${shippingAddress.locality} - ${this.regionName(shippingAddress.region)} - ${shippingAddress.postalCode}`}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        );
    }

    renderCheckAndSubmit() {
        const { areWarningPrivacyAccepted, areTermsAccepted } = this.state;
        const { cart } = this.props;
        return (
            <div>
                <div className="App-payment">
                    <h3><FormattedMessage id="checkout.payment.terms.title"/></h3>

                    <div className="form-check">
                        <input class="form-check-input" id="waringPolicyCheck" type="checkbox" defaultChecked={false} onChange={this.handleChangeWarningPrivacy} />
                        <label className="form-check-label" htmlFor="waringPolicyCheck"> <FormattedHTMLMessage id="checkout.payment.terms.waringPolicy"/> </label>
                    </div>


                    <div className="form-check">
                        <input class="form-check-input" id="termsCheck" type="checkbox" defaultChecked={false} onChange={this.handleChangeTerms} />
                        <label className="form-check-label" htmlFor="termsCheck"> <FormattedHTMLMessage id="checkout.payment.terms"/> </label>
                    </div>

                    <div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="form-actions mt-10">
                                <button disabled={areWarningPrivacyAccepted && areTermsAccepted && cart.shipping ? false : true} onClick={this.onSubmit} className="btn btn-sm btn-primary"><FormattedMessage id="checkout.button.goPayment" /></button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    renderAcceptedCards() {
        return (
            <div key="Payment">
                <div className="App-payment">
                    <span><FormattedMessage id="checkout.payment.accepted.title"/></span>
                    <div className="row mt-2 mb-2">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <span><img src={require('../../assets/img/payments/visa.png')} alt="visa" /></span>
                            <span className="ml-1" ><img src={require('../../assets/img/payments/mastercard.png')} alt="mastercard" /></span>
                            <span className="ml-1" ><img src={require('../../assets/img/payments/maestro.png')} alt="mestro" /></span>
                            <span className="ml-1" ><img src={require('../../assets/img/payments/jcb.png')} alt="amex" /></span>
                        </div>
                    </div>
                </div>
                <div className="card-divider mb-4" />
            </div>
        );
    }

    render() {
        const { cart, me } = this.props;

        if (!me) {
            return (
                <SitePageAccessDenied />
            );
        }

        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }

        const breadcrumb = [
            { title: 'Inicio', url: '/' },
            { title: <FormattedMessage id="checkout.breadcrumb.cart" />, url: '/carrito' },
            { title: <FormattedMessage id="checkout.breadcrumb.shippingAddress" />, url: '/tramitar-comprar/datos-envio' },
            { title: <FormattedMessage id="checkout.breadcrumb.payment" />, url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Tramitar compra - Términos y condiciones — ${theme.name}`}</title>
                    <meta name="description" content={"Pantalla de aceptación de términos y condiciones antes de proceder con el pago"}></meta>
                </Helmet>

                <PageHeader header={<FormattedMessage id="checkout.payment.tittle" />} breadcrumb={breadcrumb} />

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-lg-0">
                                    <div className="card-body">
                                        {this.renderAcceptedCards()}
                                        {this.renderCheckAndSubmit()}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title"><FormattedMessage id="checkout.payment.addresss" /></h3>
                                        {this.renderShipping()}
                                        <h3 className="card-title"><FormattedMessage id="checkout.yourorder.label" /></h3>
                                        {this.renderCart()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    me: state.authApp.me,
    cart: state.cart,
    shippingAddress: state.cart.shippingAddress,
    regions: state.userApp.regions,
    creatingOrder: state.cart.creatingOrder,
});

const mapDispatchToProps = { createOrder, setPaymentData };

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckoutPayment);
