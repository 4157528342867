// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

// application
import Product from './Product';
import { Cross20Svg } from '../../svg';
import { quickviewClose } from '../../store/quickview';
import { cartAddItemSuccess } from '../../store/cart';
import { wishlistAddItem } from '../../store/wishlist';


class Quickview extends Component {

    constructor(props) {
        super(props);
    }

    onAddCartClick = (product) => {
        this.props.cartAddItemSuccess(product);
    };

    onAddWishListClick = (product) => {
        this.props.wishlistAddItem(product);
    };

    render() {
        const { product, open, quickviewClose } = this.props;

        let productView;
    
        if (product !== null) {
            productView = <Product product={product} onAddCartClick={this.onAddCartClick} onAddWishListClick={this.onAddWishListClick} layout="quickview" />;
        }
    
        return (
            <Modal isOpen={open} toggle={quickviewClose} centered size="xl">
                <div className="quickview">
                    <button className="quickview__close" type="button" onClick={quickviewClose}>
                        <Cross20Svg />
                    </button>
    
                    {productView}
                </div>
            </Modal>
        );
    }
 
}

const mapStateToProps = (state) => ({
    open: state.quickview.open,
    product: state.quickview.product,
});

const mapDispatchToProps = {
    quickviewClose,
    cartAddItemSuccess,
    wishlistAddItem
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Quickview);
