// react
import React from 'react';
import { FormattedMessage } from 'react-intl';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';

// data stubs
import theme from '../../data/theme';



export default function Footer() {
    const informationLinks = [
        { title: 'footer.policy', url: '/politica-de-privacidad' },
        { title: 'footer.legalWarning', url: '/aviso-legal' },
        { title: 'footer.terms', url: '/terminos-y-condiciones' },
        { title: 'footer.cookies', url: '/politica-de-cookies' },
    ];

    const accountLinks = [
        { title: 'home.account.history', url: '/account/orders' },
        { title: 'home.shop.wishlist', url: '/lista-de-deseos' },
        { title: 'footer.gifts', url: '' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="footer.information" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="topbar.myAccount" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        {/*Powered by
                        {' '}
                        <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">React</a>
                        {' '}*/}
                        {theme.name + " v"+process.env.REACT_APP_VERSION}
                        {' - '}
                        <FormattedMessage id="app.designed" />
                        {' '}
                        <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
                            {theme.author.name}
                        </a>
                    </div>
                    {/*<div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>*/}
                    <div className="site-footer__payments">
                        <span><img src={require('../../assets/img/payments/visa.png')} alt="visa" /></span>
                        <span className="ml-1" ><img src={require('../../assets/img/payments/mastercard.png')} alt="mastercard" /></span>
                        <span className="ml-1" ><img src={require('../../assets/img/payments/maestro.png')} alt="mestro" /></span>
                        <span className="ml-1" ><img src={require('../../assets/img/payments/jcb.png')} alt="amex" /></span>
                    </div>
                </div>
            </div>
        </div>
    );
}
