import { toast } from 'react-toastify';
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    ADDING_TO_CART,
    CLEAN_CART,
    BUILD_SHIPPING_ADDRESS,
    SET_SHIPPING_ADDRESS,
    CREATE_ORDER,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAILURE,
    SET_PAYMENT_DATA,
    SET_REGION_ZONE,
    CALCULATE_TOTAL_AMOUNT,
    CALCULATE_TOTAL_AMOUNT_SUCCESS,
    CALCULATE_TOTAL_AMOUNT_FAILURE,
    CREATE_ORDER_IN_KARVE,
    CREATE_ORDER_IN_KARVE_SUCCESS,
    CREATE_ORDER_IN_KARVE_FAILURE
} from './cartActionTypes';


export const addingToCart = (adding) => ({
    type: ADDING_TO_CART,
    payload: adding
});


export const cartAddItemSuccess = (product) => ({
    type: CART_ADD_ITEM,
    payload: product,
});

export const cartRemoveItem = (itemId) => ({
    type: CART_REMOVE_ITEM,
    payload: itemId,
});

export const cleanCart = () => ({
    type: CLEAN_CART
});

export const cartUpdateQuantities = (quantities) => ({
    type: CART_UPDATE_QUANTITIES,
    payload: quantities,
});

export const buildShippingAddress = (userMainAddress) => ({
    type: BUILD_SHIPPING_ADDRESS,
    payload: userMainAddress,
});

export const setShippingAddress = (data) => ({
    type: SET_SHIPPING_ADDRESS,
    payload: data,
});


export const createOrder = (data, history) => ({
    type: CREATE_ORDER,
    payload: {data, history}
});

export const createOrderSuccess = (data) => ({
    type: CREATE_ORDER_SUCCESS,
    payload: data
});

export const createOrderFailure = (error) => ({
    type: CREATE_ORDER_FAILURE,
    payload: error
});

export const setPaymentData = (data) => ({
    type: SET_PAYMENT_DATA,
    payload: data
});

export const setRegionZone = (data) => ({
    type: SET_REGION_ZONE,
    payload: data,
});

export const totalAmountWithShipping = (history) => ({
    type: CALCULATE_TOTAL_AMOUNT,
    payload: history,
});

export const totalAmountWithShippingSuccess = (data) => ({
    type: CALCULATE_TOTAL_AMOUNT_SUCCESS,
    payload: data
});

export const totalAmountWithShippingFailure = (error) => ({
    type: CALCULATE_TOTAL_AMOUNT_FAILURE,
    payload: error
});


export function cartAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity));
                resolve();
            }, 2000);
        })
    );
}

export const createOrderInKarve = (order) => ({
    type: CREATE_ORDER_IN_KARVE,
    payload: order,
});

export const createOrderInKarveSuccess = () => ({
    type: CREATE_ORDER_IN_KARVE_SUCCESS
});

export const createOrderInKarveFailure = (error) => ({
    type: CREATE_ORDER_IN_KARVE_FAILURE,
    payload: error
});