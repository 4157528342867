/**
 * App Config File
 */
const AppConfig = {
    pageSize: 10,
    pageSizeInfiniteScroll: 4,
    smallPageSizeInfiniteScroll: 6,
    numberOfItemsVerticalFilter: 5,
    cardsPageSizeInfiniteScroll: 6,
    ordersPageSize: 10,

    orderNumberMinLength: 8,
    paymentTransactionType: '0',
    paymentCurrency: '978',
    paymentTerminal: '1',
    paymentFUC: '032873515',
    url_TEST: "https://sis-t.redsys.es:25443/sis/realizarPago",
    url_REAL: "https://sis.redsys.es/sis/realizarPago",
    paymentUrlOK: 'https://tienda.vercanminimizados.com/compra-completada',
    paymentUrlKO: 'https://tienda.vercanminimizados.com/compra-fallida',
    userPaymentMerchantUrl: 'https://admin.vercanminimizados.com/api/v1/orders/paid/',
    /*paymentUrlOK: 'http://localhost:3000compra-completada',
    paymentUrlKO: 'http://localhost:3000/compra-fallida',
    userPaymentMerchantUrl: 'http://80.28.122.46:4444/api/v1/orders/paid/',*/
    paymentKeyTest: 'sq7HjrUOBfKmC576ILgskD5srU870gJ7',
    paymentKey_REAL: 'th5EU6VLDFs4DaWP/wtRqG7ORGLUKjPX',
    paymentSignatureVersion: 'HMAC_SHA256_V1',

 }
 
 export default AppConfig;