export default [
    {
        key: 'bank',
        title: 'ENTREGA CONTRA REEMBOLSO',
        description: 'Paga al contado el importe al recibir el pedido',
    },
    {
        key: 'cash',
        title: 'Tarjeta Debito/Credito',
        description: ' ',
    },
    {
        key: 'paypal',
        title: 'PayPal',
        description: ' ',
    },
];
