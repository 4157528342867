import client from './client';

export const getGroupsRequest = async () => {
    let queryUrl = `/groups/shop/groups`;
    return await client.get(queryUrl)
        .then(response => response.data);       
};

export const getFamiliesRequest = async () => {
    let queryUrl = `/family-groups/shop/families`;
    return await client.get(queryUrl)
        .then(response => response.data);       
};