// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import PropTypes from 'prop-types';

// application
import BlockHeader from '../shared/BlockHeader';
import ProductCard from '../shared/ProductCard';

// application
import { getBestSellerProducts } from '../../store/products';
import {quickviewOpenSuccess} from '../../store/quickview';
import { cartAddItemSuccess } from '../../store/cart';
import { wishlistAddItem } from '../../store/wishlist';

class BlockBestSellerProducts extends Component {
    timeout;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getBestSellerProducts();
    }

    viewClick = (product) => {
        this.props.quickviewOpenSuccess(product);
    };

    onAddCartClick = (product) => {
        this.props.cartAddItemSuccess(product);
    };

    onAddWishListClick = (product) => {
        this.props.wishlistAddItem(product);
    };

    render() {
        const {
            title,
            layout,
            bestSellerProducts,
        } = this.props;
    
        let large;
        let smalls;
    
        if (bestSellerProducts.length > 0) {
            large = (
                <div className="block-products__featured">
                    <div className="block-products__featured-item">
                        <ProductCard product={bestSellerProducts[0]} viewClick={this.viewClick} onAddCartClick={this.onAddCartClick} onAddWishListClick={this.onAddWishListClick}/>
                    </div>
                </div>
            );
        }
    
        if (bestSellerProducts.length > 0) {
            const productsList = bestSellerProducts.map((product, index) => (
                <div key={index} className="block-products__list-item">
                    <ProductCard product={product} viewClick={this.viewClick} onAddCartClick={this.onAddCartClick} onAddWishListClick={this.onAddWishListClick}/>
                </div>
            ));
    
            smalls = (
                <div className="block-products__list">
                    {productsList}
                </div>
            );
        }
    
        return (
            <div className={`block block-products block-products--layout--${layout}`}>
                <div className="container">
                    <BlockHeader title={title} />
    
                    <div className="block-products__body">
                        {layout === 'large-first' && large}
                        {smalls}
                        {layout === 'large-last' && large}
                    </div>
                </div>
            </div>
        );
    }
}


BlockBestSellerProducts.propTypes = {
    title: PropTypes.string.isRequired,
    bestSellerProducts: PropTypes.array,
    layout: PropTypes.oneOf(['large-first', 'large-last']),
};

BlockBestSellerProducts.defaultProps = {
    bestSellerProducts: [],
    layout: 'large-first',
};

const mapStateToProps = ({ productsApp, settings }) => {
    const { bestSellerProducts, groups } = productsApp;
    return {
        bestSellerProducts,
        groups,
        settings
    };
};

const mapDispatchToProps = {
    getBestSellerProducts,
    quickviewOpenSuccess,
    cartAddItemSuccess,
    wishlistAddItem
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockBestSellerProducts);