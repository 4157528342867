/**
 * Register Form
 */
import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';
import FormInput from '../Form/FormInput';
import { FormattedMessage } from 'react-intl';


const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .required('Debe indicar la dirección de correo')
            .email('La dirección de correo electrónico no es correcta'),
        password: Yup.string()
            .required('Debe indicar una contraseña')
    }),
    mapPropsToValues: props => ({
        email: props.signinData.email,
        password: props.signinData.password,
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        props.onSubmit(true, values);
        setSubmitting(false);
    },
    enableReinitialize: true
});


const IndicatorLoginForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        isMobile
    } = props;

    return (
        <Form className="account-menu__form" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="signin.placeholder.email">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="signin.label.email" />}
                                type="text"
                                name="email"
                                id= {isMobile ? "indicatorLoginEmailMobile": "indicatorLoginEmail" }
                                placeholder={placeholder}
                                value={values.email}
                                messageError={errors.email}
                                invalid={errors.email && touched.email}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <FormattedMessage id="signin.placeholder.password">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="signin.label.password" />}
                                type='password'
                                name='password'
                                id= {isMobile ? "indicatorLoginPasswordMobile": "indicatorLoginPassword" }
                                placeholder={placeholder}
                                value={values.password}
                                messageError={errors.password}
                                invalid={errors.password && touched.password}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>


            <div className="mb-3">
                <Link to="/solicitud-restablecimiento-contrasena"><FormattedMessage id="signin.label.password.forgotten" /></Link>
            </div>

            {props.loading ?
                <div className="buttons">
                    <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                        <FormattedMessage id="button.signin.label" />
                    </button>
                </div>
                :
                <div className="buttons">
                    <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="button.signin.label" />
                    </button>
                </div>
            }
            <div className="account-menu__form-link mt-3">
                        <Link to="/acceso-registro"><FormattedMessage id="home.account.register" /></Link>
                    </div>
        </Form>
    );
};

export default formikEnhancer(IndicatorLoginForm);
