import {
    GET_FEATURED_PRODUCTS,
    GET_FEATURED_PRODUCTS_SUCCESS,
    GET_FEATURED_PRODUCTS_FAILURE,
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILURE,
    GET_NEW_RELEASE_PRODUCTS,
    GET_NEW_RELEASE_PRODUCTS_SUCCESS,
    GET_NEW_RELEASE_PRODUCTS_FAILURE,
    GET_BEST_SELLER_PRODUCTS,
    GET_BEST_SELLER_PRODUCTS_SUCCESS,
    GET_BEST_SELLER_PRODUCTS_FAILURE,
    GET_PRODUCT,
    GET_PRODUCT_FAILURE,
    GET_PRODUCT_SUCCESS,
    GET_RELATED_PRODUCTS,
    GET_RELATED_PRODUCTS_SUCCESS,
    GET_RELATED_PRODUCTS_FAILURE,
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    SET_FAMILY_GROUP_ID,
    SET_PRODUCTS_PAGE,
    GET_PRODUCTS_AFTER_SCROLLING,
    GET_PRODUCTS_AFTER_SCROLLING_SUCCESS,
    GET_PRODUCTS_AFTER_SCROLLING_FAILURE,
    GET_PRODUCTS_BY_CRITERIA,
    GET_PRODUCTS_BY_CRITERIA_SUCCESS,
    GET_PRODUCTS_BY_CRITERIA_FAILURE,
    BUILD_EMPTY_PRODUCT_REVIEW,
    ADD_PRODUCT_REVIEW,
    ADD_PRODUCT_REVIEW_SUCCESS,
    ADD_PRODUCT_REVIEW_FAILURE,
    GET_PRODUCT_REVIEWS,
    GET_PRODUCT_REVIEWS_SUCCESS,
    GET_PRODUCT_REVIEWS_FAILURE,
    //FILTERS
    GET_GROUPS_FILTER,
    GET_GROUPS_FILTER_SUCCESS,
    GET_GROUPS_FILTER_FAILURE,
    ADD_SELECTED_GROUP,
    REMOVE_SELECTED_GROUP,
    GET_FAMILY_GROUPS_FILTER,
    GET_FAMILY_GROUPS_FILTER_SUCCESS,
    GET_FAMILY_GROUPS_FILTER_FAILURE,
    ADD_SELECTED_FAMILY_GROUP,
    REMOVE_SELECTED_FAMILY_GROUP,
    CLEAN_PRODUCTS_FILTERS,
    GET_FAMILIES_FILTER,
    GET_FAMILIES_FILTER_SUCCESS,
    GET_FAMILIES_FILTER_FAILURE,
    ADD_SELECTED_FAMILY,
    REMOVE_SELECTED_FAMILY,
    GET_MANUFACTURERS_FILTER,
    GET_MANUFACTURERS_FILTER_SUCCESS,
    GET_MANUFACTURERS_FILTER_FAILURE,
    ADD_SELECTED_MANUFACTURER,
    REMOVE_SELECTED_MANUFACTURER,
    SET_SORT_BY_FIELD,
    SET_PRODUCTS_CRITERIA,
    SET_IS_OUTLET_SCREEN,
    GET_FAMILY_GROUPS,
    GET_FAMILY_GROUPS_SUCCESS,
    GET_FAMILY_GROUPS_FAILURE,
    SET_GROUP_ID,
} from './productsActionTypes';

// app config
import AppConfig from '../../util/AppConfig';

const initialState = {
    products: [],
    featuredProducts: [],
    groups: [],
    newReleaseProducts: [],
    bestSellerProducts: [],
    selectedProduct: undefined,
    relatedProducts: [],
    familyGroupId: undefined,
    pageIndex: 1,
    pageSize: AppConfig.pageSize,
    pageSizeInfiniteScroll: AppConfig.pageSizeInfiniteScroll,
    cardsPageSizeInfiniteScroll: AppConfig.cardsPageSizeInfiniteScroll,
    productsByCriteria: [],
    loadingCriteria: false,
    total: 0,
    groupsFilter: [],
    selectedGroups: [],
    familyGroupsFilter: [],
    selectedFamilyGroups: [],
    manufacturersFilter: [],
    selectedManufacturers: [],
    familiesFilter: [],
    sortByField: "",
    productReview: undefined,
    savingReview: false,
    loadingReviews: false,
    reviews: [],
    criteriaSearchBar: "",
    totalProductsByCriteria: undefined,
    isOutletScreen: false,
    familyGroups: [],
    groupId: undefined,
    error: undefined

};

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return { ...state, products: [], loading: true };
        case GET_PRODUCTS_SUCCESS:
            return { ...state, products: action.payload.products, total: action.payload.total, loading: true };
        case GET_PRODUCTS_FAILURE:
            return { ...state, loading: true };

        case GET_FEATURED_PRODUCTS:
            return { ...state };
        case GET_FEATURED_PRODUCTS_SUCCESS:
            return { ...state, featuredProducts: action.payload.products };
        case GET_FEATURED_PRODUCTS_FAILURE:
            return { ...state };

        case GET_GROUPS:
            return { ...state };
        case GET_GROUPS_SUCCESS:
            return { ...state, groups: action.payload.groups };
        case GET_GROUPS_FAILURE:
            return { ...state };

        case GET_NEW_RELEASE_PRODUCTS:
            return { ...state, loading: true };
        case GET_NEW_RELEASE_PRODUCTS_SUCCESS:
            return { ...state, newReleaseProducts: action.payload.products, loading: false };
        case GET_NEW_RELEASE_PRODUCTS_FAILURE:
            return { ...state, loading: false };

        case GET_BEST_SELLER_PRODUCTS:
            return { ...state, loading: true };
        case GET_BEST_SELLER_PRODUCTS_SUCCESS:
            return { ...state, bestSellerProducts: action.payload.products, loading: false };
        case GET_BEST_SELLER_PRODUCTS_FAILURE:
            return { ...state, loading: false };

        case GET_PRODUCT:
            return { ...state, loading: true, selectedProduct: undefined };
        case GET_PRODUCT_SUCCESS:
            return { ...state, selectedProduct: action.payload, error: undefined, loading: false };
        case GET_PRODUCT_FAILURE:
            return { ...state, error: action.payload, loading: false }

        case GET_RELATED_PRODUCTS:
            return { ...state, loading: true, relatedProducts: [] };
        case GET_RELATED_PRODUCTS_SUCCESS:
            return { ...state, relatedProducts: action.payload.products, loading: false };
        case GET_RELATED_PRODUCTS_FAILURE:
            return { ...state, loading: false };

        case SET_FAMILY_GROUP_ID:
            return { ...state, familyGroupId: action.payload };

        // set products page
        case SET_PRODUCTS_PAGE:
            return { ...state, loading: false, pageIndex: action.payload };

        // get products after scrolling
        case GET_PRODUCTS_AFTER_SCROLLING:
            return { ...state, loading: true, };

        // get products after scrolling success
        case GET_PRODUCTS_AFTER_SCROLLING_SUCCESS:
            return { ...state, products: [...state.products, ...action.payload.products], loading: false };

        // get products after scrolling failure
        case GET_PRODUCTS_AFTER_SCROLLING_FAILURE:
            return { ...state, loading: false };

        case GET_PRODUCTS_BY_CRITERIA:
            return { ...state, loadingCriteria: true, productsByCriteria: [] };
        case GET_PRODUCTS_BY_CRITERIA_SUCCESS:
            return { ...state, productsByCriteria: action.payload.products, totalProductsByCriteria: action.payload.total, loadingCriteria: false };
        case GET_PRODUCTS_BY_CRITERIA_FAILURE:
            return { ...state, loadingCriteria: false };

        case SET_PRODUCTS_CRITERIA:
            return { ...state, criteriaSearchBar: action.payload };

        //Groups filter
        case GET_GROUPS_FILTER:
            return { ...state };
        case GET_GROUPS_FILTER_SUCCESS:
            return { ...state, groupsFilter: action.payload };
        case GET_GROUPS_FILTER_FAILURE:
            return { ...state };

        case ADD_SELECTED_GROUP:
            return { ...state, selectedGroups: [...state.selectedGroups, action.payload], pageIndex: 1 };

        case REMOVE_SELECTED_GROUP:
            let selectedGroups = state.selectedGroups.filter((selectedGroup) => action.payload !== selectedGroup);
            if (selectedGroups.length === 0) {
                return { ...state, selectedGroups: [], pageIndex: 1 }
            } else {
                return { ...state, selectedGroups: selectedGroups, pageIndex: 1 }
            }

        //Family groups filter
        case GET_FAMILY_GROUPS_FILTER:
            return { ...state };
        case GET_FAMILY_GROUPS_FILTER_SUCCESS:
            return { ...state, familyGroupsFilter: action.payload };
        case GET_FAMILY_GROUPS_FILTER_FAILURE:
            return { ...state };

        case ADD_SELECTED_FAMILY_GROUP:
            return { ...state, selectedFamilyGroups: [...state.selectedFamilyGroups, action.payload], pageIndex: 1 };

        case REMOVE_SELECTED_FAMILY_GROUP:
            let selectedFamilyGroups = state.selectedFamilyGroups.filter((selectedFamilyGroup) => action.payload !== selectedFamilyGroup);
            if (selectedFamilyGroups.length === 0) {
                return { ...state, selectedFamilyGroups: [], pageIndex: 1 }
            } else {
                return { ...state, selectedFamilyGroups: selectedFamilyGroups, pageIndex: 1 }
            }

        //Families filter
        case GET_FAMILIES_FILTER:
            return { ...state };
        case GET_FAMILIES_FILTER_SUCCESS:
            return { ...state, familiesFilter: action.payload };
        case GET_FAMILIES_FILTER_FAILURE:
            return { ...state };

        case ADD_SELECTED_FAMILY:
            return { ...state, selectedFamilies: [...state.selectedFamilies, action.payload], pageIndex: 1 };

        case REMOVE_SELECTED_FAMILY:
            let selectedFamilies = state.selectedFamilies.filter((selectedFamily) => action.payload !== selectedFamily);
            if (selectedFamilies.length === 0) {
                return { ...state, selectedFamilies: [], pageIndex: 1 }
            } else {
                return { ...state, selectedFamilies: selectedFamilies, pageIndex: 1 }
            }

        //Manufacturers filter
        case GET_MANUFACTURERS_FILTER:
            return { ...state };
        case GET_MANUFACTURERS_FILTER_SUCCESS:
            return { ...state, manufacturersFilter: action.payload };
        case GET_MANUFACTURERS_FILTER_FAILURE:
            return { ...state };

        case ADD_SELECTED_MANUFACTURER:
            return { ...state, selectedManufacturers: [...state.selectedManufacturers, action.payload], pageIndex: 1 };

        case REMOVE_SELECTED_MANUFACTURER:
            let selectedManufacturers = state.selectedManufacturers.filter((selectedManufacturer) => action.payload !== selectedManufacturer);
            if (selectedManufacturers.length === 0) {
                return { ...state, selectedManufacturers: [], pageIndex: 1 }
            } else {
                return { ...state, selectedManufacturers: selectedManufacturers, pageIndex: 1 }
            }

        // clean products filters
        case CLEAN_PRODUCTS_FILTERS:
            return { ...state, selectedFamilyGroups: [], selectedGroups: [], selectedFamilies: [], selectedManufacturers: [] };

        case SET_SORT_BY_FIELD:
            return { ...state, sortByField: action.payload, pageIndex: 1 }


        case BUILD_EMPTY_PRODUCT_REVIEW:
            return {
                ...state, productReview: {
                    product: action.payload,
                    stars: undefined,
                    reviewBy: '',
                    review: ''
                },
                savingReview: false
            };

        case ADD_PRODUCT_REVIEW:
            return { ...state, savingReview: true };
        case ADD_PRODUCT_REVIEW_SUCCESS:
            return { ...state, savingReview: false };
        case ADD_PRODUCT_REVIEW_FAILURE:
            return { ...state, savingReview: false };

        case GET_PRODUCT_REVIEWS:
            return { ...state, loadingReviews: true };
        case GET_PRODUCT_REVIEWS_SUCCESS:
            return { ...state, reviews: action.payload, loadingReviews: false };
        case GET_PRODUCT_REVIEWS_FAILURE:
            return { ...state, loadingReviews: false };

        case SET_IS_OUTLET_SCREEN:
            return { ...state, isOutletScreen: action.payload };

        //Family groups for menu
        case GET_FAMILY_GROUPS:
            return { ...state };
        case GET_FAMILY_GROUPS_SUCCESS:
            return { ...state, familyGroups: action.payload };
        case GET_FAMILY_GROUPS_FAILURE:
            return { ...state };

        case SET_GROUP_ID:
            return { ...state, groupId: action.payload };

        default:
            return { ...state };
    }
}

export default productsReducer;
