/**
* User Sagas
*/
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// api
import * as api from '../../api';
import * as selectors from './selectors';
import moment from 'moment';

import {
    configurePaymentData
} from "../../utils";

import {
    CREATE_ORDER,
    CALCULATE_TOTAL_AMOUNT,
    CREATE_ORDER_IN_KARVE
} from './cartActionTypes';
import { IvaValue } from '../../util/Enums';

import {
    createOrderSuccess,
    createOrderFailure,
    cleanCart,
    setPaymentData,
    totalAmountWithShippingSuccess,
    totalAmountWithShippingFailure,
    createOrderInKarveSuccess,
    createOrderInKarveFailure
} from './cartActions';


function* createOrderFromServer({ payload }) {
    try {
        const { data, history } = payload;
        const response = yield call(api.cart.createOrderRequest, data);
        yield put(createOrderSuccess(response));

        //descomentar pago prueba tarjeta
        /*yield put(cleanCart());
        history.push('/compra-completada');*/

        //descomentar TPV produccion
        const responseSeg = yield call(api.cart.counterSecRequest, 'payment', moment().year());
        yield put(setPaymentData(configurePaymentData(data.total, response.code, responseSeg.seq)));
        history.push('/formulario-redireccion-tpv');
    } catch (error) {
        console.log(error);
        yield put(createOrderFailure(error));
    }
};

function* calculateTotalFromServer({ payload }) {
    try {
        const subtotal = yield select(selectors.cartSubtotal);
        const regionZone = yield select(selectors.regionZone);
        const totalWeight = yield select(selectors.totalWeight);
        const amountToCheckDiscount = subtotal + (subtotal * (IvaValue.IVA / 100));

        const shipping = yield call(api.cart.getShippingCost, regionZone, totalWeight, amountToCheckDiscount);
   
        const taxAmount = (subtotal + shipping.amount) * (IvaValue.IVA / 100);
        const total = (subtotal + shipping.amount) + taxAmount;
        
        yield put(totalAmountWithShippingSuccess({
            total:total,
            shipping: shipping.amount,
            tax: taxAmount
        }));

        payload.push('/tramitar-comprar/terminos-y-condiciones');
    } catch (error) {
        console.log(error);
        yield put(totalAmountWithShippingFailure(error));
    }
};

function* createOrderInKarve({ payload }) {
    try {
        const response = yield call(api.cart.createOrderInKarveRequest, payload);
        yield put(createOrderInKarveSuccess());

    } catch (error) {
        console.log(error);
        yield put(createOrderInKarveFailure(error));
    }
};

export function* createOrder() {
    yield takeEvery(CREATE_ORDER, createOrderFromServer);
}

export function* calculateAmount() {
    yield takeEvery(CALCULATE_TOTAL_AMOUNT, calculateTotalFromServer);
}

export function* watchCreateOrderInKarve() {
    yield takeEvery(CREATE_ORDER_IN_KARVE, createOrderInKarve);
}

export default function* rootSaga() {
    yield all([
        fork(createOrder),
        fork(calculateAmount),
        fork(watchCreateOrderInKarve)
    ]);
}