// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem } from '../../store/cart';


function IndicatorCart(props) {
    const { cart, cartRemoveItem, me } = props;
    let dropdown;
    let totals;

    totals = (
        <React.Fragment>
            <tr>
                <th><FormattedMessage id="amount.subtotal" /></th>
                <td><Currency value={cart.subtotal} /></td>
            </tr>
            <tr>
                <th><FormattedMessage id="amount.shipping" /></th>
                <td><FormattedMessage id="amount.shipping.message" /></td>
            </tr>
        </React.Fragment>
    );

    const items = cart.items.map((item) => {
        let options;
        let image;

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

        if (item.product.pictures.length) {
            image = (
                <div className="dropcart__product-image">
                    <Link to={`/producto/${item.product.id}`}>
                        <img src={item.product.pictures[0].value} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <button type="button" onClick={() => cartRemoveItem(item.id)} className={'dropcart__product-remove btn btn-light btn-sm btn-svg-icon'}>
                <Cross10Svg />
            </button>
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={`/producto/${item.product._id}`}>{item.product.name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Currency value={item.price} /></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th><FormattedMessage id="amount.total" /></th>
                                <td><Currency value={cart.total} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/carrito"><FormattedMessage id="indicator.cart.view.cart" /></Link>
                    {me &&
                        <Link className="btn btn-primary" to="/tramitar-comprar/datos-envio"><FormattedMessage id="indicator.cart.checkout" /></Link>}
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    <FormattedMessage id="indicator.cart.empty" />
                </div>
            </div>
        );
    }

    return (
        <Indicator url="/carrito" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    me: state.authApp.me
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
