// react
import React from 'react';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';


export default function FooterNewsletter() {
    const socialLinks = [
        {
            key: 'facebook',
            url: 'https://www.facebook.com/vercanminimizadosmedioambientales/',
            iconClass: 'fab fa-facebook-f',
        },
        {
            key: 'linkedin',
            url: 'https://www.linkedin.com/in/vercan-minimizados-07a6401bb/',
            iconClass: 'fab fa-linkedin-in'
        },
        {
            key: 'instagram',
            url: 'https://www.instagram.com/vercanminimizados/',
            iconClass: 'fab fa-instagram',
        },
        {
            key: 'youtube',
            url: 'https://www.youtube.com/channel/UCou8eIsdXt8iK4fEBiOd5Pg',
            iconClass: 'fab fa-youtube',
        }
    ];

    const socialLinksList = socialLinks.map((item) => (
        <li key={item.key} className={`footer-newsletter__social-link footer-newsletter__social-link--${item.key}`}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={item.iconClass} />
            </a>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            <div className="footer-newsletter__text">
            <FormattedMessage id="home.newsletter.label" />
            </div>

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address"><FormattedMessage id="home.mailAddress.label" /></label>
                <FormattedMessage id="mailAddress.placeholder">
                    {title =>
                        <input
                            type="text"
                            className="footer-newsletter__form-input form-control"
                            id="footer-newsletter-address"
                            placeholder={title}
                        />
                    }
                </FormattedMessage>
                <button type="submit" className="footer-newsletter__form-button btn btn-primary"><FormattedMessage id="footer.button.subscribe" /></button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                <FormattedMessage id="footer.follow" />
            </div>

            <ul className="footer-newsletter__social-links">
                {socialLinksList}
            </ul>
        </div>
    );
}
