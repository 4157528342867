// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';

import AccountPageChangePasswordForm from './AccountPageChangePasswordForm'
import {
    buildEmptyPasswords,
    changeUserPassword
} from '../../store/user';

import SitePageAccessDenied from '../site/SitePageAccessDenied';


class AccountPagePassword extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
       this.props.buildEmptyPasswords();
    }

    onSubmit(password) {
        const {me} = this.props;
        this.props.changeUserPassword(password, me._id);
    }

    render() {
        const {passwords, loadingPassword, me} = this.props;

        if (!me) {
            return (
                <SitePageAccessDenied/>
            );
        }

        if (passwords) {
            return (
                <div className="card">
                    <Helmet>
                        <title>{`Cambiar contraseña — ${theme.name}`}</title>
                        <meta name="description" content={"Formulario para cambiar la contraseña"}></meta>
                    </Helmet>
    
                    <div className="card-header">
                        <h5>{<FormattedMessage id="change.password.title" />}</h5>
                    </div>
                    <div className="card-divider" />
                    <div className="card-body">
                        <AccountPageChangePasswordForm passwords={passwords} loading={loadingPassword} onSubmit={this.onSubmit} />
                    </div>
                </div>
            );
        }

        return (
          null
        );

    }
}

// map state to props
const mapStateToProps = ({ authApp, userApp, settings }) => {
    const { me } = authApp;
    const { passwords, loadingPassword } = userApp;
    return { me, passwords, loadingPassword, settings };
}

const mapDispatchToProps = {
    buildEmptyPasswords,
    changeUserPassword
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPagePassword);
