import { 
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    GET_FEATURED_PRODUCTS,
    GET_FEATURED_PRODUCTS_SUCCESS,
    GET_FEATURED_PRODUCTS_FAILURE,
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILURE,
    GET_NEW_RELEASE_PRODUCTS,
    GET_NEW_RELEASE_PRODUCTS_SUCCESS,
    GET_NEW_RELEASE_PRODUCTS_FAILURE,
    GET_BEST_SELLER_PRODUCTS,
    GET_BEST_SELLER_PRODUCTS_SUCCESS,
    GET_BEST_SELLER_PRODUCTS_FAILURE,
    GET_PRODUCT,
    GET_PRODUCT_FAILURE,
    GET_PRODUCT_SUCCESS,
    GET_RELATED_PRODUCTS,
    GET_RELATED_PRODUCTS_SUCCESS,
    GET_RELATED_PRODUCTS_FAILURE,
    SET_FAMILY_GROUP_ID,
    GET_PRODUCTS_BY_CRITERIA,
    GET_PRODUCTS_BY_CRITERIA_SUCCESS,
    GET_PRODUCTS_BY_CRITERIA_FAILURE,
    SET_PRODUCTS_PAGE,
    GET_PRODUCTS_AFTER_SCROLLING,
    GET_PRODUCTS_AFTER_SCROLLING_SUCCESS,
    GET_PRODUCTS_AFTER_SCROLLING_FAILURE,
    BUILD_EMPTY_PRODUCT_REVIEW,
    ADD_PRODUCT_REVIEW,
    ADD_PRODUCT_REVIEW_SUCCESS,
    ADD_PRODUCT_REVIEW_FAILURE,
    GET_PRODUCT_REVIEWS,
    GET_PRODUCT_REVIEWS_SUCCESS,
    GET_PRODUCT_REVIEWS_FAILURE,
    //FILTERs
    GET_GROUPS_FILTER,
    GET_GROUPS_FILTER_SUCCESS,
    GET_GROUPS_FILTER_FAILURE,
    ADD_SELECTED_GROUP,
    REMOVE_SELECTED_GROUP,
    GET_FAMILY_GROUPS_FILTER,
    GET_FAMILY_GROUPS_FILTER_SUCCESS,
    GET_FAMILY_GROUPS_FILTER_FAILURE,
    ADD_SELECTED_FAMILY_GROUP,
    REMOVE_SELECTED_FAMILY_GROUP,
    CLEAN_PRODUCTS_FILTERS,
    GET_FAMILIES_FILTER,
    GET_FAMILIES_FILTER_SUCCESS,
    GET_FAMILIES_FILTER_FAILURE,
    ADD_SELECTED_FAMILY,
    REMOVE_SELECTED_FAMILY,
    GET_MANUFACTURERS_FILTER,
    GET_MANUFACTURERS_FILTER_SUCCESS,
    GET_MANUFACTURERS_FILTER_FAILURE,
    ADD_SELECTED_MANUFACTURER,
    REMOVE_SELECTED_MANUFACTURER,
    SET_SORT_BY_FIELD,
    SET_PRODUCTS_CRITERIA,
    SET_IS_OUTLET_SCREEN,
    GET_FAMILY_GROUPS,
    GET_FAMILY_GROUPS_SUCCESS,
    GET_FAMILY_GROUPS_FAILURE,
    SET_GROUP_ID
 } from './productsActionTypes';


// eslint-disable-next-line import/prefer-default-export
export const getProducts = (familyGroup, group) => ({
    type: GET_PRODUCTS,
    payload:{familyGroup: familyGroup, group: group}
});

export const getProductsSuccess = (data) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: data
});

export const getProductsFailure = (error) => ({
    type: GET_PRODUCTS_FAILURE,
    payload: error
});

export const getFeaturedProducts = (data) => ({
    type: GET_FEATURED_PRODUCTS,
    payload:data
});

export const getFeaturedProductsSuccess = (data) => ({
    type: GET_FEATURED_PRODUCTS_SUCCESS,
    payload: data
});

export const getFeaturedProductsFailure = (error) => ({
    type: GET_FEATURED_PRODUCTS_FAILURE,
    payload: error
});

export const getGroups = () => ({
    type: GET_GROUPS
});

export const getGroupsSuccess = (data) => ({
    type: GET_GROUPS_SUCCESS,
    payload: data
});

export const getGroupsFailure = (error) => ({
    type: GET_GROUPS_FAILURE,
    payload: error
});

export const getNewReleaseProducts = (data) => ({
    type: GET_NEW_RELEASE_PRODUCTS,
    payload:data
});

export const getNewReleaseProductsSuccess = (data) => ({
    type: GET_NEW_RELEASE_PRODUCTS_SUCCESS,
    payload: data
});

export const getNewReleaseProductsFailure = (error) => ({
    type: GET_NEW_RELEASE_PRODUCTS_FAILURE,
    payload: error
});

export const getBestSellerProducts = (data) => ({
    type: GET_BEST_SELLER_PRODUCTS,
    payload:data
});

export const getBestSellerProductsSuccess = (data) => ({
    type: GET_BEST_SELLER_PRODUCTS_SUCCESS,
    payload: data
});

export const getBestSellerProductsFailure = (error) => ({
    type: GET_BEST_SELLER_PRODUCTS_FAILURE,
    payload: error
});

export const getProduct = (productId) => ({
    type: GET_PRODUCT,
    payload: productId
});

export const getProductSuccess = (data) => ({
    type: GET_PRODUCT_SUCCESS,
    payload: data
});

export const getProductFailure = (error) => ({
    type: GET_PRODUCT_FAILURE,
    payload: error
});

export const getRelatedProducts = (productId) => ({
    type: GET_RELATED_PRODUCTS,
    payload: productId
});

export const getRelatedProductsSuccess = (data) => ({
    type: GET_RELATED_PRODUCTS_SUCCESS,
    payload: data
});

export const getRelatedProductsFailure = (error) => ({
    type: GET_RELATED_PRODUCTS_FAILURE,
    payload: error
});

export const setFamilyGroupId = (familyGroupId) => ({
    type: SET_FAMILY_GROUP_ID,
    payload: familyGroupId
});

export const getProductsByCriteria = (criteria) => ({
    type: GET_PRODUCTS_BY_CRITERIA,
    payload: criteria
});

export const setProductsCriteriaSearch = (criteria) => ({
    type: SET_PRODUCTS_CRITERIA,
    payload: criteria
});

export const getProductsByCriteriaSuccess = (data) => ({
    type: GET_PRODUCTS_BY_CRITERIA_SUCCESS,
    payload: data
});

export const getProductsByCriteriaFailure = (error) => ({
    type: GET_PRODUCTS_BY_CRITERIA_FAILURE,
    payload: error
});

/**
 * Redux Action Set products Page
 */
export const setProductsPage = (page) => ({
    type: SET_PRODUCTS_PAGE,
    payload: page
});

/**
 * Redux Action Get products After Scrolling
 */
export const getProductsAfterScrolling = (data) => ({
    type: GET_PRODUCTS_AFTER_SCROLLING,
    payload: data
});

/**
 * Redux Action Get products After Scrolling Success
 */
export const getProductsAfterScrollingSuccess = (data) => ({
    type: GET_PRODUCTS_AFTER_SCROLLING_SUCCESS,
    payload: data
});

/**
 * Redux Action Get products After Scrolling Failure
 */
export const getProductsAfterScrollingFailure = (error) => ({
    type: GET_PRODUCTS_AFTER_SCROLLING_FAILURE,
    payload: error
});

export const getGroupsFilter = () => ({
    type: GET_GROUPS_FILTER
});

export const getGroupsFilterSuccess = (data) => ({
    type: GET_GROUPS_FILTER_SUCCESS,
    payload: data
});

export const getGroupsFilterFailure = (error) => ({
    type: GET_GROUPS_FILTER_FAILURE,
    payload: error
});

export const addSelectedGroup = (groupSelected) => ({
    type: ADD_SELECTED_GROUP,
    payload: groupSelected
});

export const removeSelectedGroup = (groupSelected) => ({
    type: REMOVE_SELECTED_GROUP,
    payload: groupSelected
});

export const getFamilyGroupsFilter = () => ({
    type: GET_FAMILY_GROUPS_FILTER
});

export const getFamilyGroupsFilterSuccess = (data) => ({
    type: GET_FAMILY_GROUPS_FILTER_SUCCESS,
    payload: data
});

export const getFamilyGroupsFilterFailure = (error) => ({
    type: GET_FAMILY_GROUPS_FILTER_FAILURE,
    payload: error
});

export const addSelectedFamilyGroup = (familyGroupSelected) => ({
    type: ADD_SELECTED_FAMILY_GROUP,
    payload: familyGroupSelected
});

export const removeSelectedFamilyGroup = (familyGroupSelected) => ({
    type: REMOVE_SELECTED_FAMILY_GROUP,
    payload: familyGroupSelected
});


export const getFamiliesFilter = () => ({
    type: GET_FAMILIES_FILTER
});

export const getFamiliesFilterSuccess = (data) => ({
    type: GET_FAMILIES_FILTER_SUCCESS,
    payload: data
});

export const getFamiliesFilterFailure = (error) => ({
    type: GET_FAMILIES_FILTER_FAILURE,
    payload: error
});

export const addSelectedFamily = (familyGroupSelected) => ({
    type: ADD_SELECTED_FAMILY,
    payload: familyGroupSelected
});

export const removeSelectedFamily = (familySelected) => ({
    type: REMOVE_SELECTED_FAMILY,
    payload: familySelected
});

export const getManufacturersFilter = () => ({
    type: GET_MANUFACTURERS_FILTER
});

export const getManufacturersFilterSuccess = (data) => ({
    type: GET_MANUFACTURERS_FILTER_SUCCESS,
    payload: data
});

export const getManufacturersFilterFailure = (error) => ({
    type: GET_MANUFACTURERS_FILTER_FAILURE,
    payload: error
});

export const addSelectedManufacturer = (manufacturerSelected) => ({
    type: ADD_SELECTED_MANUFACTURER,
    payload: manufacturerSelected
});

export const removeSelectedManufacturer = (manufacturerSelected) => ({
    type: REMOVE_SELECTED_MANUFACTURER,
    payload: manufacturerSelected
});

export const cleanProductsFilters = () => ({
    type: CLEAN_PRODUCTS_FILTERS
});

export const setSortByField = (selectedField) => ({
    type: SET_SORT_BY_FIELD,
    payload: selectedField
});

export const buildEmptyProductReview = (productId) => ({
    type: BUILD_EMPTY_PRODUCT_REVIEW,
    payload: productId
});

export const addProductReview = (data) => ({
    type: ADD_PRODUCT_REVIEW,
    payload: data
});

export const addProductReviewSuccess = (data) => ({
    type: ADD_PRODUCT_REVIEW_SUCCESS,
    payload: data
});

export const addProductReviewFailure = (error) => ({
    type: ADD_PRODUCT_REVIEW_FAILURE,
    payload: error
});

export const getProductReviews = (data) => ({
    type: GET_PRODUCT_REVIEWS,
    payload: data
});

export const getProductReviewsSuccess = (data) => ({
    type: GET_PRODUCT_REVIEWS_SUCCESS,
    payload: data
});

export const getProductReviewsFailure = (error) => ({
    type: GET_PRODUCT_REVIEWS_FAILURE,
    payload: error
});

export const setIsOutletScreen = (isOutlet) => ({
    type: SET_IS_OUTLET_SCREEN,
    payload: isOutlet
});

export const getFamilyGroups = () => ({
    type: GET_FAMILY_GROUPS
});

export const getFamilyGroupsSuccess = (data) => ({
    type: GET_FAMILY_GROUPS_SUCCESS,
    payload: data
});

export const getFamilyGroupsFailure = (error) => ({
    type: GET_FAMILY_GROUPS_FAILURE,
    payload: error
});

export const setGroupId = (groupId) => ({
    type: SET_GROUP_ID,
    payload: groupId
})