// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen, quickviewOpenSuccess } from '../../store/quickview';
import { wishlistAddItem } from '../../store/wishlist';
import { FormattedMessage } from 'react-intl';

import {
    openEmailClient
} from "../../utils";

function ProductCard(props) {
    const {
        product,
        layout,
        wishlistAddItem,
        compareAddItem,
        viewClick,
        onAddCartClick,
        adding,
        onAddWishListClick
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;

    const stock = product.stock > 0 ? true : false;

    if (product.badges) {
        if (product.badges.includes('sale')) {
            badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
        }
        if (product.badges.includes('hot')) {
            badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
        }
        if (product.badges.includes('new')) {
            badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
        }
    }

    if (product.priceWithDiscount > 0) {
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Oferta</div>);
    }
    
    if (product.freeShipping) {
        badges.push(<div key="hot" className="product-card__badge product-card__badge--new">Porte gratuito</div>); 
    }


    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.pictures && product.pictures.length > 0) {
        image = (
            <div className="product-card__image">
                <Link to={`/producto/${product._id}`}>
                    <img src={product.pictures[0].value} alt="" />
                </Link>
            </div>
        );
    }

    if (product.priceWithDiscount > 0) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price"><Currency value={product.priceWithDiscount} /></span>
                {' '}
                <span className="product-card__old-price"><Currency value={product.price} /></span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={product.price} />
            </div>
        );
    }

    if (product.features && product.features.length) {
        features = (
            <ul className="product-card__features-list">
                {product.features.map((feature, index) => (
                    <li key={index}>{`${feature.name}: ${feature.value}`}</li>
                ))}
            </ul>
        );
    }

    return (
        <div className={containerClasses}>
            <button
                type="button"
                onClick={() => viewClick(product)}
                className={classNames('product-card__quickview', {
                    'product-card__quickview--preload': false,
                })}
            >
                <Quickview16Svg />
            </button>
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    {!image && <br />}
                    <Link to={`/producto/${product._id}`}>
                        <LinesEllipsis
                            title={product.name}
                            text={product.name}
                            maxLine='3'
                            ellipsis='...'
                            trimRight
                            basedOn='letters' />
                    </Link>
                </div>
                <div>
                        <LinesEllipsis
                            title={product.code}
                            className="mb-15 mt-2"
                            text={product.code}
                            maxLine='3'
                            ellipsis='...'
                            trimRight
                            basedOn='letters' />
                </div>
                <div className="product-card__rating">
                    <Rating value={product.rating} />
                    {/*<div className=" product-card__rating-legend">{`${product.reviews ? product.reviews : 0} Reviews`}</div>*/}
                </div>
                {features}
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    <FormattedMessage id="product.availability.label" />
                    {': '}
                    {stock ?
                        <span className="text-success"><FormattedMessage id="product.with.stock" /></span>
                        :
                        <Link to="#"  onClick={() => openEmailClient()}>{" Consultar disponibilidad"}</Link>
                        
                    } </div>
                {price}
                <div className="product-card__buttons">
                    <React.Fragment>
                        <button
                            type="button"
                            onClick={() => onAddCartClick({ product: product, options: [], quantity: product.minimumQuantity > 0 ? product.minimumQuantity : 1 })}
                            className={classNames('btn btn-primary product-card__addtocart', {
                                'btn-loading': adding,
                            })}
                        >
                            <FormattedMessage id="button.cartAdd" />
                        </button>
                        <button
                            type="button"
                            onClick={() => onAddCartClick({ product: product, options: [], quantity: product.minimumQuantity > 0 ? product.minimumQuantity : 1 })}
                            className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                'btn-loading': adding,
                            })}
                        >
                            <FormattedMessage id="button.cartAdd" />
                        </button>

                    </React.Fragment>
                    <button
                        type="button"
                        onClick={() => onAddWishListClick(product)}
                        className={'btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist'}
                    >
                        <Wishlist16Svg />
                    </button>

                    {/*<AsyncAction
                        action={() => compareAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    />*/}
                </div>
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
