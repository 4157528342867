// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { cartAddItemSuccess } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { wishlistRemoveItem } from '../../store/wishlist';

// data stubs
import theme from '../../data/theme';
import {
    openEmailClient
} from "../../utils";

function ShopPageWishlist(props) {
    const { wishlist, wishlistRemoveItem, cartAddItemSuccess } = props;
    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: <FormattedMessage id="home.shop.wishlist" />, url: '' },
    ];

    let content;

    if (wishlist.length) {
        const itemsList = wishlist.map((item) => {
            let image;
            const stock = item.stock > 0 ? true : false;

            if (item.pictures.length > 0) {
                image = (
                    <Link to={`/producto/${item._id}`}>
                        <img src={item.pictures[0].value} alt="" />
                    </Link>
                );
            }

            let productPrice = item.priceWithDiscount > 0 ? item.priceWithDiscount : item.price;


            return (
                <tr key={item._id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">
                        {image}
                    </td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={`/producto/${item._id}`} className="wishlist__product-name">{item.name}</Link>
                        {/*<div className="wishlist__product-rating">
                            <Rating value={item.rating} />
                            <div className="wishlist__product-rating-legend">{`${item.reviews} Reviews`}</div>
                        </div>*/}
                    </td>
                    {item.stock > 0 ?
                        <td className="wishlist__column wishlist__column--stock">
                            <div className="badge badge-success">Con stock</div>
                        </td>
                        : <td className="wishlist__column wishlist__column--stock">
                        <Link to="#" onClick={() => openEmailClient()}>{"Consultar disponibilidad"}</Link>

                        </td>
                    }
                    <td className="wishlist__column wishlist__column--price"><Currency value={productPrice} /></td>
                   
                    <td className="wishlist__column wishlist__column--tocart">
                        <button disabled={false} type="button" onClick={() => cartAddItemSuccess({ product: item, options: [], quantity: item.minimumQuantity > 0 ? item.minimumQuantity  : 1 })} className={'btn btn-primary btn-sm'}>Agregar al carrito </button>

                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <button type="button" onClick={() => wishlistRemoveItem(item._id)} className={'btn btn-light btn-sm btn-svg-icon'} aria-label="Remove"><Cross12Svg /></button>
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image"></th>
                                <th className="wishlist__column wishlist__column--product"><FormattedMessage id="wishlist.table.product.label" /></th>
                                <th className="wishlist__column wishlist__column--stock"><FormattedMessage id="wishlist.table.stock.label" /></th>
                                <th className="wishlist__column wishlist__column--price"><FormattedMessage id="wishlist.table.price.label" /></th>
                                <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" />
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">
                            {itemsList}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message"><FormattedMessage id="wishlist.empty.label" /></div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm"><FormattedMessage id="wishlist.home.label" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Lista de deseos — ${theme.name}`}</title>
                <meta name="description" content={"Productos que deseo comprar próximamente"}></meta>
            </Helmet>

            <PageHeader header="Lista de deseos" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    cartAddItemSuccess,
    wishlistRemoveItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShopPageWishlist);
