// react
import React from 'react';

// third-party
import { Route, Switch, Redirect } from 'react-router-dom';

// application

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import PageCart from './shop/ShopPageCart';
import PageCheckoutShippingAddress from './shop/ShopPageCheckout';
// DESCOMENTAR PARA REALIZAR COMPRAS SIN PASAR POR EL TPV DE PRO -> 
//import PageCheckoutPayment from './shop/ShopPageCheckoutPayment';
// DESCOMENTAR PARA PRODUCCION
import PageCheckoutPayment from './shop/ShopPageCheckoutPaymentTPV';
import PaymentAutoSubmitForm from './shop/PaymentAutoSubmitForm';
import PaymentPaid from './shop/PaymentPaid';
import PageWishlist from './shop/ShopPageWishlist';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageOrderFailure from './shop/ShopPageOrderFailure';
import ShopPageProduct from './shop/ShopPageProduct';

import SitePageNotFound from './site/SitePageNotFound';
import SitePagePrivacyPolicy from './site/SitePagePrivacyPolicy';
import SitePageCookiesPolicy from './site/SitePageCookiesPolicy';
import SitePageTerms from './site/SitePageTerms';
import SitePageLegalWarning from './site/SitePageLegalWarning';
import AccountPageConfirmEmailSend from './account/AccountPageConfirmEmailSend';
import AccountPageConfirmRegisterSuccess from './account/AccountPageConfirmRegisterSuccess';
import AccountPageForgotPasswordRequest from './account/AccountPageForgotPasswordRequest';
import AccountPageRestorePasswordRequest from './account/AccountPageRestorePasswordRequest';

import ProductsList from './shop/ProductsList';
import ProductsOutletList from './shop/ProductsOutletList';

function Routes(props) {
    const { match, homeComponent } = props;

    return (
        <Switch>
            {/* Home*/}
            <Route exact path={`${match.path}`} component={homeComponent} />
            <Route exact path="/acceso-registro" component={AccountPageLogin} />

            {/* Shop*/}
            <Route                
                exact
                path="/busqueda-avanzada"
                render={(props) => (
                    <ProductsList {...props} columns={3} viewMode="list" sidebarPosition="start" />
                )}
            />
            <Route                
                exact
                path="/outlet"
                render={(props) => (
                    <ProductsOutletList {...props} columns={3} viewMode="list" sidebarPosition="start" />
                )}
            />

            <Route exact path="/carrito" component={PageCart} />
            <Route exact path="/formulario-redireccion-tpv" component={PaymentAutoSubmitForm} />
            <Route exact path="/lista-de-deseos" component={PageWishlist} />

            {/* Site*/}
            <Route exact path="/pagina-no-encontrada" component={SitePageNotFound} />
            <Route exact path="/politica-de-privacidad" component={SitePagePrivacyPolicy} />
            <Route exact path="/terminos-y-condiciones" component={SitePageTerms} />
            <Route exact path="/politica-de-cookies" component={SitePageCookiesPolicy} />
            <Route exact path="/aviso-legal" component={SitePageLegalWarning} />

            {/*Contraseña */}
            <Route exact path="/solicitud-restablecimiento-contrasena" component={AccountPageForgotPasswordRequest} />
            <Route exact path="/restablecer-contrasena" component={AccountPageRestorePasswordRequest} />
            <Route exact path="/confirmacion-envio-email-registro" component={AccountPageConfirmEmailSend} />
            <Route exact path="/registro-confirmado/:token" component={AccountPageConfirmRegisterSuccess} />

            {/*Pantalla compra ok o compra failure */}
            <Route exact path="/compra-completada" component={ShopPageOrderSuccess} />
            <Route exact path="/compra-fallida" component={ShopPageOrderFailure} />

            {/*Detalle producto */}
            <Route exact path="/producto/:productId" component={ShopPageProduct} />

            {/*tramitar compra */}
            <Route exact path="/tramitar-comprar/datos-envio" component={PageCheckoutShippingAddress} />
            <Route exact path="/tramitar-comprar/terminos-y-condiciones" component={PageCheckoutPayment} />


            {/* Account*/}
            <Route  path="/mi-cuenta" component={AccountLayout} />
            {/*Fixme? rutas ya definidas en el componente AccountLayout, las repito de nuevo para que aparezcan en el sitemap*/}
            <Route exact path={`/mi-cuenta/editar-perfil`} />
            <Route exact path={`/mi-cuenta/historial-pedidos`} />
            <Route exact path={`/mi-cuenta/direcciones`} />
            <Route exact path={`/mi-cuenta/cambiar-contrasena`} />

            {/* Page not found*/}
            <Route component={SitePageNotFound} />
        </Switch>
    );
}

export default Routes;
