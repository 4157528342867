// react
import React, { Component } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import {
    getRegions
} from '../../store/user';

import {
    cleanCart,
    createOrderInKarve
} from '../../store/cart';
import SitePageAccessDenied from '../site/SitePageAccessDenied';


class ShopPageOrderSuccess extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {order} = this.props;
        this.props.getRegions();
        this.props.cleanCart();
        this.props.createOrderInKarve(order)
    }

    regionName(regionCode) {
        const { regions } = this.props;
        if (regions && regions.length > 0) {
            //Find index of specific object using findIndex method.    
            const objIndex = regions.findIndex((obj => obj.code === regionCode));
            //Update object's name property.
            return regions[objIndex].name;
        } else {
            return "";

        }
    }

    render() {
        const { order, me } = this.props;

        if (!me || !order) {
            return (
                <SitePageAccessDenied />
            );
        }



        const items = order.products.map((item) => {
            return (
                <tr>
                    <td className="order-list__column-product" colSpan="2">
                        <Link to={`/producto/${item.product}`}>{item.name}</Link>
                    </td>
                    <td className="order-list__column-quantity text-right" data-title="Qty:">{item.quantity}</td>
                    <td className="order-list__column-total text-right"><Currency value={item.price} /></td>
                </tr>
            );
        });

        return (
            <div className="block order-success">
                <Helmet>
                    <title>{`Compra completada — ${theme.name}`}</title>
                    <meta name="description" content={"Pantalla con el detalle de la compra realizada"}></meta>
                </Helmet>

                <div className="container">
                    <div className="order-success__body">
                        <div className="order-success__header">
                            <Check100Svg className="order-success__icon" />
                            <h1 className="order-success__title"><FormattedMessage id="order.success.title" /></h1>
                            <div className="order-success__subtitle"><FormattedMessage id="order.success.subtitle" /></div>
                            <div className="order-success__actions">
                                <Link to="/" className="btn btn-xs btn-secondary"><FormattedMessage id="order.success.button.home" /></Link>
                            </div>
                        </div>

                        <div className="order-success__meta">
                            <ul className="order-success__meta-list">
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title"><FormattedMessage id="order.success.orderNumber" /></span>
                                    <span className="order-success__meta-value">{`#${order.code}`}</span>
                                </li>
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title"><FormattedMessage id="order.success.date" /></span>
                                    <span className="order-success__meta-value">{moment(order.createdAt.date, moment.ISO_8601).tz(order.createdAt.timezone).format("DD/MM/YYYY")}</span>
                                </li>
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title"><FormattedMessage id="order.success.total" /></span>
                                    <span className="order-success__meta-value"><Currency value={order.total} /></span>
                                </li>
                            </ul>
                        </div>

                        <div className="card">
                            <div className="order-list">
                                <table>
                                    <thead className="order-list__header">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="2"><FormattedMessage id="order.success.products" /></th>
                                            <th className="order-list__column-quantity text-right"><FormattedMessage id="order.success.quantity" /></th>
                                            <th className="order-list__column-total text-right"><FormattedMessage id="order.success.price" /></th>
                                        </tr>

                                    </thead>
                                    <tbody className="order-list__products">
                                        {items}
                                    </tbody>
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3"><FormattedMessage id="order.success.subtotal" /></th>
                                            <td className="order-list__column-total"><Currency value={order.subtotal} /></td>
                                        </tr>

                                        <tr>
                                            <th className="order-list__column-label" colSpan="3"><FormattedMessage id="order.success.shipping" /></th>
                                            <td className="order-list__column-total"><Currency value={order.shipping} /></td>
                                        </tr>

                                        <tr>
                                            <th className="order-list__column-label" colSpan="3"><FormattedMessage id="order.success.tax" /></th>
                                            <td className="order-list__column-total"><Currency value={order.tax} /></td>
                                        </tr>
                                    </tbody>
                                    <tfoot className="order-list__footer">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3"><FormattedMessage id="order.success.total" /></th>
                                            <td className="order-list__column-total"><Currency value={order.total} /></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div className="row mt-3 no-gutters mx-n2">
                            <div className="col-sm-6 col-12 px-2">
                                <div className="card address-card">
                                    <div className="address-card__body">
                                        <div className="address-card__badge address-card__badge--muted">
                                            <FormattedMessage id="order.success.address" />
                                        </div>
                                        <div className="address-card__name">
                                            {`${order.shippingAddress.name}`}
                                        </div>
                                        <div className="address-card__row">
                                            {order.shippingAddress.postalCode}
                                            , {" "}
                                            {order.shippingAddress.locality}
                                            <br />
                                            {this.regionName(order.shippingAddress.region)}
                                            <br />
                                            {order.shippingAddress.address}
                                        </div>
                                        <div className="address-card__row">
                                            <div className="address-card__row-title"><FormattedMessage id="order.success.address.phone" /></div>
                                            <div className="address-card__row-content">{order.shippingAddress.phone}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {order.billingAddress &&
                                <div className="col-sm-6 col-12 px-2">
                                    <div className="card address-card">
                                        <div className="address-card__body">
                                            <div className="address-card__badge address-card__badge--muted">
                                                <FormattedMessage id="order.success.billingAddress" />
                                            </div>
                                            <div className="address-card__name">
                                                {`${order.billingAddress.name}`}
                                            </div>
                                            <div className="address-card__row">
                                                {order.billingAddress.postalCode}
                                                , {" "}
                                                {order.billingAddress.locality}
                                                <br />
                                                {this.regionName(order.billingAddress.region)}
                                                <br />
                                                {order.billingAddress.address}
                                            </div>
                                            <div className="address-card__row">
                                                <div className="address-card__row-title"><FormattedMessage id="order.success.address.phone" /></div>
                                                <div className="address-card__row-content">{order.billingAddress.phone}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    order: state.cart.order,
    regions: state.userApp.regions,
    me: state.authApp.me
});

const mapDispatchToProps = {
    getRegions,
    cleanCart,
    createOrderInKarve
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderSuccess);

