/**
 * Login Form
 */
import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Form } from 'reactstrap';
import FormInput from '../Form/FormInput';
import { FormattedMessage } from 'react-intl';
import { validateIdentityDocument } from "../../validators/IdentityDocumentValidator";


const formikEnhancer = withFormik({
    validationSchema: (props) => {
        Yup.addMethod(Yup.string, 'nifValidation', function (message) {
            return this.test('nifValidation', message, function (value) {
                const { path, createError } = this;
                const document = value;
                let documentOk = validateIdentityDocument(document);
                if (!value) {
                    return true;
                }
                if (!documentOk) {
                    return createError({ path, message: 'El documento de identidad no es correcto' });
                } else {
                    return true;
                }
            });
        });
        return Yup.object().shape({
            name: Yup.string()
                .required('Debe indicar su nombre2'),
            phone: Yup.string()
                .matches(/^$|^(\\+34|0034|34)?[\\s|\\-|\\.]?[6|7|8|9][\\s|\\-|\\.]?([0-9][\\s|\\-|\\.]?){8}$/, "El teléfono no es correcto"),
            nif: Yup.string()
                .nifValidation(Yup.string(), 'El NIF no es correcto'),
        });
    },
    mapPropsToValues: props => ({
        _id: props.user._id,
        name: props.user.name,
        surname: props.user.surname,
        phone: props.user.phone,
        nif: props.user.nif
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        props.onSubmit(values);
        setSubmitting(false);
    },
    enableReinitialize: true
});


const EditProfileForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <input type='hidden' name='product' id='product' value={values.product} />

            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <FormattedMessage id="edit.profile.nif.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="edit.profile.nif.label" />}
                                type='text'
                                name='nif'
                                id='nif'
                                placeholder={placeholder}
                                value={values.nif}
                                messageError={errors.nif}
                                invalid={errors.nif && touched.nif}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <FormattedMessage id="edit.profile.name.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="edit.profile.name.label" />}
                                type="text"
                                name="name"
                                id="name"
                                placeholder={placeholder}
                                value={values.name}
                                messageError={errors.name}
                                invalid={errors.name && touched.name}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>

                <div className="col-sm-12 col-md-5 col-lg-5">
                    <FormattedMessage id="edit.profile.surname.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="edit.profile.surname.label" />}
                                type="text"
                                name="surname"
                                id="surname"
                                placeholder={placeholder}
                                value={values.surname}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3">
                    <FormattedMessage id="edit.profile.phone.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="edit.profile.phone.label" />}
                                type='text'
                                name='phone'
                                id='phone'
                                placeholder={placeholder}
                                value={values.phone}
                                messageError={errors.phone}
                                invalid={errors.phone && touched.phone}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            {props.loading ?
                <div className="buttons">
                    <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                        <FormattedMessage id="button.signin.label" />
                    </button>
                </div>
                :
                <div className="buttons">
                    <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="button.save" />
                    </button>
                </div>
            }
        </Form>
    );
};

export default formikEnhancer(EditProfileForm);
