import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    BUILD_EMPTY_REGISTER_DATA,
    BUILD_EMPTY_SIGNIN_DATA,
    CONFIRM_REGISTER,
    CONFIRM_REGISTER_SUCCESS,
    CONFIRM_REGISTER_FAILURE,
    LOGIN_CLIENT,
    LOGIN_CLIENT_SUCCESS,
    LOGIN_CLIENT_FAILURE,
    LOGOUT_CLIENT,
    LOGOUT_CLIENT_SUCCESS,
    LOGOUT_CLIENT_FAILURE,
    ME_REQUEST,
    ME_REQUEST_SUCCESS,
    ME_REQUEST_FAILURE,
    SEND_PASSWORD_RECOVERY_EMAIL,
    SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS,
    SEND_PASSWORD_RECOVERY_EMAIL_FAILURE,
    RECOVER_PASSWORD,
    RECOVER_PASSWORD_SUCCESS,
    RECOVER_PASSWORD_FAILURE,
    SEND_REGISTER_CONFIRMATION_EMAIL,
    SEND_REGISTER_CONFIRMATION_EMAIL_SUCCESS,
    SEND_REGISTER_CONFIRMATION_EMAIL_FAILURE
} from './authActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const register = (data, history) => ({
    type: REGISTER,
    payload: { data, history }
});

export const registerSuccess = (data) => ({
    type: REGISTER_SUCCESS,
    payload: data
});

export const registerFailure = (error) => ({
    type: REGISTER_FAILURE,
    payload: error
});

export const buildEmptyRegisterData = () => ({
    type: BUILD_EMPTY_REGISTER_DATA
});

export const buildEmptySigninData = () => ({
    type: BUILD_EMPTY_SIGNIN_DATA
});

export const confirmRegister = (token) => ({
    type: CONFIRM_REGISTER,
    payload: token
});

export const confirmRegisterSuccess = (data) => ({
    type: CONFIRM_REGISTER_SUCCESS,
    payload: data
});

export const confirmRegisterFailure = (error) => ({
    type: CONFIRM_REGISTER_FAILURE,
    payload: error
});

export const signinClient = (user, history) => ({
    type: LOGIN_CLIENT,
    payload: { user, history }
});

export const signinClientSuccess = (user) => ({
    type: LOGIN_CLIENT_SUCCESS,
    payload: user
});

export const signinClientFailure = (error) => ({
    type: LOGIN_CLIENT_FAILURE,
    payload: error
});

export const logoutClient = () => ({
    type: LOGOUT_CLIENT
});

export const logoutClientSuccess = () => ({
    type: LOGOUT_CLIENT_SUCCESS
});

export const logoutClientFailure = () => ({
    type: LOGOUT_CLIENT_FAILURE
});

export const meRequest = () => ({
    type: ME_REQUEST
});

export const meRequestSuccess = (user) => ({
    type: ME_REQUEST_SUCCESS,
    payload: user
});

export const meRequestFailure = (error) => ({
    type: ME_REQUEST_FAILURE,
    payload: error
});

export const sendPasswordRecoveryEmail = (email) => ({
    type: SEND_PASSWORD_RECOVERY_EMAIL,
    payload: email
});

export const sendPasswordRecoveryEmailSuccess = () => ({
    type: SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS
});

export const sendPasswordRecoveryEmailFailure = (error) => ({
    type: SEND_PASSWORD_RECOVERY_EMAIL_FAILURE,
    payload: error
});

export const recoverPassword = (password, token, history) => ({
    type: RECOVER_PASSWORD,
    payload: { password, token, history }
});

export const recoverPasswordSuccess = () => ({
    type: RECOVER_PASSWORD_SUCCESS
});

export const recoverPasswordFailure = (error) => ({
    type: RECOVER_PASSWORD_FAILURE,
    payload: error
});

export const sendRegisterConfirmationEmail = (email) => ({
    type: SEND_REGISTER_CONFIRMATION_EMAIL,
    payload: email
});

export const sendRegisterConfirmationEmailSuccess = () => ({
    type: SEND_REGISTER_CONFIRMATION_EMAIL_SUCCESS
});

export const sendRegisterConfirmationEmailFailure = (error) => ({
    type: SEND_REGISTER_CONFIRMATION_EMAIL_FAILURE,
    payload: error
});