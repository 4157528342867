import axios from 'axios';
import config from '../config.js';

const axiosClient = () => {
  const defaultOptions = {
    baseURL: config.endpoint,
    timeout: 1200000,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('user_id') || sessionStorage.getItem('user_id');
    if (token) {
      config.headers.Authorization =  `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export default axiosClient();