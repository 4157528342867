/**
* Products Sagas
*/
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// api
import * as api from '../../api';

import {
    GET_FAMILY_WITH_FAMILYGROUPS,
} from './familyGroupsActionTypes';

import {
    getFamilyWithFamilyGroupsSuccess,
    getFamilyWithFamilyGroupsFailure,
} from './familyGroupsActions';


function* getFamilyGroupsFromServer() {
    try {
        const response = yield call(api.groups.getFamiliesRequest);
        yield put(getFamilyWithFamilyGroupsSuccess(response));
    } catch (error) {
        yield put(getFamilyWithFamilyGroupsFailure(error));
    }
};

export function* fetchFamilyGroups() {
    yield takeEvery(GET_FAMILY_WITH_FAMILYGROUPS, getFamilyGroupsFromServer);
}


export default function* rootSaga() {
    yield all([
        fork(fetchFamilyGroups),
    ]);
}