// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';


function SitePageAccessDenied() {
    return (
        <div className="block">
            <Helmet>
                <title>{`401 Access denied — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">
                        <FormattedMessage id="access.denied.404.title" />
                    </div>

                    <div className="not-found__content">
                        <h1 className="not-found__title"> <FormattedMessage id="access.denied.404.subtitle" />
                        </h1>

                        <Link to="/" className="btn btn-secondary btn-sm"><FormattedMessage id="access.denied.button.home" /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAccessDenied;
