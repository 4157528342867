// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';

function SitePagePrivacyPolicy() {
    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: <FormattedMessage id="terms.title" />, url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Términos y condiciones de compra`}</title>
                <meta name="description" content={"TÉRMINOS Y CONDICIONES DE COMPRA de www.vercanminimizados.com INTRODUCCIÓN El presente documento tiene como finalidad establecer las condiciones de uso..."}></meta>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">TÉRMINOS Y CONDICIONES DE COMPRA de <a href="http://tienda.vercanminimizados.com/">www.vercanminimizados.com</a></h1>
                            {/* <div className="document__subtitle">This Agreement was last modified on 27 May 2018.</div> */}
                        </div>
                        <div className="document__content typography text-justify">
                            <h2>INTRODUCCIÓN</h2>
                            <p>El presente documento tiene como finalidad establecer las condiciones de uso de la página <a href="http://tienda.vercanminimizados.com/">www.vercanminimizados.com</a> así como las condiciones de compra de productos a través de la misma por los usuarios.</p>
                            <p>Le rogamos que lea detenidamente los presentes TÉRMINOS Y CONDICIONES DE COMPRA así como la POLÍTICA DE PRIVACIDAD antes de usar esta página web. Si lo desea puede imprimir estas condiciones. Al utilizar esta página web o hacer un pedido a través de la misma el usuario acepta inequívocamente nuestros TÉRMINOS Y CONDICIONES DE COMPRA así como nuestra POLÍTICA DE PRIVACIDAD, por lo que, en caso de no estar de acuerdo, no debe utilizar la web.</p>


                            <h2>INFORMACIÓN GENERAL</h2>
                            <p>En cumplimiento del deber de información recogido en el art. 10 de la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico le informamos que la titularidad de la web corresponde a VERCAN MINIMIZADOS, S.L. CIF/NIF B39521661 y domicilio sito en P.I. La Cerrada I, nave 28. 39600 Maliaño - Cantabria y con correo electrónico info@vercanminimizados.com y teléfono 942269548.</p>

                            <h2>USO DEL SITIO</h2>
                            <p>Al hacer uso de esta página web y realizar pedidos a través de la misma, el usuario se compromete a los siguientes extremos:</p>
                            <ul>
                                <li>Usar la página web únicamente con la finalidad de consultar productos y realizar pedidos.
                                </li>

                                <li>No realizar ningún pedido fraudulento o falso. Si de una forma razonable se considera que un pedido es fraudulento VERCAN MINIMIZADOS, S.L. está autorizada a anularlo e informar a las autoridades pertinentes.
                                </li>

                                <li>Tener capacidad legal para celebrar contratos (el usuario declara ser mayor de 18 años).
                                </li>

                                <li>Facilitar sus datos de contacto de una forma veraz y exacta con el fin de poder realizar el envío de los pedidos.
                                </li>
                            </ul>

                            <h2>PROTECCIÓN DE DATOS PERSONALES</h2>
                            <p>VERCAN MINIMIZADOS, S.L. informa a los usuarios de esta página web sobre su política respecto del tratamiento y protección de los datos de carácter personal que puedan ser recabados por la navegación o contratación de servicios a través de la política de privacidad. Puede acceder a la política de privacidad así como la política de cookies desde la página web en cualquier momento.</p>

                            <h2>CONDICIONES DE COMPRA</h2>
                            <p>Disponibilidad del servicio. Los usuarios tienen la posibilidad de realizar pedidos de los artículos que se ofrecen a través de la página web tanto para su envío a una dirección dentro del territorio español como a un destino europeo.</p>

                            <h3>Idioma.</h3>
                            <p>El contrato se formalizará en lengua española.</p>

                            <h3>Disponibilidad de los productos.</h3>
                            <p>Los pedidos están sujetos a la disponibilidad de los productos. Si se produjeran dificultades en cuanto al suministro de los productos o si no quedaran artículos en stock, VERCAN MINIMIZADOS, S.L. informará al cliente de tal circunstancia, bien por mail o por teléfono y reembolsará cualquier cantidad que hubiese abonado por el producto o productos no disponibles o cambiará el producto por otro del mismo valor, según decida el cliente.</p>

                            <h3>Precios de los productos.</h3>
                            <p>En los precios de los artículos NO están incluidos todos los impuestos vigentes así como tampoco están incluidos los gastos de envío, ambos se añadirán al importe total a la finalización del pedido. VERCAN MINIMIZADOS, S.L. se reserva el derecho a modificar los precios en cualquier momento, si bien los productos se facturarán sobre la base del precio en vigor en el momento de formalizar el pedido (a reserva de la disponibilidad del producto).</p>
                            <p>En el pedido de compra final se indicará tanto el precio total del producto/productos, incluyendo IVA y los gastos de envío que correspondan, según se indica en los apartados siguientes.</p>
                            <p>El tipo de IVA aplicable será el legalmente vigente en cada momento en función del artículo concreto de que se trate.</p>
                            <p>En los pedidos con destino a Canarias, Ceuta y Melilla, las entregas se encontrarán exentas de IVA por aplicación de lo dispuesto en el artículo 21 de Ley 37/1992, sin perjuicio de la aplicación de los impuestos y aranceles correspondientes conforme a la normativa vigente en cada uno de estos territorios.</p>

                            <h3>Gastos de envío</h3>
                            <p>Los gastos de envío serán abonados, salvo disposición particular en contrario, por parte del comprador, siendo éstos pagados junto a la cantidad económica total derivada de los productos incluidos en el pedido. </p>
                            <p>Debido a la variedad en las características de los productos, así como en función de si el envío es a territorio peninsular, o a las Islas Baleares, Islas Canarias, Ceuta o Melilla, o a fuera de territorio español, trabajamos con diferentes medios de transporte, por lo que el coste de los gastos de envío se irá calculando a medida que se vaya efectuando el pedido, incorporando al pedido el coste final del envío a la finalización del proceso de compra.</p>

                            <h3>Forma de realizar un pedido.</h3>
                            <p>Conforme a nuestra garantía de seguridad y confidencialidad, estamos especialmente interesados en ofrecerle el más alto nivel de seguridad y proteger la confidencialidad de la información personal que nos aporta. Por ello, las transacciones comerciales son realizadas en un entorno de servidor seguro bajo protocolo SSL (Secure Socket Layer).</p>
                            <p>Para realizar un pedido debe seleccionar el artículo o artículos deseados. Una vez que haya seleccionado todos los artículos que desee, éstos se añadirán a su cesta de la compra. El siguiente paso será tramitar el pedido y efectuar el pago. Para ello deberá seguir los pasos que se le solicitan en la página web y seguir las indicaciones de compra que le aparecen en pantalla. Se le pedirán únicamente los datos necesarios para la compra y posterior entrega del pedido. Antes de formalizar la compra deberá ACEPTAR EXPRESAMENTE las presentes condiciones así como la política de privacidad.</p>
                            <p>Los datos registrados por la página web constituyen la prueba de las transacciones realizadas. El cliente recibirá confirmación del pedido a través de correo electrónico.</p>
                            <p>Durante el proceso, antes de realizar el pago, podrá modificar los datos de su pedido. El pedido no se formaliza hasta que finaliza el proceso, lo cual ocurrirá cuando haya efectuado el pago. Hacer clik en “Pagar” implica que adquiere y abona los artículos seleccionados, este paso supone un compromiso vinculante.</p>
                            <p>No existen límites de compra. El usuario puede realizar el pedido por el número de artículos que desee, sin mínimos ni máximos establecidos.</p>

                            <h3>Derecho de cancelación del pedido.</h3>
                            <p>VERCAN MINIMIZADOS, S.L. se reserva el derecho a rechazar un pedido de un cliente con el que existe litigio.</p>
                            <p>Asimismo VERCAN MINIMIZADOS, S.L. tiene derecho a rechazar o cancelar un pedido por razones justificadas, como por ejemplo:</p>
                            <ul>
                                <li>Por error en los datos de facturación (que sean incorrectos o imposibles de verificar)
                                </li>

                                <li>Si existe sospecha de fraude con la tarjeta bancaria
                                </li>

                                <li>Si no es posible enviarlo a la dirección indicada
                                </li>

                                <li>Si existen razones por las que se sospeche que el usuario no es un consumidor final
                                </li>

                                <li>Si el usuario es menor de 18 años
                                </li>

                                <li>Si no se ha realizado el pago completo.
                                </li>
                            </ul>

                            <h3>Forma de pago.</h3>
                            <p>Podrá utilizar como medio de pago TPV- Redsys. Una vez redirigido a la pasarela de pago, se solicita los datos de pago al cliente, si el TPV confirma el pago, el pedido será registrado.</p>
                            <p>El procedimiento de pago mediante tarjeta de crédito es complemente privado entre el comprador y la plataforma Redsys proporcionada por el Banco Santander, por lo que en ningún momento tendremos acceso a sus datos de tarjeta.</p>
                            <p>Solo se admiten pagos en EUROS.</p>
                            <p>VERCAN MINIMIZADOS, S.L. también le ofrece la posibilidad de realizar el pago mediante transferencia bancaria.</p>
                            <p>El pedido será enviado a la dirección que nos indique una vez confirmado el ingreso del importe total del pedido. Si el importe no fuera por el total, VERCAN MINIMIZADOS, S.L. se reserva el derecho a no enviar el pedido hasta su total abono por parte del cliente.</p>

                            <h3>Entrega.</h3>
                            <p>Los productos se enviarán a la dirección que el usuario haya indicado. </p>
                            <p>El plazo de entrega oscila entre 24 y 72 horas (días hábiles) para domicilios en la península y 7 días hábiles para entregas a Islas Baleares, Islas Canarias, Ceuta y Melilla.</p>
                            <p>El plazo de entrega para direcciones fuera del territorio español oscila entre 7 y 10 días hábiles.</p>
                            <p>Los plazos indicados se respetarán siempre que no existan circunstancias imprevistas o extraordinarias ajenas a nuestra voluntad. Si por algún motivo no pudiésemos cumplir con la fecha de entrega, le informaremos de esta circunstancia y le daremos la opción de seguir adelante con la compra estableciendo una nueva fecha de entrega o bien anular el pedido con el reembolso total del precio pagado.</p>
                            <p>A efectos de las presentes condiciones, se entenderá que se ha producido la “entrega“ o que el pedido ha sido “entregado“ en el momento en el que usted o un tercero indicado por usted adquiera la posesión material de los productos, lo que se acreditará mediante la firma de la recepción del pedido en la dirección de entrega convenida.</p>

                            <h3>Imposibilidad de entrega.</h3>
                            <p>Si fuera imposible efectuar la entrega del pedido en el domicilio indicado, VERCAN MINIMIZADOS, S.L. tratará de entregarlo a un tercero indicado por el cliente o volver a intentar su entrega en una fecha posterior. Para ello le rogamos que si no va a estar en el domicilio se ponga en contacto con VERCAN MINIMIZADOS, S.L. para convenir la entrega otro día.</p>
                            <p>Si en el plazo que establezca el transportista, a contar desde que su pedido está disponible para su entrega, el pedido no ha podido ser entregado por causas no imputables a VERCAN MINIMIZADOS, S.L. ni al transportista, los productos serán devueltos a los almacenes de VERCAN MINIMIZADOS, S.L., en cuyo caso, VERCAN MINIMIZADOS, S.L. podrá cargar al cliente los gastos de envío y retorno, así como los posibles gastos de gestión asociados.</p>
                            <p>VERCAN MINIMIZADOS, S.L. no será responsable por los errores o perjuicios causados en la entrega cuando la dirección introducida por el cliente no exista o sera incorrecta.</p>
                            <p>En el momento de entrega del producto, el cliente o el receptor deberá exhibir un documento de identificación al transportista, verificar el estado aparente de los bultos y firmar y anotar en el albarán de entrega las observaciones y reservas aparentes en ese momento. En cualquier caso, deberá comunicarlo a VERCAN MINIMIZADOS, S.L. en un plazo máximo de 24 horas desde la entrega para su comunicación al transportista.</p>

                            <h3>Cancelación de un pedido.</h3>
                            <p>El usuario tiene derecho a cancelar un pedido sin dar ninguna explicación. Este derecho es el derecho de desistimiento establecido por el art. 102 el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias.</p>
                            <p>Para cancelar un pedido puede notificarlo por correo electrónico dirigiéndose a info@vercanminimizados.com o por correo postal a la dirección P.I. La Cerrada I, nave 28. 39600 Maliaño – Cantabria.</p>
                            <p>Si nos envía un correo electrónico o una comunicación por escrito, por favor, incluya los detalles de su pedido para ayudarnos a identificarlo.</p>
                            <p>El plazo de desistimiento expirará a los 14 días naturales del día que el cliente o un tercero indicado por el cliente, distinto del transportista, adquirió la posesión material de los bienes o en caso de que los productos que componen el pedido se entreguen por separado, a los 14 días naturales del día que el cliente o un tercero indicado por el cliente, distinto del transportista, adquirió la posesión material del último de los mencionados productos.</p>
                            <p>Una vez ejercido el derecho al desistimiento el usuario deberá remitir a la dirección indicada los productos entregados asumiendo por tanto los costes de devolución. Por favor, tenga en cuenta que si usted decide devolvernos los artículos a portes debidos estaremos autorizados a cargarle los gastos en que podamos incurrir.</p>
                            <p>El derecho a desistir del contrato será de aplicación exclusivamente a aquellos productos que se devuelvan en las mismas condiciones en que el cliente los recibió. No se hará ningún reembolso si el producto ha sido usado más allá de la mera apertura del mismo, de productos que no estén en las mismas condiciones en las que se entregaron o que hayan sufrido algún daño, por lo que deberá ser cuidadoso con el/los producto/s mientras estén en su posesión. Por favor, devuelva el artículo usando o incluyendo todos sus envoltorios originales con todas las etiquetas. Rogamos no retiren las etiquetas hasta estar seguros de querer quedarse con cada artículo.</p>
                            <p>Tras examinar el artículo le comunicaremos si tiene derecho al reembolso de las cantidades abonadas.</p>
                            <p>Si la devolución incluye la totalidad de la compra, se ha seguido el procedimiento correcto y los productos están nuevos y sin usar, procederemos al reembolso del importe total de la compra sin incluir los gastos de envío en un plazo de 14 días desde que recibamos el pedido cancelado.</p>
                            <p>Si la devolución solo incluye una parte del pedido, se ha seguido el procedimiento correcto y los productos están nuevos y sin usar, solo le reembolsaremos el valor de los productos devueltos.</p>

                            <h3>Devolución de productos defectuosos.</h3>
                            <p>Si nos devuelve los productos porque son defectuosos o las descripciones no se corresponden con los mismos, procederemos a reembolsar el precio total de los productos, junto con los gastos de envío derivados y de la devolución del artículo.</p>

                            <h3>Garantía de los productos adquiridos</h3>
                            <p>Los productos disponen de una garantía legal por falta de conformidad de los mismos que se manifieste en un periodo establecido por el fabricante a partir de la fecha de entrega y de conformidad con lo establecido en la normativa de aplicación. </p>
                            <p>La garantía ofrecida por VERCAN MINIMIZADOS, S.L. se corresponde con la establecida en el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la defensa de Consumidores y Usuarios.</p>
                            <p>La garantía no incluye las deficiencias ocasionadas por negligencias, golpes, uso o manipulaciones indebidas, tensión no idónea, accidentes eléctricos, instalación y/o utilización no conforme con las instrucciones de uso o no efectuada por el servicio técnico autorizado cuando proceda. Quedan excluidos de la garantía los productos modificados o reparados por el cliente o cualquier otra persona no autorizada por el servicio de asistencia técnica.</p>

                            <h3>RESPONSABILIDADES</h3>
                            <p>El acceso y uso de esta página web es responsabilidad de los usuarios. No es objeto de garantía por parte de VERCAN MINIMIZADOS, S.L.</p>
                            <ul>
                                <li>La infalibilidad, la disponibilidad, la continuidad, la inexistencia de deficiencias de seguridad de la página web
                                </li>

                                <li>Que el contenido de la página o la información que pasa a través del mismo esté libre de virus o de otros elementos lesivos así como de errores, omisiones o incorrecciones.
                                </li>

                                <li>Los posibles daños o perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías o desconexiones en el funcionamiento operativo de la página web
                                </li>

                                <li>Retrasos o bloqueos en el uso de esta página causados por deficiencias o sobrecargas del centro de procesamiento de datos, ni de los daños que puedan ser causados por terceras personas mediante intromisiones ilegítimas que están fuera del control de VERCAN MINIMIZADOS, S.L..
                                </li>

                                <li>Se exonera de responsabilidad a VERCAN MINIMIZADOS, S.L. ante cualquier daño o perjuicio que pudiera sufrir el usuario como consecuencia de errores, defectos u omisiones en la información facilitada siempre que proceda de otras fuentes ajenas.
                                </li>
                            </ul>
                            <p>El mero acceso a la página web no supone entablar ningún tipo de relación comercial entre VERCAN MINIMIZADOS, S.L. y el usuario.</p>

                            <h3>Cesión de derechos y obligaciones.</h3>
                            <p>El contrato es vinculante tanto para el cliente como para VERCAN MINIMIZADOS, S.L. así como para nuestros respectivos sucesores, cesionarios y causahabientes. El cliente no podrá transmitir, ceder, gravar o de cualquier otro modo transferir un contrato o alguno de los derechos u obligaciones derivados del mismo, sin haber obtenido el consentimiento de VERCAN MINIMIZADOS, S.L. previo y por escrito. Podemos transmitir, ceder, gravar, subcontratar o de cualquier otro modo transferir un contrato o alguno de los derechos u obligaciones derivados del mismo, en cualquier momento durante su vigencia. Para evitar cualquier duda, dichas transmisiones, cesiones, gravámenes u otras transferencias no afectarán los derechos que, en su caso, usted, como consumidor, tiene reconocidos por ley ni anularán, reducirán o limitarán de cualquier otra forma las garantías, tanto expresas como tácitas, que le hubiésemos podido otorgar.</p>

                            <h2>INFORMACIÓN Y CONTENIDOS DE LA WEB</h2>
                            <p>La información que se muestra en la página web es la vigente en la fecha de última actualización. VERCAN MINIMIZADOS, S.L. se reserva el derecho a actualizar/modificar/eliminar información de esta página web en cualquier momento y sin previo aviso.</p>
                            <p>En el caso de que la página web contenga links a otras páginas web y materiales de terceros, dichos links se facilitan únicamente a efectos informativos, sin que VERCAN MINIMIZADOS, S.L. tenga control alguno sobre el contenido de dichas páginas web o materiales. Por lo tanto, no aceptamos responsabilidad alguna por cualquier daño o pérdida derivados de su uso.</p>
                            <p>VERCAN MINIMIZADOS, S.L. se reserva la facultad de modificar los Términos y Condiciones de Compra. Las modificaciones introducidas no tendrán carácter retroactivo. Si no está de acuerdo con las modificaciones introducidas, le recomendamos no hacer uso de nuestra página web.</p>

                            <h2>VIRUS PIRATERÍA Y OTROS ATAQUES INFORMÁTICOS</h2>
                            <p>El usuario no debe realizar un uso indebido de esta página web mediante la introducción intencionada en la misma de virus, troyanos, gusanos, bombas lógicas o cualquier otro programa o material tecnológicamente perjudicial o dañino. El usuario no tratará de tener acceso no autorizado a esta página web, al servidor en que dicha página se encuentra alojada o a cualquier servidor, ordenador o base de datos relacionada con nuestra página web. El usuario se compromete a no atacar esta página web. El incumplimiento de esta cláusula podría llevar aparejada la comisión de infracciones tipificadas por la normativa aplicable. Informaremos de cualquier incumplimiento de dicha normativa a las autoridades competentes y cooperaremos con ellas para descubrir la identidad del atacante. Asimismo, en caso de incumplimiento de la presente cláusula, dejará inmediatamente de estar autorizado a usar esta página web. No seremos responsables de cualquier daño o pérdida resultante de un ataque de denegación de servicio, virus o cualquier otro programa o material tecnológicamente perjudicial o dañino que pueda afectar a su ordenador, equipo informático, datos o materiales como consecuencia del uso de esta página web o de la descarga de contenidos de la misma o a los que la misma redireccione.</p>

                            <h2>PROPIEDAD INTELECTUAL Y DERECHOS DE AUTOR</h2>
                            <p>El usuario reconoce y consiente que todo copyright, marca registrada y demás derechos de propiedad industrial e intelectual sobre los materiales o contenidos que se aportan como parte de la página web corresponden en todo momento a VERCAN MINIMIZADOS, S.L.. El usuario podrá hacer uso de dicho material únicamente en la forma en que se le autorice expresamente.</p>

                            <h2>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
                            <p>El uso de esta página web y los contratos de compra de productos a través la misma se regirán por la legislación española. Cualquier controversia que surja o guarde relación con el uso de la página web o con dichos contratos será sometida a la jurisdicción no exclusiva de los juzgados y tribunales de Cantabria.</p>
                            <p>Puede formular cualquier queja y reclamación ante VERCAN MINIMIZADOS, S.L. a través del correo electrónico info@vercanminimizados.com o por correo postal a la dirección P.I. La Cerrada I, nave 28. 39600 Maliaño - Cantabria</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePagePrivacyPolicy;
