import React from 'react';
import PropTypes from 'prop-types';

import {
   FormGroup,
   Label,
   Input,
   FormFeedback,
   FormText
} from 'reactstrap';

const FormInput = ({ id, name, label, type, value, onChange, placeholder, messageError, invalid, message, readOnly, disabled }) => (
  <FormGroup>
    <Label for={id}>{label}</Label>
    <Input type={type}
           name={name}
           value={value}
           placeholder={placeholder}
           onChange={(e) => onChange(e)}
           id={id}
           invalid={invalid}
           autoComplete="new-password"
           readOnly={readOnly}
           disabled={disabled}
    />
    <FormFeedback>
      {messageError}
    </FormFeedback>
    <FormText>{message}</FormText>
  </FormGroup>
);

FormInput.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  placeholder: PropTypes.node,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.node,
  messageError: PropTypes.string,
  message: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool
}

export default FormInput;
