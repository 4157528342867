// react
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';


const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    slides = [
        {
            title: 'home.banner.message.one',
            text: 'home.banner.message.one.sub',
            image_classic: {
                ltr: '../assets/img/slides/sliderOne.jpg',
                rtl: '../assets/img/slides/sliderOne.jpg',
            },
            image_full: {
                ltr: '../assets/img/slides/sliderOne.jpg',
                rtl: '../assets/img/slides/sliderOne.jpg',
            },
            image_mobile: {
                ltr: '../assets/img/slides/sliderOne.jpg',
                rtl: '../assets/img/slides/sliderOne.jpg',
            },
        },
        {
            title: 'home.banner.message.two',
            text: 'home.banner.message.two.sub',
            image_classic: {
                ltr: '../assets/img/slides/sliderTwo.jpg',
                rtl: '../assets/img/slides/sliderTwo.jpg',
            },
            image_full: {
                ltr: '../assets/img/slides/sliderTwo.jpg',
                rtl: '../assets/img/slides/sliderTwo.jpg',
            },
            image_mobile: {
                ltr: '../assets/img/slides/sliderTwo.jpg',
                rtl: '../assets/img/slides/sliderTwo.jpg',
            },
        },
        {
            title: 'home.banner.message.three',
            text: 'home.banner.message.three.sub',
            image_classic: {
                ltr: '../assets/img/slides/sliderThree.jpg',
                rtl: '../assets/img/slides/sliderThree.jpg',
            },
            image_full: {
                ltr: '../assets/img/slides/sliderThree.jpg',
                rtl: '../assets/img/slides/sliderThree.jpg',
            },
            image_mobile: {
                ltr: '../assets/img/slides/sliderThree.jpg',
                rtl: '../assets/img/slides/sliderThree.jpg',
            },
        },
        {
            title: 'home.banner.message.three',
            text: 'home.banner.message.three.sub',
            image_classic: {
                ltr: '../assets/img/slides/sliderFour.jpg',
                rtl: '../assets/img/slides/sliderFour.jpg',
            },
            image_full: {
                ltr: '../assets/img/slides/sliderFour.jpg',
                rtl: '../assets/img/slides/sliderFour.jpg',
            },
            image_mobile: {
                ltr: '../assets/img/slides/sliderFour.jpg',
                rtl: '../assets/img/slides/sliderFour.jpg',
            },
        },
    ];

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        const slides = this.slides.map((slide, index) => {
            const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];
            if(!image){
                console.log("No image");
            }

            var test = image;
            var lastStr = test.lastIndexOf("/");
            var fileName = test.substring(lastStr + 1);
            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${require('../../assets/img/slides/'+ fileName)})`,
                            backgroundSize: 'cover',
                            backgroundRepeat:'no-repeat',
                            backgroundPosition: 'center top',                          
                            
                        }}
                    />
                    
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${require('../../assets/img/slides/'+ fileName)})`,
                            backgroundSize: 'cover',
                            backgroundRepeat:'no-repeat',
                            backgroundPosition: 'center top',  
                        }}
                    />
                    <div style={{color:'Black'}} className="block-slideshow__slide-content">
                        <div className="block-slideshow__slide-title">
                            <FormattedMessage id={slide.title}/>
                        </div>
                        <div className="block-slideshow__slide-text">
                            <FormattedMessage id={slide.text}/>
                        </div>
                        {/*<div className="block-slideshow__slide-button">
                            <Link to="/shop" className="btn btn-primary btn-lg">
                                <FormattedMessage id="home.banner.button"/>
                            </Link>
                        </div>*/}
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container-fluid">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {slides}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
