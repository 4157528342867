// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import { FormattedMessage } from 'react-intl';

// pages
import AccountPageAddresses from './AccountPageAddresses';
//import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';

export default function AccountLayout(props) {
    const { match, location } = props;

    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: 'Mi cuenta', url: '' },
    ];

    const links = [
        { title: <FormattedMessage id="account.profile.edit" />, url: 'editar-perfil' },
        { title: <FormattedMessage id="account.profile.orders.history" />, url: 'historial-pedidos' },
        { title: <FormattedMessage id="account.profile.addresses" />, url: 'direcciones' },
        { title: <FormattedMessage id="account.profile.change.password" />, url: 'cambiar-contrasena' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url}>{link.title}</Link>
            </li>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header="Mi cuenta" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title"> </h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/editar-perfil`} />
                                <Route sitemapIndex exact path={`${match.path}/editar-perfil`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/historial-pedidos`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/direcciones`} component={AccountPageAddresses} />
                                {/*<Route exact path={`${match.path}/addresses/:addressId`} component={AccountPageEditAddress} />*/}
                                <Route exact path={`${match.path}/cambiar-contrasena`} component={AccountPagePassword} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
