// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import 'moment-timezone';

// application
import Pagination from '../shared/Pagination';

// data stubs
import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import AccountPageOrderDetails from './AccountPageOrderDetails';

import {
    getUserOrders,
    setOrdersPage,
    setOrderDetail,
    getRegions
} from '../../store/user';
import Currency from '../shared/Currency';
// app config
import AppConfig from '../../util/AppConfig';

import SitePageAccessDenied from '../site/SitePageAccessDenied';
import { orderStatus } from '../../util/Enums';


class AccountPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: dataOrders,
            page: 1,
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.onSetOrderDetail = this.onSetOrderDetail.bind(this);

    }

    handlePageChange = (page) => {
        const { me } = this.props;
        this.setState(() => ({ page }));
        this.props.setOrdersPage(page);
        this.props.getUserOrders(me._id);
    };

    componentDidMount() {
        const { me } = this.props;
        if (me) {
            this.props.getUserOrders(me._id);
            this.props.setOrderDetail();
            this.props.getRegions();
        }
    }

    onSetOrderDetail(order) {
        this.props.setOrderDetail(order);
    }

    render() {
        const { loadingOrders, orders, ordersPageIndex, total, orderDetail, regions, me } = this.props;

        const ordersList = orders && orders.map((order) => (
            <tr key={order._id}>
                <td><Link to="#" onClick={() => this.onSetOrderDetail(order)} >{`#${order.code}`}</Link></td>
                <td class="text-right">{moment(order.createdAt.date, moment.ISO_8601).tz(order.createdAt.timezone).format("DD/MM/YYYY")}</td>
                {<td class="text-right">{order.paymentData && order.paymentData.error ? "Fallido" : "Correcto"}</td>}

                {order.paymentData && order.paymentData.error ?
                    <td class="text-right">-</td>
                    :
                    <td class="text-right">                        
                        {order.status.name}
                    </td>
                }                
                <td class="text-right"><Currency value={order.total} /></td>

            </tr>
        ));

        if (!me) {
            return (
                <SitePageAccessDenied />
            );
        }

        if (orderDetail && orderDetail._id) {
            return (
                <AccountPageOrderDetails order={orderDetail} regions={regions} />
            );

        } else {

            return (
                <div className="card">
                    <Helmet>
                        <title>{`Historial de pedidos — ${theme.name}`}</title>
                        <meta name="description" content={"Histórico de pedidos del usuiario"}></meta>
                    </Helmet>

                    <div className="card-header">
                        <h5><FormattedMessage id="order.history.header.title" /></h5>
                    </div>
                    <div className="card-divider" />
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="order.history.column.number" /></th>
                                        <th class="text-right"><FormattedMessage id="order.history.column.date" /></th>
                                        <th class="text-right"><FormattedMessage id="order.history.column.status.payment" /></th>
                                        <th class="text-right"><FormattedMessage id="order.history.column.status" /></th>
                                        <th class="text-right"><FormattedMessage id="order.history.column.total" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordersList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-divider" />
                    <div className="card-footer">
                        <Pagination current={ordersPageIndex}
                            total={(total + AppConfig.ordersPageSize - 1) / AppConfig.ordersPageSize}
                            siblings={2}
                            onPageChange={this.handlePageChange} />
                    </div>
                </div>
            );
        }
    }
}

// map state to props
const mapStateToProps = ({ authApp, userApp, settings }) => {
    const { me } = authApp;
    const { loadingOrders, orders, ordersPageIndex, total, orderDetail, regions } = userApp;
    return { me, loadingOrders, orders, ordersPageIndex, total, orderDetail, regions, settings };
}

const mapDispatchToProps = {
    getUserOrders,
    setOrdersPage,
    setOrderDetail,
    getRegions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageOrders);