import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    ADDING_TO_CART, CLEAN_CART,
    BUILD_SHIPPING_ADDRESS,
    SET_SHIPPING_ADDRESS,
    CREATE_ORDER,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAILURE,
    SET_PAYMENT_DATA,
    SET_REGION_ZONE,
    CALCULATE_TOTAL_AMOUNT,
    CALCULATE_TOTAL_AMOUNT_SUCCESS,
    CALCULATE_TOTAL_AMOUNT_FAILURE,
    CREATE_ORDER_IN_KARVE,
    CREATE_ORDER_IN_KARVE_SUCCESS,
    CREATE_ORDER_IN_KARVE_FAILURE
} from './cartActionTypes';
import { toast } from 'react-toastify';
import { IvaValue } from '../../util/Enums';
import { NotificationManager } from 'react-notifications';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product._id !== product._id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTax(subtotal) {
    return (subtotal * (IvaValue.IVA / 100));
}

function calcWeight(items) {
    return items.reduce((weight, item) => weight + item.weight, 0);
}

function addItem(state, product, options, quantity) {
    const itemIndex = findItemIndex(state.items, product, options);

    let newItems;
    let { lastItemId } = state;
    let productPrice = product.priceWithDiscount > 0 ? product.priceWithDiscount : product.price;
    let productWeight = product.weight ? product.weight : 0;

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: productPrice,
            total: productPrice * quantity,
            quantity,
            weight: product.freeShipping ? 0 : productWeight * quantity
        }];
    } else {
        const item = state.items[itemIndex];

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
                weight: product.freeShipping ? 0 : (item.quantity + quantity) * productWeight,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const weight = calcWeight(newItems);
    const total = subtotal + calcTax(subtotal);
    const tax = calcTax(subtotal);
    toast.success(`"${product.name}" Añadido al carrito!`);

    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
        tax,
        adding: false,
        weight
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const weight = calcWeight(newItems);
    const total = subtotal + calcTax(subtotal);
    const tax = calcTax(subtotal);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
        tax,
        weight
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);
        const productWeight = item.product.freeShipping ? 0 : item.product.weight ? item.product.weight : 0;
        if (!quantity) {
            return item;
        }
        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
            weight:  item.product.freeShipping ? 0 : quantity.value * productWeight
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const weight = calcWeight(newItems);
        const total = subtotal + calcTax(subtotal);
        const tax = calcTax(subtotal);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
            tax,
            weight
        };
    }

    return state;
}


const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    total: 0,
    tax: 0,
    shipping: 0,
    weight: 0,
    adding: false,
    shippingAddress: null,
    creatingOrder: false,
    order: null,
    modifiedAddress: false,
    paymentData: undefined,
    regionZone: '',
    loading: false,
    finalTotal: undefined,
    finalTax: undefined
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case ADDING_TO_CART:
            return { ...state, adding: action.payload };
        case CART_ADD_ITEM:
            return addItem(state, action.payload.product, action.payload.options, action.payload.quantity);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.payload);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.payload);

        case CLEAN_CART:
            return {
                ...state,
                lastItemId: 0,
                quantity: 0,
                items: [],
                subtotal: 0,
                total: 0,
                tax: 0,
                adding: false,
                shippingAddress: null,
                creatingOrder: false,
                modifiedAddress: false,
                paymentData: undefined,
                regionZone: '',
                weight: 0,
                finalTotal: undefined,
                finalTax: undefined,
                shipping: 0
            };

        case BUILD_SHIPPING_ADDRESS:
            return {
                ...state, shippingAddress:
                    action.payload ?
                        action.payload
                        :
                        {
                            name: "",
                            companyName: "",
                            phone: "",
                            nif: "",
                            address: "",
                            postalCode: undefined,
                            locality: "",
                            region: undefined,
                            additionalInformation: "",
                            billingAddress: {
                                name: "",
                                companyName: "",
                                phone: "",
                                nif: "",
                                address: "",
                                postalCode: undefined,
                                locality: "",
                                region: undefined,
                                additionalInformation: "",
                            }
                        }
            }

        case SET_SHIPPING_ADDRESS:
            return { ...state, shippingAddress: action.payload, modifiedAddress: true };

        case CREATE_ORDER:
            return { ...state, creatingOrder: true };
        case CREATE_ORDER_SUCCESS:
            return { ...state, creatingOrder: false, order: action.payload };
        case CREATE_ORDER_FAILURE:
            NotificationManager.error('Se ha producido un error durante la creación del pedido.');
            return { ...state, creatingOrder: false };

        case SET_PAYMENT_DATA:
            return { ...state, paymentData: action.payload };

        case SET_REGION_ZONE:
            return { ...state, regionZone: action.payload };

        case CALCULATE_TOTAL_AMOUNT:
            return { ...state, loading: true };

        case CALCULATE_TOTAL_AMOUNT_SUCCESS:
            return { ...state, loading: false, finalTotal: action.payload.total, shipping: action.payload.shipping, finalTax: action.payload.tax };

        case CALCULATE_TOTAL_AMOUNT_FAILURE:
            NotificationManager.error('Error al calcular los gastos de envío');
            return { ...state, loading: false, shipping: 0 };

        case CREATE_ORDER_IN_KARVE:
            return { ...state };
        case CREATE_ORDER_IN_KARVE_SUCCESS:
            return { ...state};
        case CREATE_ORDER_IN_KARVE_FAILURE:
            return { ...state, error: action.payload };

        default:
            return state;
    }
}
