export default {
    name: 'Vercan Minimizados Medioambientales S.L.',
    fullName: 'Vercan Minimizados Medioambientales S.L.',
    url: 'https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258',
    author: {
        name: 'Viacore IT',
        profile_url: 'https://viacoreit.com/',
    },
    contacts: {
        address: 'home.address',
        postalCode: 'home.address.postalCode',
        email: 'home.mailAddress',
        phone: 'header.phone',
    },
};
