// react
import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function ProductTabDescription(props) {

  const convertFromJSONToHTML = (raw) => {
    try {
      return stateToHTML(convertFromRaw(raw));
    } catch (error) {
      console.log(error);
    }
  }

  const a = props.product.description;
  if (!a.entityMap) {
    a.entityMap = {};
  }
  const html = convertFromJSONToHTML(a);

  return (
    <div className="typography">
      {props.product.files && props.product.files.length > 0 &&
        <Link to="#" onClick={() => props.onDownload(props.product._id, props.product.files[0])}>{<FormattedMessage id="product.download.button" />}</Link>
      }

      {html && <div dangerouslySetInnerHTML={{ __html: html }}></div>}
    </div>
  );
}

export default ProductTabDescription;
