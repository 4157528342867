// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';

//app
import AccountEditProfileForm from './AccountEditProfileForm';
import SitePageAccessDenied from '../site/SitePageAccessDenied';
import {
    editUser,
} from '../../store/user';

class AccountPageProfile extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {

    }

    onSubmit(data) {
        this.props.editUser(data);
    }


    render() {
        const {me, loading} = this.props;

        if (me && me._id)  {
            return (
                <div className="card">
                    <Helmet>
                        <title>{`Editar perfil — ${theme.name}`}</title>
                        <meta name="description" content={"Editar el perfil del usuario"}></meta>
                    </Helmet>
        
                    <div className="card-header">
                        <h5><FormattedMessage id="edit.profile.basic.data" /></h5>
                    </div>
                    <div className="card-divider" />
                    <div className="card-body">
                        {<AccountEditProfileForm
                            user={me}
                            loading={loading}
                            onSubmit={this.onSubmit} />}
                    </div>
                </div>
            );
        } else {
            return (
                <SitePageAccessDenied/>
            );
        }
       
    }
}

// map state to props
const mapStateToProps = ({ authApp, userApp, settings }) => {
    const { me } = authApp;
    const { loading } = userApp;
    return { me, loading, settings };
}

const mapDispatchToProps = {
    editUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageProfile);