import { toast } from 'react-toastify';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM } from './wishlistActionTypes';




export const wishlistAddItem = (product) => ({
    type: WISHLIST_ADD_ITEM,
    payload: product,
});

export const wishlistRemoveItem = (itemId) => ({
    type: WISHLIST_REMOVE_ITEM,
    payload: itemId,
});
