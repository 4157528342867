/**
 * Change password Form
 */
import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../Form/FormInput';
import { FormattedMessage } from 'react-intl';
import { Form } from 'reactstrap';


const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        password: Yup.string()
            .required('Debe indicar una contraseña')
            .min(6, 'La longitud de la contraseña debe ser de, al menos, 6 caracteres'),
        passwordConfirmation: Yup.string()
            .required('Debe repetir la contraseña')
            .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    }),
    mapPropsToValues: props => ({
        password: props.passwords.password,
        passwordConfirmation: props.passwords.passwordConfirmation,
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        props.onSubmit(values.password);
        setSubmitting(false);
        values.password = "";
        values.passwordConfirmation = "";
    },
    enableReinitialize: true
});


const changePasswordForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <FormattedMessage id="change.password.password.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="change.password.password.label" />}
                                type='password'
                                name='password'
                                id='password'
                                placeholder={placeholder}
                                value={values.password}
                                messageError={errors.password}
                                invalid={errors.password && touched.password}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <FormattedMessage id="change.password.passwordConfirmation.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="change.password.passwordConfirmation.label" />}
                                type='password'
                                name='passwordConfirmation'
                                id='passwordConfirmation'
                                placeholder={placeholder}
                                value={values.passwordConfirmation}
                                messageError={errors.passwordConfirmation}
                                invalid={errors.passwordConfirmation && touched.passwordConfirmation}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            {props.loading ?
                <div className="buttons">
                    <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                        <FormattedMessage id="button.save" />
                    </button>
                </div>
                :
                <div className="buttons">
                    <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="button.save" />
                    </button>
                </div>
            }
        </Form>
    );
};

export default formikEnhancer(changePasswordForm);
