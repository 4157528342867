export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_UPDATE_QUANTITIES = 'CART_UPDATE_QUANTITIES';
export const ADDING_TO_CART = 'ADDING_TO_CART';
export const CLEAN_CART = 'CLEAN_CART';

export const BUILD_SHIPPING_ADDRESS = 'BUILD_SHIPPING_ADDRESS';
export const SET_SHIPPING_ADDRESS='SET_SHIPPING_ADDRESS';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';

export const SET_REGION_ZONE = 'SET_REGION_ZONE';

export const CALCULATE_TOTAL_AMOUNT = 'CALCULATE_TOTAL_AMOUNT';
export const CALCULATE_TOTAL_AMOUNT_SUCCESS = 'CALCULATE_TOTAL_AMOUNT_SUCCESS';
export const CALCULATE_TOTAL_AMOUNT_FAILURE = 'CALCULATE_TOTAL_AMOUNT_FAILURE';

export const CREATE_ORDER_IN_KARVE = 'CREATE_ORDER_IN_KARVE';
export const CREATE_ORDER_IN_KARVE_SUCCESS = 'CREATE_ORDER_IN_KARVE_SUCCESS';
export const CREATE_ORDER_IN_KARVE_FAILURE = 'CREATE_ORDER_IN_KARVE_FAILURE';