// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';

function SitePagePrivacyPolicy() {
    const breadcrumb = [
        { title: 'Inicio', url: '/' },
        { title: <FormattedMessage id="privacyPolicy.title" />, url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Política de privacidad`}</title>
                <meta name="description" content={"Política de Privacidad. Protección de Datos Personales. VERCAN MINIMIZADOS, S.L. pone a su disposiciónl presente política de privacidad con la finalidad de informarle, de forma detallada, sobre cómo tratamos sus datos personales y protegemos su privacidad y la información que nos proporciona ..."}></meta>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title"><FormattedMessage id="privacyPolicy.title" /></h1>
                            {/* <div className="document__subtitle">This Agreement was last modified on 27 May 2018.</div> */}
                        </div>
                        <div className="document__content typography text-justify">
                            <h2>Política de Privacidad. Protección de Datos Personales.</h2>
                            <p>VERCAN MINIMIZADOS, S.L. pone a su disposiciónl presente política de privacidad con la finalidad de informarle, de forma detallada, sobre cómo tratamos sus datos personales y protegemos su privacidad y la información que nos proporciona.</p>

                            <p>En cumplimiento del Reglamento Europeo (UE) 2016/679 de Protección de Datos de las Personas Físicas y de la Ley Orgánica 3/2018 de 5 de Diciembre, de Protección de Datos y Garantía de los Derechos Digitales (LOPDGDD) le informamos lo siguiente:</p>

                            <p><strong>RESPONSABLE DEL TRATAMIENTO: </strong></p>

                            <ul>
                                <li>
                                    <strong>Titular: </strong>
                                    VERCAN MINIMIZADOS, S.L.
                                </li>
                                <li>
                                    <strong>C.I.F.: </strong>
                                    B39521661
                                </li>
                                <li>
                                    <strong>Domicilio Social: </strong>
                                    P.I. La Cerrada I, nave 28. 39600 Maliaño – Cantabria
                                </li>
                                <li>
                                    <strong>Teléfono: </strong>
                                    942 269 548
                                </li>
                                <li>
                                    <strong>Correo electrónico: </strong>
                                    info@vercanminimizados.com
                                </li>
                            </ul>

                            <h2>FINALIDADES:</h2>
                            <p>En cumplimiento del RGPD, le informamos de que sus datos personales, recabados a través de la web, mediante formularios o enlaces al correo electrónico, serán tratados con carácter general para gestionar la relación que mantenemos con usted, siendo las principales finalidades identificadas: gestión de clientes potenciales, contactos y acciones de comunicación comercial, tramitar encargos, solicitudes o cualquier tipo de petición que sea realizada por el usuario a través de cualquiera de las formas de contacto que se ponen a disposición del usuario en el sitio web de la compañía, pudiendo ser alojados en los ficheros correspondientes.
                                Le informamos que sus datos serán conservados en los ficheros de nuestra empresa mientras se necesiten, con el fin de realizar un seguimiento comercial y mantenerle informado (sobre los servicios y productos que le puedan ser de su interés, o gestionar el contacto o la consulta) por correo postal, telefónicamente o mediante otro cualquier medio electrónico que nos haya usted facilitado.</p>

                            <h2>CONSERVACIÓN DE LOS DATOS:</h2>
                            <p>Los datos personales proporcionados se conservarán mientras se mantenga la relación con la entidad, y no se solicite su supresión por parte del interesado. Serán conservados conforme a los plazos legales establecidos y en función de la finalidad por la que fueron recabados.</p>
                            <p>En el supuesto de que el interesado esté suscrito de forma voluntaria a algún tipo de envío de comunicaciones comerciales, newsletter, publicidad, etc. los datos permanecerán hasta que éste, tramite su baja de este tipo de comunicaciones.</p>

                            <h2>LEGITIMACIÓN:</h2>
                            <p>La legitimación del tratamiento de sus datos es el consentimiento que presta enviando el formulario de contacto o la consulta.</p>

                            <h2>RESPONSABILIDAD DEL USUARIO:</h2>
                            <p>Al facilitarnos sus datos a través de canales electrónicos, el usuario garantiza que es mayor de 18 años y que los datos facilitados a VERCAN MINIMIZADOS, S.L. son verdaderos, exactos, completos y actualizados. A estos efectos, el usuario confirma que responde de la veracidad de los datos comunicados y que mantendrá convenientemente actualizada dicha información de modo que responda a su situación real, haciéndose responsable de los datos falsos e inexactos que pudiera proporcionar, así como de los daños y perjuicios, directos o indirectos, que pudieran derivarse.</p>

                            <h2>COMUNICACIONES DE LOS DATOS:</h2>
                            <p>En general, en VERCAN MINIMIZADOS, S.L. no compartimos su información personal, salvo aquellas cesiones que debemos realizar en base a obligaciones legales impuestas.
                            En los supuestos que, para la realización de tratamientos de tipo administrativo, fiscal, contable y/o comercial, se cuente con los servicios de terceros, se formalizará debidamente el preceptivo contrato de encargado de tratamiento, con la finalidad de garantizar la seguridad y confidencialidad de los datos del interesado frente a terceros.</p>

                            <h2>TRANSFERENCIAS INTERNACIONALES DE DATOS:</h2>
                            <p>No existen transferencias internacionales de sus datos a países fuera del Espacio Económico Europeo (EEE).</p>

                            <h2>DERECHOS DE LOS INTERESADOS:</h2>
                            <p>Cualquier persona tiene derecho a obtener confirmación sobre si tratamos sus datos, por lo tanto tiene derecho a poder ejercitar los derechos, que como titular de sus datos personales le contempla la Ley, a acceder a sus datos personales, rectificar los datos inexactos, solicitar su supresión o solicitar la portabilidad de datos y oposición y limitación del tratamiento, dirigiéndose por escrito y acreditando debidamente su persona mediante fotocopia del D.N.I., a VERCAN MINIMIZADOS, S.L. indicando en el sobre la referencia: "Protección de Datos", en la siguiente dirección: P.I. La Cerrada I, nave 28. 39600 Maliaño – Cantabria, o mediante correo electrónico, dirigiéndose a info@vercanminimizados.com.
                            Asimismo, y especialmente si considera que no ha obtenido satisfacción plena en el ejercicio de sus derechos, podrá presentar una reclamación ante la autoridad nacional de control dirigiéndose a estos efectos a la Agencia Española de Protección de Datos, C/ Jorge Juan, 6 – 28001 Madrid.</p>

                            <h2>CÓMO PROTEGEMOS SU INFORMACIÓN:</h2>
                            <p>En VERCAN MINIMIZADOS, S.L. nos comprometemos a proteger su información personal.
                                Utilizamos medidas, controles  y procedimientos de carácter físico, organizativo y tecnológico, razonablemente fiables  y efectivos, orientados a preservar la integridad y la seguridad de sus datos y garantizar la privacidad.
                                Todas las medidas de seguridad son revisadas de forma periódica para garantizar la adecuación y efectividad.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePagePrivacyPolicy;
