import { 
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE,
    OPEN_FORM_ADDRESS,
    BUILD_EMPTY_ADDRESS,
    GET_REGIONS,
    GET_REGIONS_SUCCESS,
    GET_REGIONS_FAILURE,
    SET_USER_ADDRESS,
    SET_USER_ADDRESS_SUCCESS,
    SET_USER_ADDRESS_FAILURE,
    GET_USER_ORDERS,
    GET_USER_ORDERS_SUCCESS,
    GET_USER_ORDERS_FAILURE,
    SET_ORDERS_PAGE,
    SET_ORDER_DETAIL,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PASSWORD_SUCCESS,
    CHANGE_USER_PASSWORD_FAILURE,
    BUILD_EMPTY_PASSWORDS
 } from './userActionTypes';

export const editUser = (token) => ({
    type: EDIT_USER,
    payload: token
});

export const editUserSuccess = (data) => ({
    type: EDIT_USER_SUCCESS,
    payload: data
});

export const editUserFailure = (error) => ({
    type: EDIT_USER_FAILURE,
    payload: error
});

export const setOpenFormAddress = (open, address) => ({
    type: OPEN_FORM_ADDRESS,
    payload: { open, address }
});

export const buildEmptyAddress = () => ({
    type: BUILD_EMPTY_ADDRESS
});

export const getRegions = () => ({
    type: GET_REGIONS
});

export const getRegionsSuccess = (data) => ({
    type: GET_REGIONS_SUCCESS,
    payload: data
});

export const getRegionsFailure = (error) => ({
    type: GET_REGIONS_FAILURE,
    payload: error
});

export const setUserAddress = (data) => ({
    type: SET_USER_ADDRESS,
    payload: data
});

export const setUserAddressSuccess = (data) => ({
    type: SET_USER_ADDRESS_SUCCESS,
    payload: data
});

export const setUserAddressFailure = (error) => ({
    type: SET_USER_ADDRESS_FAILURE,
    payload: error
});

export const getUserOrders = (userId) => ({
    type: GET_USER_ORDERS,
    payload: userId
});

export const getUserOrdersSuccess = (data) => ({
    type: GET_USER_ORDERS_SUCCESS,
    payload: data
});

export const getUserOrdersFailure = (error) => ({
    type: GET_USER_ORDERS_FAILURE,
    payload: error
});

export const setOrdersPage = (page) => ({
    type: SET_ORDERS_PAGE,
    payload: page
});

export const setOrderDetail = (order) => ({
    type: SET_ORDER_DETAIL,
    payload: order
});

export const changeUserPassword = (password, userId) => ({
    type: CHANGE_USER_PASSWORD,
    payload: { password, userId }
});

export const changeUserPasswordSuccess = (data) => ({
    type: CHANGE_USER_PASSWORD_SUCCESS,
    payload: data
});

export const changeUserPasswordFailure = (error) => ({
    type: CHANGE_USER_PASSWORD_FAILURE,
    payload: error
});

export const buildEmptyPasswords = () => ({
    type: BUILD_EMPTY_PASSWORDS
});