import Payment from "payment";
import AppConfig from '../util/AppConfig';
import CryptoJS from 'crypto-js';
import moment from 'moment';

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}


export function configurePaymentData(total, orderNumber, seq) {
  //(new Date().getTime()).toString(36))
  let paidNumber = buildOrderNumber(moment().year(), seq);

  let merchantParameters = {
    "DS_MERCHANT_TRANSACTIONTYPE": AppConfig.paymentTransactionType,
    "DS_MERCHANT_CURRENCY": AppConfig.paymentCurrency,
    "DS_MERCHANT_MERCHANTCODE": AppConfig.paymentFUC,
    "DS_MERCHANT_TERMINAL": AppConfig.paymentTerminal,
    "DS_MERCHANT_URLOK": AppConfig.paymentUrlOK,
    "DS_MERCHANT_MERCHANTURL": AppConfig.userPaymentMerchantUrl + orderNumber,
    "DS_MERCHANT_AMOUNT": formatMoney(total).split(',').join("").split('.').join(""),
    "DS_MERCHANT_ORDER": paidNumber,
    "DS_MERCHANT_URLKO": AppConfig.paymentUrlKO,
    "DS_MERCHANT_PRODUCTDESCRIPTION": "Pedido Nº " + orderNumber + " Vercan "
  }
  // Base64 encoding of parameters
  let merchantWordArray = CryptoJS.enc.Utf8.parse(JSON.stringify(merchantParameters));
  let merchantBase64 = merchantWordArray.toString(CryptoJS.enc.Base64);

  // Generate transaction key

  let iv = CryptoJS.enc.Hex.parse("0000000000000000");
  let cipher = CryptoJS.TripleDES.encrypt(merchantParameters.DS_MERCHANT_ORDER, CryptoJS.enc.Base64.parse(AppConfig.paymentKey_REAL), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });

  // Sign
  var signature = CryptoJS.HmacSHA256(merchantBase64, cipher.ciphertext);
  let signatureBase64 = signature.toString(CryptoJS.enc.Base64);

  let paymentData = {
    Ds_MerchantParameters: merchantBase64,
    Ds_Signature: signatureBase64,
    Ds_SignatureVersion: AppConfig.paymentSignatureVersion
  }
  return paymentData;
}

function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    const amountFormated = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    return amountFormated;
  } catch (e) {
    console.log(e)
  }
};

function buildOrderNumber(year, sequence) {
  let sequenceString = sequence.toString();
  while (sequenceString.length < AppConfig.orderNumberMinLength) {
    sequenceString = '0' + sequenceString;
  }
  return year + sequenceString;
}

export function openEmailClient() {
  window.location.href = "mailto:tienda@vercanminimizados.com?subject=Constar stock de producto/s";
}