// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { Redirect } from 'react-router'
import { FormattedMessage } from 'react-intl';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import { Cart16Svg } from '../../svg';
import { cartAddItem } from '../../store/cart';
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';


function Suggestions(props) {
    const {
        context,
        className,
        products,
        cartAddItem,
        loadingCriteria
    } = props;

    function openEmailClient() {
        window.location.href = "mailto:tienda@vercanminimizados.com";
    }

    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const list = (products && products.map((product) => (
        <li key={product._id} className="suggestions__item">
            {product.pictures && product.pictures.length > 0 && (
                <div className="suggestions__item-image">
                    <img src={product.pictures[0].value} alt="" />
                </div>
            )}
            <div className="suggestions__item-info">
                <Link replace className="suggestions__item-name" to={`/producto/${product._id}`}>
                    {product.name}
                </Link>
                <div className="suggestions__item-meta">Código: {product.code} </div>
            </div>
            <div className="suggestions__item-price">
                <Currency value={product.priceWithDiscount > 0 ? product.priceWithDiscount : product.price} />
            </div>
            {/*context === 'header' && (
                <div className="suggestions__item-actions">
                    <AsyncAction
                        action={() => cartAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                title="Add to cart"
                                className={classNames('btn btn-primary btn-sm btn-svg-icon', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Cart16Svg />
                            </button>
                        )}
                    />
                </div>
            )*/}
        </li>
    )));

    if (loadingCriteria) {
        return (
            <div className={rootClasses}>
                <ul className="suggestions__list">
                    <li className="suggestions__item">
                        <RctSectionLoader />
                    </li>
                </ul>
            </div>
        );
    }

    if (!loadingCriteria && list && list.length > 0) {
        return (
            <div className={rootClasses}>
                <ul className="suggestions__list">
                    {list}
                </ul>
            </div>
        );
    } else {
        return (
            <div className={rootClasses}>
                <ul className="suggestions__list">
                    <li className="suggestions__item"><p><FormattedMessage id="search.product.notFound" /><Link to="#" onClick={() => openEmailClient()}>{" abrir email"}</Link>
                    </p></li>
                </ul>
            </div>
        );
    }

}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suggestions);
