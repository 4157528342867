// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';


function SitePageNotFound() {
    return (
        <div className="block">
            <Helmet>
                <title>{`404 Página no encontrada — ${theme.name}`}</title>
                <meta name="description" content={`La página no se ha encontrado por la url indicada`}></meta>
            </Helmet>

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">
                        <FormattedMessage id="error.notFound" />
                    </div>

                    <div className="not-found__content">
                        <h1 className="not-found__title"><FormattedMessage id="error.notFound.title" />
                        </h1>

                        <p className="not-found__text">
                        <FormattedMessage id="error.notFound.subtitle" />
                        </p>

                        <Link to="/" className="btn btn-secondary btn-sm"><FormattedMessage id="error.notFound.button.home" /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
