// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// data stubs
import theme from '../../data/theme';
// APP
import {
    confirmRegister,
} from '../../store/auth';

class AccountPageConfirmEmailSend extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.confirmRegister(this.props.match.params.token);
    }

    render() {
        const breadcrumb = [
            { title: 'Inicio', url: '/' },
            { title: 'Register', url: '' },
            { title: 'Email-send', url: '' },

        ];

        return (
            <div className="block about-us">
            <Helmet>
                <title>{`Registro confirmado — ${theme.name}`}</title>
                <meta name="description" content={"Registro confirmado. Ya se puede iniciar sesión con la cuenta del usuario"}></meta>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: `url(${require('../../assets/img/slides/slider7.jpg')})` }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title"><FormattedMessage id="confirm.register.success.title" /></h1>
                            <div className="about-us__text typography">
                                <p>
                                <FormattedMessage id="confirm.register.success.message" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ settings }) => {
    return { settings };
}

const mapDispatchToProps = {
    confirmRegister
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageConfirmEmailSend);
