// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import CheckoutForm from './CheckoutForm';
import SitePageAccessDenied from '../site/SitePageAccessDenied';

import {
    buildShippingAddress,
    setShippingAddress,
    totalAmountWithShipping,
    setRegionZone
} from '../../store/cart';

import {
    getProduct,
} from '../../store/products';

import {
    getRegions,
} from '../../store/user';

class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);

        this.state = {
            sameAddresses: true,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }


    handleChangeCheckBox = (e) => {
        const { checked } = e.target
        this.setState({
            sameAddresses: checked
        })
    }

    componentDidMount() {
        const { me, shippingAddress, modifiedAddress } = this.props;
        if (me) {
            this.props.getRegions();

            if (modifiedAddress) {
                shippingAddress.billingAddress = {
                    name: "",
                    companyName: "",
                    phone: "",
                    nif: "",
                    address: "",
                    postalCode: undefined,
                    locality: "",
                    region: undefined,
                    additionalInformation: ""
                };
                this.props.buildShippingAddress(shippingAddress);
            } else {
                if (me && me.addresses && me.addresses.length > 0) {
                    const mainAddress = this.getMainShippingAddress();
                    mainAddress.billingAddress = {
                        name: "",
                        companyName: "",
                        phone: "",
                        nif: "",
                        address: "",
                        postalCode: undefined,
                        locality: "",
                        region: undefined,
                        additionalInformation: ""
                    };
                    this.props.buildShippingAddress(mainAddress);
                } else {
                    this.props.buildShippingAddress();
                }
            }
        }
    }

    getMainShippingAddress() {
        const { me } = this.props;
        //Find index of specific object using findIndex method.    
        const objMainIndex = me.addresses.findIndex((obj => obj.status === true));
        //Update object's name property.
        return me.addresses[objMainIndex];
    }

    getRegion(regionCode) {
        const { regions } = this.props;
        if (regions && regions.length > 0) {
            //Find index of specific object using findIndex method.    
            const objIndex = regions.findIndex((obj => obj.code === regionCode));
            //Update object's name property.
            return regions[objIndex];
        } else {
            return "";

        }
    }
    onSubmit(values) {
        this.props.setShippingAddress(values);
        this.props.setRegionZone(this.getRegion(values.region).zone);
        this.props.totalAmountWithShipping(this.props.history);
    }

    openEmailClient() {
        window.location.href = "mailto:tienda@vercanminimizados.com?subject=Constar stock de producto/s";
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th><FormattedMessage id="amount.subtotal" /></th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    <tr key="shipping">
                        <th><FormattedMessage id="amount.shipping" /></th>
                        <td>
                        <FormattedMessage id="amount.shipping.message" />
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <FormattedMessage id="cart.checkout.tax.text" />
                        <Currency value={cart.tax} />
                    </tr>
                </tfoot>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;
        const stock = 0;
        
        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{item.product.stock > 0 ? `${item.product.name} × ${item.quantity}` : `${item.product.name} x`}
                    {!(item.product.stock > 0) &&  <Link to="#" onClick={() => this.openEmailClient()}>{"- Consultar disponibilidad"}</Link>}</td>
                <td><Currency value={item.total} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th><FormattedMessage id="checkout.products.label" /></th>
                        <th><FormattedMessage id="amount.total" /></th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th><FormattedMessage id="amount.total" /></th>
                        <td><Currency value={cart.total} /></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    render() {
        const { cart, me, shippingAddress, regions } = this.props;
        const { sameAddresses } = this.state;
        if (!me) {
            return (
                <SitePageAccessDenied />
            );
        } else {

            if (cart.items.length < 1) {
                return <Redirect to="cart" />;
            }

            const breadcrumb = [
                { title: 'Inicio', url: '/' },
                { title: <FormattedMessage id="checkout.breadcrumb.cart" />, url: '/carrito' },
                { title: <FormattedMessage id="checkout.breadcrumb.shippingAddress" />, url: '' },
            ];

            return (
                <React.Fragment>
                    <Helmet>
                        <title>{`Tramitar compra - Datos de envío — ${theme.name}`}</title>
                        <meta name="description" content={"Datos de envío y facturación. Primer paso para tramirar la compra"}></meta>
                    </Helmet>

                    <PageHeader header={""} breadcrumb={breadcrumb} />

                    <div className="checkout block">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    {!me &&
                                        <div className="alert alert-primary alert-lg">
                                            <FormattedMessage id="checkout.stay.as.guess" />
                                            {' '}
                                            <Link to="/acceso-registro">ACCESO / REGISTRO</Link>
                                        </div>
                                    }
                                </div>

                                {shippingAddress &&
                                    <div className="col-12 col-lg-6 col-xl-7">
                                        <div className="card mb-lg-0">
                                            <div className="card-body">

                                                <CheckoutForm address={shippingAddress} provinces={regions} onSubmit={this.onSubmit} sameAddresses={sameAddresses} handleChangeCheckBox={this.handleChangeCheckBox} />

                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <h3 className="card-title"><FormattedMessage id="checkout.yourorder.label" /></h3>
                                            {this.renderCart()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }

}


const mapStateToProps = (state) => ({
    cart: state.cart,
    modifiedAddress: state.cart.modifiedAddress,
    me: state.authApp.me,
    shippingAddress: state.cart.shippingAddress,
    regions: state.userApp.regions
});

const mapDispatchToProps = {
    buildShippingAddress,
    getRegions,
    setShippingAddress,
    totalAmountWithShipping,
    setRegionZone
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
