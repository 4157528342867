/**
* Auth Sagas
*/
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

// api
import * as api from '../../api';

import {
    REGISTER,
    CONFIRM_REGISTER,
    LOGIN_CLIENT,
    LOGOUT_CLIENT,
    ME_REQUEST,
    SEND_PASSWORD_RECOVERY_EMAIL,
    RECOVER_PASSWORD,
    SEND_REGISTER_CONFIRMATION_EMAIL
} from './authActionTypes';

import {
    registerSuccess,
    registerFailure,
    confirmRegisterSuccess,
    confirmRegisterFailure,
    signinClientSuccess,
    signinClientFailure,
    logoutClientSuccess,
    logoutClientFailure,
    meRequestSuccess,
    meRequestFailure,
    sendPasswordRecoveryEmailSuccess,
    sendPasswordRecoveryEmailFailure,
    recoverPasswordSuccess,
    recoverPasswordFailure,
    sendRegisterConfirmationEmailSuccess,
    sendRegisterConfirmationEmailFailure    
} from './authActions';

import {
    cleanCart
} from '../cart/cartActions';

function* fetchMe() {
    try {
        const me = yield call(api.auth.fetchMeRequest);
        yield put(meRequestSuccess(me));
    }
    catch (error) {
        yield put(meRequestFailure(error));
    }
}

function* registerFromServer({ payload }) {
    try {
        const { data, history } = payload;

        const response = yield call(api.auth.registerRequest, data);
        yield put(registerSuccess(response.user.email));
        history.push(`/confirmacion-envio-email-registro`);

    } catch (error) {
        yield put(registerFailure(error));
    }
};

function* confirmRegisterFromServer({ payload }) {
    try {
        const response = yield call(api.auth.confirmRegisterRequest, payload);
        yield put(confirmRegisterSuccess(response));

    } catch (error) {
        yield put(confirmRegisterFailure(error));
    }
};

function* signInClientWithEmailPassword({ payload }) {
    const { history } = payload;
    try {
        const signInUser = yield call(api.auth.signInClientWithEmailPasswordRequest, payload.user);
        localStorage.setItem('user_id', signInUser.token);
        yield put(signinClientSuccess(signInUser));

        // get me
        yield call(fetchMe);
        if (history) {
            history.push('/');
        } else {
            window.location.replace('/');
        }
       
    } catch (error) {
        yield put(signinClientFailure(error));
    }
}

function* signOutClientRequest() {
    try {
        localStorage.removeItem('user_id');
        window.location.replace('/acceso-registro');
        yield put(logoutClientSuccess());
        yield put(cleanCart());
        yield put(meRequestSuccess(null));

    } catch (error) {
        yield put(logoutClientFailure());
    }
}

function* sendPasswordRecoveryEmailRequest({ payload }) {
    try {
        yield call(api.auth.sendPasswordRecoveryEmailToServer, payload);
        yield put(sendPasswordRecoveryEmailSuccess());
    } catch (error) {
        yield put(sendPasswordRecoveryEmailFailure(error));
    }
}

function* sendRegisterConfirmationEmailRequest({ payload }) {
    try {
        yield call(api.auth.sendRegisterConfirmationEmailToServer, payload);
        yield put(sendRegisterConfirmationEmailSuccess());
    } catch (error) {
        yield put(sendRegisterConfirmationEmailFailure(error));
    }
}

function* recoverPasswordRequest({ payload }) {
    const { password, token } = payload;
    try {
        yield call(api.auth.sendRecoverPasswordRequestToServer, password, token);
        yield put(recoverPasswordSuccess());
    } catch (error) {
        yield put(recoverPasswordFailure(error));
    }
 }

export function* register() {
    yield takeEvery(REGISTER, registerFromServer);
}

export function* confirmRegister() {
    yield takeEvery(CONFIRM_REGISTER, confirmRegisterFromServer);
}

export function* signinClient() {
    yield takeEvery(LOGIN_CLIENT, signInClientWithEmailPassword);
}

export function* signOutClient() {
    yield takeEvery(LOGOUT_CLIENT, signOutClientRequest);
}

export function* meRequest() {
    yield takeEvery(ME_REQUEST, fetchMe);
}

export function* sendPasswordRecoveryEmail() {
    yield takeEvery(SEND_PASSWORD_RECOVERY_EMAIL, sendPasswordRecoveryEmailRequest);
}

export function* recoverPassword() {
    yield takeEvery(RECOVER_PASSWORD, recoverPasswordRequest);
}

export function* sendRegisterConfirmationEmail() {
    yield takeEvery(SEND_REGISTER_CONFIRMATION_EMAIL, sendRegisterConfirmationEmailRequest);
}

export default function* rootSaga() {
    yield all([
        fork(register),
        fork(confirmRegister),
        fork(signinClient),
        fork(signOutClient),
        fork(meRequest),
        fork(recoverPassword),
        fork(sendPasswordRecoveryEmail),
        fork(sendRegisterConfirmationEmail)
    ]);
}