/**
 * Register Form
 */
import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import FormInput from '../Form/FormInput';
import { FormattedMessage } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import { reviewStarts } from '../../util/Enums';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        reviewBy: Yup.string()
            .required('Debe introducir su nombre'),
        review: Yup.string()
            .required('Debe introducir su valoración'),

    }),
    mapPropsToValues: props => ({
        stars: 5, //default 5 rating
        review: props.productReview ? props.productReview.review : "",
        reviewBy: props.productReview ? props.productReview.reviewBy : "",
        product: props.productReview ? props.productReview.product: "",
    }),
    handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
        props.onSubmit(values);
        setSubmitting(false);
    },
    enableReinitialize: true
});


const ProductReviewForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = props;



    return (
        <Form onSubmit={handleSubmit}>
            <input type='hidden' name='product' id='product' value={props.product} />

            <h3 className="reviews-view__header mt-5"><FormattedMessage id="product.review.form.title" /></h3>
            <div className="row">
                <div className="col-12 col-lg-9 col-xl-8">
                    <div className="row">
                        <div className="col-md-3">
                            <FormControl>
                                <Label>{<FormattedMessage id="product.review.stars.label" />}</Label>
                                <Input autoComplete="new-password"
                                    className={values.stars && values.stars !== "" ? "select-with-label" : "empty-select select-with-label"}
                                    type="select" name="stars" id="stars" value={values.stars} onChange={handleChange("stars")}>
                              
                                    {reviewStarts.map((item, key) => (
                                        <option key={key} value={item.id}>{item.name}</option>

                                    ))}
                                </Input>
                            </FormControl>
                        </div>

                        <div className="col-md-9">
                            <FormattedMessage id="product.review.name.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="product.review.name.label" />}
                                        type="text"
                                        name="reviewBy"
                                        id="reviewBy"
                                        placeholder={placeholder}
                                        value={values.reviewBy}
                                        messageError={errors.reviewBy}
                                        invalid={errors.reviewBy && touched.reviewBy}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>
                        <div className="col-md-12 col-lg-12 mt-0">
                            <FormattedMessage id="product.review.text.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="product.review.text.label" />}
                                        type='textarea'
                                        name='review'
                                        id='review'
                                        placeholder={placeholder}
                                        value={values.review}
                                        messageError={errors.review}
                                        invalid={errors.review && touched.review}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                    {props.savingReview ?
                        <div className="buttons">
                            <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                                <FormattedMessage id="button.signin.label" />
                            </button>
                        </div>
                        :
                        <div className="buttons">
                            <button type="submit" className="btn btn-primary">
                                <FormattedMessage id="button.signin.label" />
                            </button>
                        </div>
                    }
                </div>
            </div>
        </Form>
    );
};

export default formikEnhancer(ProductReviewForm);
