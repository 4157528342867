// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import CategoryMenu from './CategoryMenu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs

//Otener grupos familia con productos
function getFamilyGroupsWithProducts(arr1, arr2) {
    const familyGroupsWithProducts = [];
    arr1.forEach((a1) => arr2.forEach((a2) => { if (a1._id === a2._id) { familyGroupsWithProducts.push(a2) } }));
    return familyGroupsWithProducts;
}

function getResult(arr1, familyId) {
    let result = false;
    if (arr1 && arr1.some(e => e.family === familyId)) {
        result = true;
    }
    return result;
}

function DepartmentsLinks(props) {

    let categoriesMenu = [];
    if (props.familiesWithFamilyGroups && props.familiesWithFamilyGroups.length > 0) {
        for (var itemFamily of props.familiesWithFamilyGroups) {
            //Obtener solo las familias con productos
            if (getResult(props.familyGroupsFilter, itemFamily._id)) {

                categoriesMenu.push({
                    _id: itemFamily._id,
                    title: itemFamily.name,
                    link: true,
                    submenu: { type: 'menu', menu: getFamilyGroupsWithProducts(props.familyGroupsFilter, itemFamily.familyGroups) }
                });
            }
        }
    }


    const linksList = categoriesMenu.map((categoriesMenu, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';

        if (categoriesMenu.submenu && categoriesMenu.submenu) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }

        if (categoriesMenu.submenu && categoriesMenu.submenu.type === 'menu') {
            itemClass = 'departments__item--menu';
            submenu = (
                <div className="departments__menu">
                    <CategoryMenu items={categoriesMenu.submenu.menu} />
                </div>
            );
        }

       /* if (categoriesMenu.submenu && categoriesMenu.submenu.type === 'megamenu') {
            submenu = (
                <div className={`departments__megamenu departments__megamenu--${categoriesMenu.submenu.menu.size}`}>
                    <Megamenu menu={categoriesMenu.submenu.menu} location="department" />
                </div>
            );
        }*/

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={"#"}>
                    {categoriesMenu.title}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="departments__links">
            {linksList}
        </ul>
    );
}

export default DepartmentsLinks;
