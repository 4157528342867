import {
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE,
    OPEN_FORM_ADDRESS,
    BUILD_EMPTY_ADDRESS,
    GET_REGIONS,
    GET_REGIONS_SUCCESS,
    GET_REGIONS_FAILURE,
    SET_USER_ADDRESS,
    SET_USER_ADDRESS_SUCCESS,
    SET_USER_ADDRESS_FAILURE,
    GET_USER_ORDERS,
    GET_USER_ORDERS_SUCCESS,
    GET_USER_ORDERS_FAILURE,
    SET_ORDERS_PAGE,
    SET_ORDER_DETAIL,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PASSWORD_SUCCESS,
    CHANGE_USER_PASSWORD_FAILURE,
    BUILD_EMPTY_PASSWORDS
} from './userActionTypes';
import { NotificationManager } from 'react-notifications';



const initialState = {
    loading: false,
    openFormAddress: false,
    address: null,
    regions: [],
    loadingRegions: false,
    savingAddress: false,
    loadingOrders: false,
    orders: [],
    total: null,
    ordersPageIndex: 1,
    orderDetail: {},
    loadingPassword: false,
    passwords: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case EDIT_USER:
            return { ...state, loading: true };
        case EDIT_USER_SUCCESS:
            NotificationManager.success('Datos modificados correctamente.');
            return { ...state, loading: false };
        case EDIT_USER_FAILURE:
            NotificationManager.error('Se ha producido un error durante la modificación de los datos.');
            return { ...state, loading: false };

        case OPEN_FORM_ADDRESS:
            return {
                ...state, openFormAddress: action.payload.open, address: action.payload.address && action.payload.address._id ? action.payload.address :
                    {
                        status: false,
                        name: "",
                        companyName: "",
                        phone: "",
                        address: "",
                        postalCode: undefined,
                        locality: "",
                        region: undefined,
                        additionalInformation: "",
                        nif: ""
                    }
            }

        case BUILD_EMPTY_ADDRESS:
            return {
                ...state, address: {
                    status: false,
                    name: "",
                    companyName: "",
                    phone: "",
                    address: "",
                    postalCode: undefined,
                    locality: "",
                    region: undefined,
                    additionalInformation: "",
                    nif: ""
                }
            }

        case GET_REGIONS:
            return { ...state, loadingRegions: true, regions: [] };
        case GET_REGIONS_SUCCESS:
            return { ...state, loadingRegions: false, regions: action.payload };
        case GET_REGIONS_FAILURE:
            return { ...state, loadingRegions: false };

        case SET_USER_ADDRESS:
            return { ...state, savingAddress: true };
        case SET_USER_ADDRESS_SUCCESS:
            return { ...state, savingAddress: false, openFormAddress: false };
        case SET_USER_ADDRESS_FAILURE:
            NotificationManager.error('Se ha producido un error durante la creación de los datos.');
            return { ...state, savingAddress: false };

        case GET_USER_ORDERS:
            return { ...state, loadingOrders: true };
        case GET_USER_ORDERS_SUCCESS:
            return { ...state, loadingOrders: false, orders: action.payload.orders, total: action.payload.total };
        case GET_USER_ORDERS_FAILURE:
            NotificationManager.error('Se ha producido un error durante al obtener los pedidos.');
            return { ...state, loadingOrders: false };

        case SET_ORDERS_PAGE:
            return { ...state, loading: false, ordersPageIndex: action.payload };

        case SET_ORDER_DETAIL:
            return { ...state, loading: false, orderDetail: action.payload ? action.payload : {} };

        case CHANGE_USER_PASSWORD:
            return {
                ...state, loadingPassword: true
            };
        case CHANGE_USER_PASSWORD_SUCCESS:
            NotificationManager.success('Contraseña actualizada');
            return {
                ...state, loadingPassword: false,
                passwords: {
                    password: "",
                    passwordConfirmation: ""
                }
            };
        case CHANGE_USER_PASSWORD_FAILURE:
            NotificationManager.error('Se ha producido un error durante el cambio de contraseña.');
            return { ...state, loadingPassword: false };

        case BUILD_EMPTY_PASSWORDS:
            return {
                ...state, passwords: {
                    password: "",
                    passwordConfirmation: ""
                }
            }
        default:
            return { ...state };
    }
}

export default userReducer;
