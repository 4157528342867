import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM } from './wishlistActionTypes';
import { NotificationManager } from 'react-notifications';


function addItem(state, product) {
    const itemIndex = state.findIndex((x) => x._id === product._id);

    if (itemIndex === -1) {
        return [
            ...state,
            JSON.parse(JSON.stringify(product)),
        ];
    }

    return state;
}

const initialState = [];

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case WISHLIST_ADD_ITEM:
            NotificationManager.success('Producto añadido a la lista de deseos');
            return addItem(state, action.payload);

        case WISHLIST_REMOVE_ITEM:
            return state.filter((x) => x._id !== action.payload);

        default:
            return state;
    }
}
