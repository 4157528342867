// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import IndicatorLoginForm from './IndicatorLoginForm';


export default function IndicatorAccount(props) {

    const { user, onSubmit, signinData, loadingSignin, onLogout, isMobile } = props;

    const dropdown = (
        <div className="account-menu">
            {user && user._id ?
                <div>
                    <div className="account-menu__divider" />
                    <Link to="/mi-cuenta/editar-perfil" className="account-menu__user">
                        {/*<div className="account-menu__user-avatar">
                            <img src="images/avatars/avatar-3.jpg" alt="" />
                        </div>*/}
                        <div className="account-menu__user-info">
                            <div className="account-menu__user-name">{user.name ? user.name : ""}</div>
                            <div className="account-menu__user-email">{user.email}</div>
                        </div>
                    </Link>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li><Link to="/mi-cuenta/editar-perfil"><FormattedMessage id="home.account.edit" /></Link></li>
                        <li><Link to="/mi-cuenta/historial-pedidos"><FormattedMessage id="home.account.history" /></Link></li>
                        <li><Link to="/mi-cuenta/direcciones"><FormattedMessage id="home.account.address" /></Link></li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li><Link to="#" onClick={() => onLogout()}><FormattedMessage id="home.account.logout" /></Link></li>
                    </ul>
                </div>
                :
                <div>
                    <div className="account-menu__form-title"><FormattedMessage id="home.account.login" /></div>
                    {<IndicatorLoginForm
                        signinData={signinData}
                        onSubmit={onSubmit}
                        loading={loadingSignin}
                        isMobile={isMobile}
                    />}
                </div>
            }

        </div>
    );

    return (
        <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />
    );
}
