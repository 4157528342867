// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import theme from '../../data/theme';

// application
import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';
import {
    Filters16Svg,
    LayoutGrid16x16Svg,
    LayoutGridWithDetails16x16Svg,
    LayoutList16x16Svg,
} from '../../svg';
import { sidebarOpen } from '../../store/sidebar';
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';
import { setProductsPage, getProducts } from '../../store/products';


class ProductsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
        };
    }

    setLayout = (layout) => {
        this.setState(() => ({ layout }));
    };

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
        this.props.setProductsPage(page);
        this.props.getProducts();

    };

    render() {
        const {
            products,
            total,
            grid,
            offcanvas,
            layout: propsLayout,
            sidebarOpen,
            onAddCartClick,
            adding,
            viewClick,
            pageSize,
            pageIndex,
            onChangeOrderBy,
            sortByField,
            onAddWishListClick
        } = this.props;

        let metaDescription = "Búsqueda avanzada de productos"
            if (products.length > 0) {
                if (products[0].family) {
                    metaDescription += " - "+products[0].family.name
                }
                if (products[0].familyGroup) {
                    metaDescription += " - "+products[0].familyGroup.name
                }
                if (products[0].group) {
                    metaDescription += " - "+products[0].group.name
                }
                if(metaDescription.length > 160) {
                metaDescription = metaDescription.substring(0,160)}
            }

        const { page, layout: stateLayout } = this.state;
        const layout = stateLayout || propsLayout;

        let viewModes = [
            { key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> },
            { key: 'grid-with-features', title: 'Grid With Features', icon: <LayoutGridWithDetails16x16Svg /> },
            { key: 'list', title: 'List', icon: <LayoutList16x16Svg /> },
        ];

        viewModes = viewModes.map((viewMode) => {
            const className = classNames('layout-switcher__button', {
                'layout-switcher__button--active': layout === viewMode.key,
            });

            return (
                <button
                    key={viewMode.key}
                    title={viewMode.title}
                    type="button"
                    className={className}
                    onClick={() => this.setLayout(viewMode.key)}
                >
                    {viewMode.icon}
                </button>
            );
        });


        const viewOptionsClasses = classNames('view-options', {
            'view-options--offcanvas--always': offcanvas === 'always',
            'view-options--offcanvas--mobile': offcanvas === 'mobile',
        });

        return (
            <div className="products-view">
                <Helmet>
                    <title>{`Búsqueda de productos — ${theme.name}`}</title>
                    <meta name="description" content={metaDescription}></meta>
                </Helmet>
                <div className="products-view__options">
                    <div className={viewOptionsClasses}>
                        <div className="view-options__filters-button">
                            <button type="button" className="filters-button" onClick={() => sidebarOpen()}>
                                <Filters16Svg className="filters-button__icon" />
                                <span className="filters-button__title">Filters</span>
                                <span className="filters-button__counter">3</span>
                            </button>
                        </div>
                        <div className="view-options__layout">
                            <div className="layout-switcher">
                                <div className="layout-switcher__list">
                                    {viewModes}
                                </div>
                            </div>
                        </div>
                        <div className="view-options__legend">{total} resultados</div>
                        <div className="view-options__divider" />
                        <div className="view-options__control">
                            <label htmlFor="view-options-sort">Ordenar por</label>
                            <div>

                                <select className="form-control form-control-sm" value={sortByField} name="" id="view-options-sort" onChange={(field) => {
                                    onChangeOrderBy(field.target.value);
                                }}>
                                    <option value="">-</option>
                                    <option value="rating">Valoración</option>
                                    <option value="name">Nombre (A-Z)</option>
                                    <option value="pricelt">Precio ascendente</option>
                                    <option value="pricegt">Precio descendente</option>
                                </select>
                            </div>
                        </div>
                        {/*<div className="view-options__control">
                            <label htmlFor="view-options-limit">Show</label>
                            <div>
                                <select className="form-control form-control-sm" name="" id="view-options-limit">
                                    <option value="">12</option>
                                    <option value="">24</option>
                                </select>
                            </div>
                        </div>*/}
                    </div>
                </div>

                <div
                    className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
                >
                    <div className="products-list__body">

                        {products.map((product) => (
                            <div key={product._id} className="products-list__item">
                                <ProductCard product={product} onAddCartClick={onAddCartClick} onAddWishListClick={onAddWishListClick} viewClick={viewClick} adding={adding} />
                            </div>
                        ))}
                    </div>
                </div>
                {<div className="products-view__pagination">
                    <Pagination
                        current={pageIndex}
                        siblings={2}
                        total={Math.ceil((total ) / (pageSize - 1)) }
                        onPageChange={this.handlePageChange}
                    />
                </div>}
            </div>
        );
    }
}

ProductsView.propTypes = {
    /**
     * array of product objects
     */
    products: PropTypes.array,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
    viewClick: PropTypes.func.isRequired,
    onAddCartClick: PropTypes.func.isRequired
};

ProductsView.defaultProps = {
    products: [],
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};

const mapDispatchToProps = {
    sidebarOpen,
    setProductsPage,
    getProducts
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(ProductsView);
