// eslint-disable-next-line import/prefer-default-export
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const OPEN_FORM_ADDRESS = 'OPEN_FORM_ADDRESS';
export const BUILD_EMPTY_ADDRESS = 'BUILD_EMPTY_ADDRESS';
export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE';

export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const SET_USER_ADDRESS_SUCCESS = 'SET_USER_ADDRESS_SUCCESS';
export const SET_USER_ADDRESS_FAILURE = 'SET_USER_ADDRESS_FAILURE';

export const GET_USER_ORDERS = 'GET_USER_ORDERS';
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS';
export const GET_USER_ORDERS_FAILURE = 'GET_USER_ORDERS_FAILURE';

export const SET_ORDERS_PAGE = 'SET_ORDERS_PAGE';
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE';

export const BUILD_EMPTY_PASSWORDS = 'BUILD_EMPTY_PASSWORDS';