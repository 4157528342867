/**
 * Login Form
 */
import React from 'react';

import { withFormik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../Form/FormInput';
import { FormattedMessage } from 'react-intl';
import { Form, Label, Input, FormGroup } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { bool } from 'prop-types';
import { validateIdentityDocument } from "../../validators/IdentityDocumentValidator";


const formikEnhancer = withFormik({
    validationSchema: (props) => {
        Yup.addMethod(Yup.string, 'nifValidation', function (message) {
            return this.test('nifValidation', message, function (value) {
                const { path, createError } = this;
                const document = value;
                let documentOk = validateIdentityDocument(document);
                if (!value) {
                    return true;
                }
                if (!documentOk) {
                    return createError({ path, message: 'El documento de identidad no es correcto' });
                } else {
                    return true;
                }
            });
        });
        if (props.sameAddresses) {
            return Yup.object().shape({
                name: Yup.string()
                    .required('Debe indicar el nombre'),
                address: Yup.string()
                    .required('Debe indicar una dirección'),
                postalCode: Yup.number()
                    .required('Debe indicar el código postal'),
                locality: Yup.string()
                    .required('Debe indicar la localidad'),
                region: Yup.string()
                    .required('Debe indicar la provincia'),
                phone: Yup.string()
                    .matches(/^$|^(\\+34|0034|34)?[\\s|\\-|\\.]?[6|7|8|9][\\s|\\-|\\.]?([0-9][\\s|\\-|\\.]?){8}$/, "El teléfono no es correcto"),
                nif: Yup.string()
                    .nifValidation(Yup.string(), 'El NIF no es correcto'),
            });
        } else {
            return Yup.object().shape({
                name: Yup.string()
                    .required('Debe indicar el nombre'),
                address: Yup.string()
                    .required('Debe indicar una dirección'),
                postalCode: Yup.number()
                    .required('Debe indicar el código postal'),
                locality: Yup.string()
                    .required('Debe indicar la localidad'),
                region: Yup.string()
                    .required('Debe indicar la provincia'),
                phone: Yup.string()
                    .matches(/^$|^(\\+34|0034|34)?[\\s|\\-|\\.]?[6|7|8|9][\\s|\\-|\\.]?([0-9][\\s|\\-|\\.]?){8}$/, "El teléfono no es correcto"),
                nif: Yup.string()
                    .nifValidation(Yup.string(), 'El NIF no es correcto'),
                billingName: Yup.string()
                    .required('Debe indicar el nombre'),
                billingFullAddress: Yup.string()
                    .required('Debe indicar una dirección'),
                billingPostalCode: Yup.number()
                    .required('Debe indicar el código postal'),
                billingLocality: Yup.string()
                    .required('Debe indicar la localidad'),
                billingRegion: Yup.string()
                    .required('Debe indicar la provincia'),
                billingPhone: Yup.string()
                    .matches(/^$|^(\\+34|0034|34)?[\\s|\\-|\\.]?[6|7|8|9][\\s|\\-|\\.]?([0-9][\\s|\\-|\\.]?){8}$/, "El teléfono no es correcto"),
                billingNif: Yup.string()
                    .nifValidation(Yup.string(), 'El NIF no es correcto'),

            });
        }

    },
    mapPropsToValues: props => ({
        _id: props.address._id,
        status: props.address.status,
        companyName: props.address.companyName,
        phone: props.address.phone,
        nif: props.address.nif,
        email: props.address.email,
        address: props.address.address,
        postalCode: props.address.postalCode,
        locality: props.address.locality,
        region: props.address.region,
        additionalInformation: props.address.additionalInformation,
        name: props.address.name,
        //Billing
        billingCompanyName: props.address.billingAddress.companyName,
        billingPhone: props.address.billingAddress.phone,
        billingFullAddress: props.address.billingAddress.address,
        billingPostalCode: props.address.billingAddress.postalCode,
        billingLocality: props.address.billingAddress.locality,
        billingRegion: props.address.billingAddress.region,
        billingAdditionalInformation: props.address.billingAddress.additionalInformation,
        billingName: props.address.billingAddress.name,
        billingNif: props.address.billingAddress.nif,

    }),
    handleSubmit: async (values, { props, setSubmitting }) => {

        if (props.sameAddresses) {
            values.billingAddress = {
                name: values.name,
                companyName: values.companyName,
                phone: values.phone,
                address: values.address,
                postalCode: values.postalCode,
                locality: values.locality,
                region: values.region,
                additionalInformation: values.additionalInformation,
                nif: values.nif,
            }
        } else {
            values.billingAddress = {
                name: values.billingName,
                companyName: values.billingCompanyName,
                phone: values.billingPhone,
                address: values.billingFullAddress,
                postalCode: values.billingPostalCode,
                locality: values.billingLocality,
                region: values.billingRegion,
                additionalInformation: values.billingAdditionalInformation,
                nif: values.billingNif,
            }
        }

        delete values.billingName;
        delete values.billingCompanyName;
        delete values.billingPhone;
        delete values.billingFullAddress;
        delete values.billingPostalCode;
        delete values.billingLocality;
        delete values.billingRegion;
        delete values.billingNif;

        props.onSubmit(values);
        setSubmitting(false);
    },
    enableReinitialize: true
});


const CheckoutForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        onBackPress
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <h3 className="card-title"><FormattedMessage id="checkout.shipping.data" /></h3>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="checkout.user.name.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="checkout.user.name.label" />}
                                type="text"
                                name="name"
                                id="name"
                                placeholder={placeholder}
                                value={values.name}
                                messageError={errors.name}
                                invalid={errors.name && touched.name}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <FormattedMessage id="edit.profile.nif.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="edit.profile.nif.label" />}
                                type='text'
                                name='nif'
                                id='nif'
                                placeholder={placeholder}
                                value={values.nif}
                                messageError={errors.nif}
                                invalid={errors.nif && touched.nif}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <FormattedMessage id="checkout.phone.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="checkout.phone.label" />}
                                type='text'
                                name='phone'
                                id='phone'
                                placeholder={placeholder}
                                value={values.phone}
                                messageError={errors.phone}
                                invalid={errors.phone && touched.phone}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="checkout.companyName.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="checkout.companyName.label" />}
                                type="text"
                                name="companyName"
                                id="companyName"
                                placeholder={placeholder}
                                value={values.companyName}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="checkout.streetAddress.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="checkout.streetAddress.label" />}
                                type='textarea'
                                name='address'
                                id='address'
                                placeholder={placeholder}
                                value={values.address}
                                messageError={errors.address}
                                invalid={errors.address && touched.address}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-5 col-lg-5">
                    <FormattedMessage id="checkout.locality.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="checkout.locality.placeholder" />}
                                type="text"
                                name="locality"
                                id="locality"
                                placeholder={placeholder}
                                value={values.locality}
                                messageError={errors.locality}
                                invalid={errors.locality && touched.locality}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4">
                    <FormGroup error={errors.region && touched.region}>
                        <Label>{<FormattedMessage id="checkout.region.label" />}</Label>
                        <Input invalid={errors.region && touched.region} className={values.region && values.region !== "" ? "select-with-label" : "select-with-label empty-select"} type="select" name="region" id="region" value={values.region} onChange={handleChange("region")}>
                            <FormattedMessage id="checkout.region.placeholder">
                                {placeholder =>
                                    <option key="" value="">{placeholder}</option>
                                }
                            </FormattedMessage>
                            {props.provinces && props.provinces.map((item, key) => (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            ))}
                        </Input>
                        {errors.region && touched.region &&
                            <div className="input-error">{errors.region}</div>
                        }
                    </FormGroup>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3">
                    <FormGroup error={errors.postalCode && touched.postalCode}>
                        <Label for="postalCode"><FormattedMessage id="checkout.postalcode.label" /></Label>
                        <FormattedMessage id="checkout.postalcode.placeholder">
                            {placeholder =>
                                <NumberFormat autoComplete="new-password"
                                    id="postalCode"
                                    name="postalCode"
                                    placeholder={placeholder}
                                    value={values.postalCode}
                                    isNumericString={true}
                                    thousandSeparator={""}
                                    decimalSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                    onValueChange={vals => setFieldValue('postalCode', vals.formattedValue)}
                                    className={errors.postalCode && touched.postalCode ? "form-control custom-input-error" : "form-control"}
                                />
                            }
                        </FormattedMessage>
                        {errors.postalCode && touched.postalCode &&
                            <div className="input-error">{errors.postalCode}</div>
                        }
                    </FormGroup>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormattedMessage id="checkout.additionalInformation.placeholder">
                        {placeholder =>
                            <FormInput
                                label={<FormattedMessage id="checkout.additionalInformation.label" />}
                                type='textarea'
                                name='additionalInformation'
                                id='additionalInformation'
                                placeholder={placeholder}
                                value={values.additionalInformation}
                                onChange={handleChange} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <input id="sameAddressCheck" type="checkbox" defaultChecked={props.sameAddresses} onChange={props.handleChangeCheckBox} />
            <label htmlFor="sameAddressCheck">  Los datos de facturación y de envío son los mismos. </label>

            {!props.sameAddresses &&
                <div className="mt-3">
                    <h3 className="card-title"><FormattedMessage id="checkout.billing.data" /></h3>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <FormattedMessage id="checkout.user.name.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="checkout.user.name.label" />}
                                        type="text"
                                        name="billingName"
                                        id="billingName"
                                        placeholder={placeholder}
                                        value={values.billingName}
                                        messageError={errors.billingName}
                                        invalid={errors.billingName && touched.billingName}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <FormattedMessage id="edit.profile.nif.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="edit.profile.nif.label" />}
                                        type='text'
                                        name='billingNif'
                                        id='billingNif'
                                        placeholder={placeholder}
                                        value={values.billingNif}
                                        messageError={errors.billingNif}
                                        invalid={errors.billingNif && touched.billingNif}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>

                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <FormattedMessage id="checkout.phone.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="checkout.phone.label" />}
                                        type='text'
                                        name='billingPhone'
                                        id='billingPhone'
                                        placeholder={placeholder}
                                        value={values.billingPhone}
                                        messageError={errors.billingPhone}
                                        invalid={errors.billingPhone && touched.billingPhone}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <FormattedMessage id="checkout.companyName.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="checkout.companyName.label" />}
                                        type="text"
                                        name="billingCompanyName"
                                        id="billingCompanyName"
                                        placeholder={placeholder}
                                        value={values.billingCompanyName}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <FormattedMessage id="checkout.streetAddress.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="checkout.streetAddress.label" />}
                                        type='textarea'
                                        name='billingFullAddress'
                                        id='billingFullAddress'
                                        placeholder={placeholder}
                                        value={values.billingFullAddress}
                                        messageError={errors.billingFullAddress}
                                        invalid={errors.billingFullAddress && touched.billingFullAddress}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            <FormattedMessage id="checkout.locality.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="checkout.locality.placeholder" />}
                                        type="text"
                                        name="billingLocality"
                                        id="billingLocality"
                                        placeholder={placeholder}
                                        value={values.billingLocality}
                                        messageError={errors.billingLocality}
                                        invalid={errors.billingLocality && touched.billingLocality}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>

                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <FormGroup error={errors.billingRegion && touched.billingRegion}>
                                <Label>{<FormattedMessage id="checkout.region.label" />}</Label>
                                <Input invalid={errors.billingRegion && touched.billingRegion} className={values.billingRegion && values.billingRegion !== "" ? "select-with-label" : "select-with-label empty-select"} type="select" name="billingRegion" id="billingRegion" value={values.billingRegion} onChange={handleChange("billingRegion")}>
                                    <FormattedMessage id="checkout.region.placeholder">
                                        {placeholder =>
                                            <option key="" value="">{placeholder}</option>
                                        }
                                    </FormattedMessage>
                                    {props.provinces && props.provinces.map((item, key) => (
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    ))}
                                </Input>
                                {errors.billingRegion && touched.billingRegion &&
                                    <div className="input-error">{errors.billingRegion}</div>
                                }
                            </FormGroup>
                        </div>

                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <FormGroup error={errors.billingPostalCode && touched.billingPostalCode}>
                                <Label for="postalCode"><FormattedMessage id="checkout.postalcode.label" /></Label>
                                <FormattedMessage id="checkout.postalcode.placeholder">
                                    {placeholder =>
                                        <NumberFormat autoComplete="new-password"
                                            id="billingPostalCode"
                                            name="billingPostalCode"
                                            placeholder={placeholder}
                                            value={values.billingPostalCode}
                                            isNumericString={true}
                                            thousandSeparator={""}
                                            decimalSeparator={false}
                                            allowNegative={false}
                                            allowLeadingZeros={true}
                                            onValueChange={vals => setFieldValue('billingPostalCode', vals.formattedValue)}
                                            className={errors.billingPostalCode && touched.billingPostalCode ? "form-control custom-input-error" : "form-control"}
                                        />
                                    }
                                </FormattedMessage>
                                {errors.billingPostalCode && touched.billingPostalCode &&
                                    <div className="input-error">{errors.billingPostalCode}</div>
                                }
                            </FormGroup>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <FormattedMessage id="checkout.additionalInformation.placeholder">
                                {placeholder =>
                                    <FormInput
                                        label={<FormattedMessage id="checkout.additionalInformation.label" />}
                                        type='textarea'
                                        name='billingAdditionalInformation'
                                        id='billingAdditionalInformation'
                                        placeholder={placeholder}
                                        value={values.billingAdditionalInformation}
                                        onChange={handleChange} />
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
            }


            {props.loading ?
                <div className="buttons">
                    <button type="submit" className="btn btn-primary btn-loading" disabled={true}>
                        <FormattedMessage id="checkout.button.submit" />
                    </button>
                </div>
                :
                <div className="buttons">
                    <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="checkout.button.submit" />
                    </button>
                </div>
            }
        </Form>
    );
};

export default formikEnhancer(CheckoutForm);
