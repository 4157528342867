// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, cartUpdateQuantities } from '../../store/cart';
import { Cross12Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';


class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
        };
    };

    onUpdateProductQuantities = (quantities) => {
        this.props.cartUpdateQuantities(quantities);
    };

    onRemoveProduct = (itemId) => {
        this.props.cartRemoveItem(itemId);
    };

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity) => {
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return quantities.filter((x) => {
            const item = cart.items.find((item) => item.id === x.itemId);

            return item && item.quantity !== x.value;
        }).length > 0;
    }

    renderItems() {
        const { cart, cartRemoveItem } = this.props;

        return cart.items.map((item) => {
            let image;
            let options;

            if (item.product.pictures.length > 0) {
                image = <Link to={`/producto/${item.product._id}`}><img src={item.product.pictures[0].value} alt="" /></Link>;
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <button type="button" onClick={() => this.onRemoveProduct(item.id)} className="btn btn-light btn-sm btn-svg-icon">
                    <Cross12Svg />
                </button>
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={`/producto/${item.product._id}`} className="cart-table__product-name">
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        <Currency value={item.price} />
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="minimumQuantity">
                          {item.product.minimumQuantity > 0 ? item.product.minimumQuantity + " unidades": "-" }
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
                            value={this.getItemQuantity(item)}
                            min={item.product.minimumQuantity > 0 ? item.product.minimumQuantity : 1}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={item.total} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                        {removeButton}
                    </td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th><FormattedMessage id="amount.subtotal" /></th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">
                    <tr>
                        <th><FormattedMessage id="amount.shipping" /></th>
                        <td>
                        <FormattedMessage id="amount.shipping.message" />
                        </td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, cartUpdateQuantities, me } = this.props;
        const { quantities } = this.state;

        const updateCartButton = (
            <button type="button" onClick={() => this.onUpdateProductQuantities(quantities)} className="btn btn-primary cart__update-button" disabled={!this.cartNeedUpdate()}>
                <FormattedMessage id="cart.update.cart" />
            </button>

        );

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image"></th>
                                <th className="cart-table__column cart-table__column--product"><FormattedMessage id="cart.table.product" /></th>
                                <th className="cart-table__column cart-table__column--price"><FormattedMessage id="cart.table.price" /></th>
                                <th className="cart-table__column cart-table__column--price"><FormattedMessage id="cart.table.minimumQuantity" /></th>
                                <th className="cart-table__column cart-table__column--quantity"><FormattedMessage id="cart.table.quantity" /></th>
                                <th className="cart-table__column cart-table__column--total"><FormattedMessage id="cart.table.total" /></th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>
                    <div className="cart__actions">
                        <div></div>
                        <div className="cart__buttons">
                            <Link to="/" className="btn btn-light"><FormattedMessage id="cart.continue.shopping" /></Link>
                            {updateCartButton}
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title"><FormattedMessage id="cart.totals" /></h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot>
                                            <tr>
                                                <FormattedMessage id="cart.tax.text" />
                                                <Currency value={cart.tax} />)

                                            </tr>
                                        </tfoot>
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th><FormattedMessage id="amount.total" /></th>
                                                <td><Currency value={cart.total} /></td>

                                            </tr>
                                        </tfoot>
                                    </table>
                                    {!me ?
                                        <Link to="/acceso-registro" className="btn btn-primary btn-xl btn-block cart__checkout-button">
                                            <FormattedMessage id="cart.login.required" />
                                        </Link>
                                        : <Link to="/tramitar-comprar/datos-envio" className="btn btn-primary btn-xl btn-block cart__checkout-button">
                                            <FormattedMessage id="cart.proceed.checkout" />
                                        </Link>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: 'Inicio', url: '/' },
            { title: 'carrito', url: '' },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message"><FormattedMessage id="indicator.cart.empty" /></div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm"><FormattedMessage id="cart.go.home" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`TU CARRITO — ${theme.name}`}</title>
                    <meta name="description" content={"Modificar cantiades de productos o proceder con la tramitación de la compra"}></meta>
                </Helmet>

                <PageHeader header="TU CARRITO" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    me: state.authApp.me
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
