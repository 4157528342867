/* eslint-disable no-restricted-globals */
// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import { Check9x7Svg } from '../../svg';

class FilterCheckbox extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        const { items, onSelect, selectedItems } = this.props;

        const itemsList = items.map((item) => {
            let count;

            if (item.total) {
                count = <span className="filter-list__counter">{item.total}</span>;
            }

            return (
                <label
                    key={item._id}
                    className={classNames('filter-list__item', {
                        'filter-list__item--disabled': item.disabled,
                    })}
                >
                    <span className="filter-list__input input-check">
                        <span className="input-check__body">
                                <input checked={selectedItems ? selectedItems.includes(item._id): null} className="input-check__input" type="checkbox" onChange={() => onSelect(item._id, event.target.checked)}/>
                            <span className="input-check__box" />
                            <Check9x7Svg className="input-check__icon" />
                        </span>
                    </span>
                    <span className="filter-list__title">{item.name}</span>
                    {count}
                </label>
            );
        });

        return (
            <div className="filter-list">
                <div className="filter-list__list">
                    {itemsList}
                </div>
            </div>
        );
    }
}

FilterCheckbox.propTypes = {
    onSelect: PropTypes.func.isRequired,
    items: PropTypes.array,

};

export default FilterCheckbox;