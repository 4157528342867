import {
    GET_FAMILY_WITH_FAMILYGROUPS,
    GET_FAMILY_WITH_FAMILYGROUPS_SUCCESS,
    GET_FAMILY_WITH_FAMILYGROUPS_FAILURE,
} from './familyGroupsActionTypes';


const initialState = {
    familiesWithFamilyGroups: [],
};

const familyGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case GET_FAMILY_WITH_FAMILYGROUPS:
            return { ...state };
        case GET_FAMILY_WITH_FAMILYGROUPS_SUCCESS:
            return { ...state, familiesWithFamilyGroups: action.payload };
        case GET_FAMILY_WITH_FAMILYGROUPS_FAILURE:
            return { ...state };

        default:
            return { ...state };
    }
}

export default familyGroupsReducer;
