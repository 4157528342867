// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import PropTypes from 'prop-types';

// application
import { getFeaturedProducts, getGroups } from '../../store/products';
import { quickviewOpenSuccess } from '../../store/quickview';
import { cartAddItemSuccess } from '../../store/cart';
import { wishlistAddItem } from '../../store/wishlist';
// data stubs
import BlockProductsCarousel from './BlockProductsCarousel';

class BlockTabbedProductsCarousel extends Component {
    timeout;

    constructor(props) {
        super(props);
        this.groups = [
            { id: 1, name: 'Todos', current: true }
        ];
        this.state = {
            loading: false
        };
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleChangeGroup = (newCurrentGroup) => {
        clearTimeout(this.timeout);

        const groups = this.groups;
        const currentGroup = groups.find((group) => group.current);

        if (currentGroup && currentGroup.id === newCurrentGroup.id) {
            return;
        }

        this.setState((state) => (
            this.groups = this.groups.map((group) => (
                { ...group, current: group.id === newCurrentGroup.id }
            ))
        ));
        this.props.getFeaturedProducts(newCurrentGroup.id);
    };

    viewClick = (product) => {
        this.props.quickviewOpenSuccess(product);
    };

    onAddCartClick = (product) => {
        this.props.cartAddItemSuccess(product);
    };

    onAddWishListClick = (product) => {
        this.props.wishlistAddItem(product);
    };

    componentWillMount() {
        this.props.getFeaturedProducts();
        this.props.getGroups();
        const groups = this.props.groups;
        /*if (groups && groups.length > 0) {
            groups.map((group) => {
                let name = group.name[0] + group.name.slice(1).toLowerCase();
                this.groups.push({ id: group._id, name: name, current: false })
            })
        }*/
    }

    render() {
        const { featuredProducts } = this.props;
        return (
            <BlockProductsCarousel
                {...this.props}
                {...this.state}
                products={featuredProducts}
                groups={this.groups}
                onGroupClick={this.handleChangeGroup}
                viewClick={this.viewClick}
                onAddCartClick={this.onAddCartClick}
                onAddWishListClick={this.onAddWishListClick}
            />
        );
    }
}

BlockTabbedProductsCarousel.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    withSidebar: PropTypes.bool,
};

BlockTabbedProductsCarousel.defaultProps = {
    layout: 'grid-4',
    rows: 1,
    withSidebar: false,
};

const mapStateToProps = ({ productsApp, settings }) => {
    const { featuredProducts, groups } = productsApp;
    return {
        featuredProducts,
        groups,
        settings
    };
};

const mapDispatchToProps = {
    getFeaturedProducts,
    getGroups,
    quickviewOpenSuccess,
    cartAddItemSuccess,
    wishlistAddItem
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockTabbedProductsCarousel);