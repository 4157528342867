/*export default [
   {
        title: 'home.home',
        url: '/',
    },
    {
        title: 'home.menu',
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Equipos de soldadura',
                                url: '',
                                links: [
                                    { title: 'Soldadura por electrodo', url: '' },
                                    { title: 'Soldadura MIG', url: '' },
                                    { title: 'Soldadura TIG', url: '' },
                                ],
                            },
                            {
                                title: 'Accesorios',
                                url: '',
                                links: [
                                    { title: 'Fungibles', url: '' },
                                    { title: 'Monitores de rendimiento', url: '' },
                                    { title: 'Generadores', url: '' },
                                    { title: 'Alicates', url: '' },
                                    { title: 'Máscaras', url: '' },
                                    { title: 'Destornilladores', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Devanadores',
                                url: '',
                                links: [
                                    { title: 'Devanadores automáticos', url: '' },
                                    { title: 'Devanadores semiautomáticos', url: '' }
                                ],
                            },
                            {
                                title: 'Equipos multiprocesos',
                                url: '',
                                links: [
                                    { title: 'Equipos de corte de plasma', url: '' },
                                    { title: 'Equipos para Arco Sumergido', url: '' },
                                    { title: 'Extracción de humos', url: '' },
                                    { title: 'Equipo de Formación', url: '' },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        title: 'home.shop',
        url: '/shop/category-grid-3-columns-sidebar',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'home.shop.grid',
                    url: '/shop/category-grid-3-columns-sidebar',
                },
                { title: 'home.shop.list', url: '/shop/all-products' },
                {
                    title: 'Product',
                    url: '/shop/product-standard',
                    submenu: [
                        { title: 'Product', url: '/shop/product-standard' },
                        { title: 'Product Alt', url: '/shop/product-columnar' },
                        { title: 'Product Sidebar', url: '/shop/product-sidebar' },
                    ],
                },
                { title: 'home.shop.cart', url: '/carrito' },
                { title: 'home.shop.checkout', url: '/shop/checkout' },
                { title: 'home.shop.wishlist', url: '/lista-de-deseos' },
                { title: 'home.shop.compare', url: '/shop/compare' },
                { title: 'home.shop.track', url: '/shop/track-order' },
            ],
        },
    },
    {
        title: 'home.account',
        url: '/account',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'home.account.login', url: '/acceso-registro' },
                { title: 'home.account.dashboard', url: '/account/dashboard' },
                { title: 'home.account.edit', url: '/mi-cuenta/editar-perfil' },
                { title: 'home.account.history', url: '/account/orders' },
                { title: 'home.account.details', url: '/account/orders/5' },
                { title: 'home.account.address', url: '/account/addresses' },
                { title: 'home.account.address.edit', url: '/account/addresses/5' },
                { title: 'home.account.changePwd', url: '/account/password' },
            ],
        },
    },
    {
        title: 'home.blog',
        url: '/blog/category-classic',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'home.blog.classic', url: '/blog/category-classic' },
                { title: 'home.blog.grid', url: '/blog/category-grid' },
                { title: 'home.blog.list', url: '/blog/busqueda-avanzada' },
               
                { title: 'Post Page', url: '/blog/post-classic' },
                { title: 'Post Without Sidebar', url: '/blog/post-full' },
            ],
        },
    },
    {
        title: 'home.contact',
        url: '/site/about-us',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'home.contact.about', url: '/site/about-us' },
                { title: 'home.contact', url: '/site/contact-us-alt' },
                { title: '404', url: '/site/not-found' },
                { title: 'home.contact.terms', url: '/terminos-y-condiciones' },
                { title: 'FAQ', url: '/site/faq' },
                { title: 'Components', url: '/site/components' },
                { title: 'Typography', url: '/site/typography' },
            ],
        },
    },
];*/

export default [
   {
        title: 'home.outlet',
        url: '/outlet',
    }
];