/**
 * Add Atp brand Page Container
 */
// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import CategorySidebar from './CategorySidebar';
import PageHeader from '../shared/PageHeader';
import ProductsView from './ProductsView';
import { sidebarClose } from '../../store/sidebar';
import { cartAddItemSuccess } from '../../store/cart';
import { wishlistAddItem } from '../../store/wishlist';
import queryString  from 'query-string';

// data stubs
import products from '../../data/shopProducts';
import theme from '../../data/theme';
import {
    getProducts,
    setFamilyGroupId,
    setProductsPage,
    getProductsAfterScrolling,
    getGroupsFilter,
    addSelectedGroup,
    removeSelectedGroup,
    getFamilyGroupsFilter,
    addSelectedFamilyGroup,
    removeSelectedFamilyGroup,
    cleanProductsFilters,
    getFamiliesFilter,
    addSelectedFamily,
    removeSelectedFamily,
    getManufacturersFilter,
    addSelectedManufacturer,
    removeSelectedManufacturer,
    setSortByField,
    setIsOutletScreen,
    setGroupId
} from '../../store/products';

import { quickviewOpenSuccess } from '../../store/quickview';

class ProductsList extends Component {

    constructor(props) {
        super(props);

        this.onAddCartClick = this.onAddCartClick.bind(this);
        this.onAddWishListClick = this.onAddWishListClick.bind(this);
        this.viewClick = this.viewClick.bind(this);
        this.onGroupSelect = this.onGroupSelect.bind(this);
        this.onFamilyGroupSelect = this.onFamilyGroupSelect.bind(this);
        this.onFamilySelect = this.onFamilySelect.bind(this);
        this.onManufacturerSelect = this.onManufacturerSelect.bind(this);
        this.onCleanFilters = this.onCleanFilters.bind(this);
    }

    componentDidMount() {
        this.props.setIsOutletScreen(false);
        this.props.cleanProductsFilters();
        var parsed = queryString.parse(this.props.location.search);
        if (parsed.familyGroup) {
            this.props.setFamilyGroupId(parsed.familyGroup);
            this.props.addSelectedFamilyGroup(parsed.familyGroup);
        } else if (parsed.group) {
            this.props.setGroupId(parsed.group);
            this.props.addSelectedGroup(parsed.group);
        }
        this.props.getGroupsFilter();
        this.props.getFamiliesFilter();
        this.props.getFamilyGroupsFilter();
        //this.props.getManufacturersFilter();
        this.props.setSortByField('');
        this.props.getProducts();
    }

    componentDidUpdate(prevProps) {
        var parsed = queryString.parse(this.props.location.search);
        if (parsed.familyGroup) {
            if (prevProps.familyGroupId !== parsed.familyGroup) {
                this.props.cleanProductsFilters();
                this.props.removeSelectedFamilyGroup(prevProps.familyGroupId);
                this.props.addSelectedFamilyGroup(parsed.familyGroup);
                this.props.setFamilyGroupId(parsed.familyGroup);
                this.props.getGroupsFilter();
                this.props.getFamiliesFilter();
                this.props.getFamilyGroupsFilter();
                //this.props.getManufacturersFilter();
                this.props.setSortByField('');
                this.props.getProducts(parsed.familyGroup);
            }
        } else if (parsed.group) {
            if (prevProps.groupId !== parsed.group) {
                this.props.setGroupId(parsed.group);
                this.props.cleanProductsFilters();            
                this.props.removeSelectedGroup(prevProps.groupId);
                this.props.addSelectedGroup(parsed.group);
                this.props.getGroupsFilter();
                this.props.getFamiliesFilter();
                this.props.getFamilyGroupsFilter();
                this.props.setSortByField('');
                this.props.getProducts(null, parsed.group);
            }
        }
    }

    onAddCartClick = (product) => {
        this.props.cartAddItemSuccess(product);
    };

    onAddWishListClick = (product) => {
        this.props.wishlistAddItem(product);
    };


    viewClick = (product) => {
        this.props.quickviewOpenSuccess(product);
    };

    onGroupSelect = (groupId, checked) => {
        if (checked) {
            this.props.addSelectedGroup(groupId);
        } else {
            this.props.removeSelectedGroup(groupId);
        }

        this.props.getProducts();
    };

    onFamilyGroupSelect = (familyGroupId, checked) => {
        if (checked) {
            this.props.addSelectedFamilyGroup(familyGroupId);
        } else {
            this.props.removeSelectedFamilyGroup(familyGroupId);
        }

        this.props.getProducts();
    };

    onFamilySelect = (familyId, checked) => {
        if (checked) {
            this.props.addSelectedFamily(familyId);
        } else {
            this.props.removeSelectedFamily(familyId);
        }

        this.props.getProducts();
    };

    onManufacturerSelect = (familyId, checked) => {
        if (checked) {
            this.props.addSelectedManufacturer(familyId);
        } else {
            this.props.removeSelectedManufacturer(familyId);
        }

        this.props.getProducts();
    };

    onChangeOrderBy = (selected) => {
        this.props.setSortByField(selected);
        this.props.getProducts();
    };

    onCleanFilters = () => {
        this.props.cleanProductsFilters();
        this.props.getProducts();
    }


    render() {
        const {
            columns,
            viewMode,
            sidebarPosition,
            products,
            total,
            adding,
            groupsFilter,
            selectedGroups,
            familyGroupsFilter,
            pageSize,
            pageIndex,
            selectedFamilyGroups,
            familiesFilter,
            selectedFamilies,
            manufacturersFilter,
            selectedManufacturers,
            sortByField
        } = this.props;

        const breadcrumb = [
            { title: 'Inicio', url: '/' },
            { title: products.length > 0 && products[0].family ? products[0].family.name : '' },
            { title: products.length > 0 && products[0].familyGroup ? products[0].familyGroup.name : '' },
            { title: products.length > 0 && products[0].group ? products[0].group.name : '' },

        ];
        let content;

        const offcanvas = columns === 3 ? 'mobile' : 'always';

        if (columns > 3) {
            content = (
                <div className="container">
                    <div className="block">
                        <ProductsView
                            products={products}
                            layout={viewMode}
                            grid={`grid-${columns}-full`}
                            limit={15}
                            offcanvas={offcanvas}
                            onAddCartClick={this.onAddCartClick}
                            viewClick={this.viewClick}
                            adding={adding}
                        />
                    </div>
                    <CategorySidebar offcanvas={offcanvas} />
                </div>
            );
        } else {
            const sidebar = (
                <div className="shop-layout__sidebar"><CategorySidebar groupsFilter={groupsFilter} onGroupSelect={this.onGroupSelect}
                    selectedGroups={selectedGroups} familyGroupsFilter={familyGroupsFilter}
                    onFamilyGroupSelect={this.onFamilyGroupSelect} onFamilySelect={this.onFamilySelect} offcanvas={offcanvas} selectedFamilyGroups={selectedFamilyGroups}
                    familiesFilter={familiesFilter} selectedFamilies={selectedFamilies} manufacturersFilter={manufacturersFilter} onManufacturerSelect={this.onManufacturerSelect}
                    selectedManufacturers= {selectedManufacturers} onCleanFilters={this.onCleanFilters}
                     /></div>
            );

            content = (
                <div className="container">
                    <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                        {sidebarPosition === 'start' && sidebar}
                        <div className="shop-layout__content">
                            <div className="block">
                                <ProductsView
                                    products={products}
                                    total={total}
                                    layout={viewMode}
                                    grid="grid-3-sidebar"
                                    limit={15}
                                    offcanvas={offcanvas}
                                    onAddCartClick={this.onAddCartClick}
                                    viewClick={this.viewClick}
                                    adding={adding}
                                    pageSize={pageSize}
                                    pageIndex={pageIndex}
                                    onChangeOrderBy={this.onChangeOrderBy}
                                    sortByField={sortByField}
                                    onAddWishListClick={this.onAddWishListClick}
                                />
                            </div>
                        </div>
                        {sidebarPosition === 'end' && sidebar}
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Shop Category Page — ${theme.name}`}</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ productsApp, cart, settings }) => {
    const {
        products,
        total,
        familyGroupId,
        groupsFilter,
        selectedGroups,
        familyGroupsFilter,
        pageSize,
        pageIndex,
        selectedFamilyGroups,
        familiesFilter,
        selectedFamilies,
        manufacturersFilter,
        selectedManufacturers,
        sortByField,
        groupId
     } = productsApp;
    const { adding } = cart;
    return {
        products,
        total,
        familyGroupId,
        adding,
        groupsFilter,
        selectedGroups,
        familyGroupsFilter,
        pageSize,
        pageIndex,
        selectedFamilyGroups,
        familiesFilter,
        selectedFamilies,
        manufacturersFilter,
        selectedManufacturers,
        sortByField,
        settings,
        groupId
    };
};

const mapDispatchToProps = {
    getProducts,
    setFamilyGroupId,
    cartAddItemSuccess,
    quickviewOpenSuccess,
    setProductsPage,
    getProductsAfterScrolling,
    getGroupsFilter,
    addSelectedGroup,
    removeSelectedGroup,
    getFamilyGroupsFilter,
    addSelectedFamilyGroup,
    removeSelectedFamilyGroup,
    cleanProductsFilters,
    getFamiliesFilter,
    addSelectedFamily,
    removeSelectedFamily,
    getManufacturersFilter,
    addSelectedManufacturer,
    removeSelectedManufacturer,
    setSortByField,
    wishlistAddItem,
    setIsOutletScreen,
    setGroupId
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsList);