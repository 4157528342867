import client from './client';

export const getProductsRequest = async (
    pageIndex,
    pageSize,
    familyGroup,
    selectedGroups,
    selectedFamilyGroups,
    selectedFamilies,
    selectManufacturers,
    productsSortField,
    isOutletScreen,
    group
) => {
    let queryUrl = `/products/shop/products`;

    if (familyGroup) {
        queryUrl = `/products/shop/products?familyGroup=${familyGroup}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

        if (selectedGroups && selectedGroups.length > 0) {
            queryUrl += `&groups=${selectedGroups}`;
        }
        if (selectedFamilyGroups && selectedFamilyGroups.length > 0) {
            queryUrl += `&familyGroups=${selectedFamilyGroups}`;
        }
        if (selectedFamilies && selectedFamilies.length > 0) {
            queryUrl += `&families=${selectedFamilies}`;
        }
        if (selectManufacturers && selectManufacturers.length > 0) {
            queryUrl += `&manufacturers=${selectManufacturers}`;
        }

        if (productsSortField) {
            queryUrl += `&sortBy=${productsSortField}`;
        }
    }
    else {
        queryUrl = `/products/shop/products?pageIndex=${pageIndex}&pageSize=${pageSize}`;
        if (group) {
            queryUrl += `&group=${group}`;
        }
        if (selectedGroups && selectedGroups.length > 0) {
            queryUrl += `&groups=${selectedGroups}`;
        }
        if (selectedFamilyGroups && selectedFamilyGroups.length > 0) {
            queryUrl += `&familyGroups=${selectedFamilyGroups}`;
        }
        if (selectedFamilies && selectedFamilies.length > 0) {
            queryUrl += `&families=${selectedFamilies}`;
        }
        if (selectManufacturers && selectManufacturers.length > 0) {
            queryUrl += `&manufacturers=${selectManufacturers}`;
        }
        if (productsSortField) {
            queryUrl += `&sortBy=${productsSortField}`;
        }
        if (isOutletScreen) {
            queryUrl += `&outlet=${isOutletScreen}`;
        }
    }

    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getFeaturedProductsRequest = async (group, showFeatured) => {
    let queryUrl = `/products/shop/products?group=${group}&showFeatured=${showFeatured}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getNewReleaseProductsRequest = async (group, showNewReleases) => {
    let queryUrl = `/products/shop/products?group=${group}&showNewReleases=${showNewReleases}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getBestSellerProductsRequest = async (group, showBestSellers) => {
    let queryUrl = `/products/shop/products?group=${group}&showBestSellers=${showBestSellers}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getProductRequest = async (id) => {
    let queryUrl = `/products/shop/products/${id}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getRelatedProductsRequest = async (familyGroup,group) => {
    let queryUrl = `/products/shop/products?showRelated=true`;
    if (familyGroup) {
        queryUrl+= `&familyGroup=${familyGroup}`
    } else if (group) {
        queryUrl+= `&group=${group}`
    }

    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getProductsByCriteriaRequest = async (criteria) => {
    let queryUrl = `/products/shop/products?searchText=${criteria}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getGroupsFilterRequest = async (isOutlet) => {
    let queryUrl = `/products/shop/groups-filter?outlet=${isOutlet}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getFamilyGroupsFilterRequest = async (isOutlet) => {
    let queryUrl = `/products/shop/family-groups-filter?outlet=${isOutlet}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getFamiliesFilterRequest = async (isOutlet) => {
    let queryUrl = `/products/shop/families-filter?outlet=${isOutlet}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const getManufacturersFilterRequest = async () => {
    let queryUrl = `/products/shop/manufacturers-filter`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

export const addProductReviewRequest = async (data) => {
    let queryUrl = `/product-reviews`;
    return await client.post(queryUrl, data)
        .then(response => response.data);
};

export const getProductReviewsRequest = async (product) => {
    let queryUrl = `/product-reviews/${product}`;
    return await client.get(queryUrl)
        .then(response => response.data);
};

