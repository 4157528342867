/* eslint-disable no-restricted-globals */
// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';


// data stubs
import theme from '../../data/theme';
import {
    setOrderDetail
} from '../../store/user';
import Currency from '../shared/Currency';
import { orderStatus } from '../../util/Enums';

class AccountPageOrderDetails extends Component {

    constructor(props) {
        super(props);
        this.onShowOrderList = this.onShowOrderList.bind(this);
    }

    onShowOrderList() {
        this.props.setOrderDetail();
    }

    regionName(regionCode) {
        const { regions } = this.props;
        if (regions && regions.length > 0) {
            //Find index of specific object using findIndex method.    
            const objIndex = regions.findIndex((obj => obj.code === regionCode));
            //Update object's name property.
            return regions[objIndex].name;
        } else {
            return "";

        }
    }

    render() {

        const {
            order
        } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Detalles del pedido ${order.code} — ${theme.name}`}</title>
                    <meta name="description" content={"Ver los detalles de un pedido del usuario"}></meta>
                </Helmet>

                <div className="card">
                    <div className="order-header">
                        <div className="order-header__actions">
                            <Link to="#" onClick={this.onShowOrderList} className="btn btn-xs btn-secondary"><FormattedMessage id="order.detail.back.button" /></Link>
                        </div>
                        <h5 className="order-header__title">#{order.code}</h5>
                        <div className="order-header__subtitle">
                            <FormattedMessage id="order.detail.message.ordered" />
                            {' '}
                            <mark className="order-header__date">{moment(order.createdAt.date, moment.ISO_8601).tz(order.createdAt.timezone).format("DD/MM/YYYY")}</mark>
                            {' '}
                            {order.paymentData && !order.paymentData.error &&
                                <FormattedMessage id="order.detail.message.current.status" />
                            }
                            {' '}
                            {order.paymentData && order.paymentData.error ?
                                <mark className="order-header__status"></mark>
                                :
                                <mark className="order-header__status">{order.status.name} </mark>
                            }

                        </div>
                    </div>
                    <div className="card-divider" />
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="order.detail.products.label" /></th>
                                        <th class="text-right"><FormattedMessage id="order.detail.total.label" /></th>
                                    </tr>
                                </thead>
                                <tbody className="card-table__body card-table__body--merge-rows">

                                    {order.products && order.products.map((product) => (
                                        <tr key={product._id}>
                                            <td>{product.name} x {product.quantity}</td>
                                            <td class="text-right"><Currency value={product.price} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tbody className="card-table__body card-table__body--merge-rows">
                                    <tr>
                                        <th><FormattedMessage id="order.detail.subtotal.label" /></th>
                                        <td class="text-right"><Currency value={order.subtotal} /></td>
                                    </tr>

                                    <tr>
                                        <th><FormattedMessage id="order.detail.shipping.label" /></th>
                                        <td class="text-right"><Currency value={order.shipping ? order.shipping : 0} /></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th><FormattedMessage id="order.detail.total.label" /></th>
                                        <td class="text-right"><Currency value={order.total} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 no-gutters mx-n2">
                    <div className="col-sm-6 col-12 px-2">
                        <div className="card address-card address-card--featured">
                            <div className="address-card__body">
                                <div className="address-card__badge address-card__badge--muted"><FormattedMessage id="order.detail.shippingAddress.label" /></div>
                                <div className="address-card__name">{order.shippingAddress.name}</div>
                                <div className="address-card__row">
                                    {order.shippingAddress.address}
                                    <br />
                                    {order.shippingAddress.postalCode}, {order.shippingAddress.locality}
                                    <br />
                                    {this.regionName(order.shippingAddress.region)}
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title"><FormattedMessage id="order.detail.address.phone" /></div>
                                    <div className="address-card__row-content">{order.shippingAddress.phone}</div>
                                </div>
                                {/*<div className="address-card__row">
                                    <div className="address-card__row-title"><FormattedMessage id="order.detail.address.email" /></div>
                                    <div className="address-card__row-content">{order.shippingAddress.email}</div>
                                </div>*/}
                            </div>
                        </div>
                    </div>

                    {order.billingAddress &&
                        <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                            <div className="card address-card address-card--featured">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted"><FormattedMessage id="order.detail.billingAddress.label" /></div>
                                    <div className="address-card__name">{order.billingAddress.name}</div>
                                    <div className="address-card__row">
                                        {order.billingAddress.address}
                                        <br />
                                        {order.billingAddress.postalCode}, {order.billingAddress.locality}
                                        <br />
                                        {this.regionName(order.billingAddress.region)}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title"><FormattedMessage id="order.detail.address.phone" /></div>
                                        <div className="address-card__row-content">{order.billingAddress.phone}</div>
                                    </div>
                                    {/*<div className="address-card__row">
                                    <div className="address-card__row-title"><FormattedMessage id="order.detail.address.email" /></div>
                                    <div className="address-card__row-content">{order.billingAddress.email}</div>
                                </div>*/}
                                </div>
                            </div>
                        </div>}
                </div>
            </React.Fragment>
        );
    }
}

AccountPageOrderDetails.propTypes = {
    order: PropTypes.object.isRequired,

};

// map state to props
const mapStateToProps = ({ settings }) => {
    return { settings };
}

const mapDispatchToProps = {
    setOrderDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageOrderDetails);