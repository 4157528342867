// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../../api/client';

// third-party
import { Helmet } from 'react-helmet-async';
//import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';
//import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import AccountRegisterForm from './AccountRegisterForm';
import AccountLoginForm from './AccountLoginForm';

import {
    buildEmptyRegisterData,
    buildEmptySigninData,
    register,
    signinClient
} from '../../store/auth';

class AccountPageLogin extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.checkUserEmail = this.checkUserEmail.bind(this);
    }

    componentDidMount() {
        this.props.buildEmptySigninData();
        this.props.buildEmptyRegisterData();
    }

    onSubmit(isLogin, data) {
        const { match } = this.props;

        if (isLogin) {
            this.props.signinClient(data, this.props.history);
        } else {
            this.props.register(data, this.props.history );
        }
    }

   /*
   * Checks whether user email exists
   */
  checkUserEmail(email) {
    return new Promise((resolve, reject) => {
      let urlQuery = `users/shop/check-email?email=${email}`;

      api.get(urlQuery)
        .then((response) => {
          resolve(true);
        })
        .catch(error => {
          reject(new Error(`${email} is already taken`));
        });
    });
  }

    render() {
        const breadcrumb = [
            { title: 'Inicio', url: '/' },
            { title: 'Acceso', url: '' },
        ];

        const { registerData, signinData, loading, loadingSignin, loadingRegister } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Acceso / Registro — ${theme.name}`}</title>
                    <meta name="description" content={"Ingresar o registrarse en el sistema"}></meta>
                </Helmet>

                <PageHeader header="Acceso / Registro" breadcrumb={breadcrumb} />

                <div className="block">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex">
                                <div className="card flex-grow-1 mb-md-0">
                                    <div className="card-body">
                                    <h3 className="card-title"><FormattedMessage id="header.signin.title"/></h3>
                                         {<AccountLoginForm
                                            signinData={signinData}
                                            onSubmit={this.onSubmit}
                                            loading={loadingSignin} />}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex mt-4 mt-md-0">
                                <div className="card flex-grow-1 mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title"><FormattedMessage id="header.register.title"/></h3>
                                        {<AccountRegisterForm
                                            registerData={registerData}
                                            onSubmit={this.onSubmit}
                                            loading={loadingRegister}
                                            checkUserEmail={this.checkUserEmail} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// map state to props
const mapStateToProps = ({ authApp, settings }) => {
    const { registerData, signinData, loading, loadingSignin, loadingRegister } = authApp;
    return { registerData, signinData, loading, loadingSignin, loadingRegister, settings };
}

const mapDispatchToProps = {
    buildEmptyRegisterData,
    buildEmptySigninData,
    register,
    signinClient
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageLogin);
